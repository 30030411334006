import { FC, useContext, createContext, ReactNode } from "react";
import { useMutation } from "react-query";
import { appendAnswers } from "../../data/queries/appendAnswers";
import { Solution } from "hp-types";
import useLocalStorage from "../../utils/useLocalStorage";
import { useAdmin } from "../AdminProvider";
import { useQuestions } from "../QuestionsProvider";
import { appendAnswersV2 } from "../../data/queries/appendAnswersV2";

export type AnswerData = {
  questionId: string;
  answer: Solution;
  seconds: number;
  minutes?: number;
  timeStamp: number;
};

const transformOldData = (data: AnswerData): AnswerData => {
  const { questionId, answer, timeStamp } = data;
  if (data.minutes) {
    return {
      questionId,
      answer,
      timeStamp,
      seconds: data.minutes * 60 + data.seconds,
    };
  }
  return data;
};

type AnswersContextType = {
  answers: AnswerData[];
  addAnswers: (answer: AnswerData[]) => void;
};

const AnswersContext = createContext<AnswersContextType | null>(null);

export const useAnswers = (): AnswersContextType => {
  const context = useContext(AnswersContext);
  if (!context) {
    throw new Error("useUser must be inside the UserProvider");
  }

  return context;
};

type Props = {
  children: ReactNode;
};

const UserProvider: FC<Props> = ({ children }) => {
  const { isAdmin } = useAdmin();
  const { mutate: appendAnswers1 } = useMutation(appendAnswers);
  const { mutate: appendAnswers2 } = useMutation(appendAnswersV2);
  const { questions } = useQuestions();
  const [answers, setAnswers] = useLocalStorage<AnswerData[]>("ANSWERS", []);
  const addAnswers = (newAnswers: AnswerData[]) => {
    setAnswers([...answers, ...newAnswers]);
    if (!isAdmin) {
      appendAnswers1({
        questions,
        answers: newAnswers,
        oldAnswers: answers,
      });
      appendAnswers2({
        questions,
        answers: newAnswers,
        oldAnswers: answers,
      });
    }
  };

  return (
    <AnswersContext.Provider
      value={{ answers: answers.map(transformOldData), addAnswers }}
    >
      {children}
    </AnswersContext.Provider>
  );
};

export default UserProvider;
