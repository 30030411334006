import { FC, createContext, useContext, ReactNode, useEffect } from "react";
import { LIMIT_FILTER_OPTIONS } from "../../constants/numbers";
import useLocalStorage from "../../utils/useLocalStorage";
import { useAnswers } from "../AnswersProvider";
import { useQuestions } from "../QuestionsProvider";
import { filterQuestions } from "./filterQuestions";
import { filtering } from "hp-utils";
import { Filter, Question } from "hp-types";

type FilterContextType = {
  filter: Filter;
  filtered: Question[];
  setFilter: (filter: Filter) => void;
};

export const FilterContext = createContext<FilterContextType | null>(null);

export const useFilter = (): FilterContextType => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useXYZFilter must be inside the FilterProvider");
  }
  return context;
};

export const defaultFilterState: Filter = {
  tests: [],
  unanswered: true,
  incorrect: true,
  tooSlow: true,
  correct: true,
  XYZ: true,
  KVA: true,
  NOG: true,
  DTK: true,
  limit: LIMIT_FILTER_OPTIONS.TEN,
  sections: [],
  exclusiveSections: false,
};

type Props = {
  nameSpace: string;
  customFilter?: Partial<Filter>;
  children: ReactNode;
};

const FilterProvider: FC<Props> = ({
  children,
  nameSpace,
  customFilter = {},
}) => {
  const { questions } = useQuestions();
  const { answers } = useAnswers();
  const [filterState, setFilter] = useLocalStorage<Filter>(
    `FILTER_v3_${nameSpace}`,
    { ...defaultFilterState, ...customFilter }
  );

  const filter = { ...defaultFilterState, ...filterState };

  const filtered = filterQuestions(questions, filter, answers);
  const reduced = filtering.reduceQuestions(filtered, answers, filter.limit);

  useEffect(() => {
    if (nameSpace !== "testing") {
      setFilter({ ...filter, sections: [], exclusiveSections: false });
    }
  });

  return (
    <FilterContext.Provider
      value={{
        filter,
        filtered: reduced,
        setFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
