import Container from "../../components/Container";
import FilterFooter from "./FilterFooter";
import { useFilter } from "../../providers/FilterProvider";
import QuestionList from "./QuestionList";
import MenuIcon from "../../components/MenuIcon";
import { useQuestions } from "../../providers/QuestionsProvider";
import { Heading } from "hp-fabric";

const dateSort = (a: Date, b: Date) => {
  return a.getTime() - b.getTime();
};

const Questions = () => {
  const { loadingQuestions } = useQuestions();
  const { filtered } = useFilter();
  const questionList = filtered.sort((a, b) => {
    const byDate = dateSort(new Date(a.date), new Date(b.date));
    const byPartNumber = a.partNumber - b.partNumber;
    const byQuestionNumber = a.questionNumber - b.questionNumber;
    return byDate || byPartNumber || byQuestionNumber;
  });

  return (
    <Container
      header={{
        leftComponent: <MenuIcon />,
        centerComponent: (
          <Heading level={1} size="s">
            Frågor
          </Heading>
        ),
      }}
    >
      {!loadingQuestions && <QuestionList questions={questionList} />}
      <FilterFooter filtered={filtered} />
    </Container>
  );
};

export default Questions;
