import { Section } from "../data/sections";

const addNewToSectionList = (
  sectionList: Section[],
  sectionsToAdd: Section[]
): Section[] => {
  const withoutDuplicates = sectionsToAdd.filter(
    (newSection) =>
      !sectionList.find((section) => section.name === newSection.name)
  );
  return [...sectionList, ...withoutDuplicates];
};

export default addNewToSectionList;
