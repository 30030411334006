import { Question } from "hp-types";

export const checkSectionsFilter = (
  sections: string[],
  exclusiveSections: boolean,
  question: Question
) => {
  if (sections.length === 0) {
    return true;
  }
  if (exclusiveSections) {
    return question.sections?.every((section) => sections?.includes(section));
  }
  return sections.some((section) => question.sections?.includes(section));
};
