import { FC } from "react";
import { useQuestions } from "../../providers/QuestionsProvider";
import { SectionsPage } from "hp-fabric";
import { useMetaData } from "../../providers/MetaDataProvider/MetaDataProvider";
import { Link } from "react-router-dom";
import MenuIcon from "../../components/MenuIcon";

export const OPACITY_SPEED = 0.5;
export const TRANSFORM_SPEED = 0.9;

const Sections: FC = () => {
  const { loadingQuestions, questions } = useQuestions();

  const { dtkGraph, mathGraph, optimalPath, sections, loadingMetaData } =
    useMetaData();

  const loading = loadingQuestions || loadingMetaData;

  return (
    <SectionsPage
      dtkGraph={dtkGraph}
      linkComponent={Link}
      mathGraph={mathGraph}
      menuIcon={<MenuIcon />}
      optimalPath={optimalPath || []}
      questions={questions}
      sections={sections}
      loading={loading}
    />
  );
};

export default Sections;
