import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useSettings } from "../../providers/SettingsProvider";
import { useTheme } from "styled-components";
import styled from "styled-components";
import stringifyTime from "../../utils/stringifyTime";
import { useStopwatch } from "react-timer-hook";
import MenuIcon from "../../components/MenuIcon";
import PauseButtonContainer from "./PauseButtonContainer";
import { Card, Heading, Page } from "hp-fabric";

const SettingsContainer = styled(Stack)`
  width: 100%;
  padding: ${(props) => props.theme.spaces.m}px;
`;

const DarkModeContainer = styled.div`
  display: flex;
`;

const ShowPauseButtonContainer = styled.div`
  display: flex;
  min-height: 50px;
`;

const TimerFormContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TimerContainer = styled.div`
  height: 32px;
`;

const RingContainer = styled.div`
  position: relative;
  height: 100%;
`;

const ThemeRing = styled.div<{ offset: number; color: string }>`
  position: absolute;
  top: 10.5px;
  left: ${(props) => props.offset * props.theme.spaces.m}px;
  width: ${(props) => props.theme.spaces.l}px;
  height: ${(props) => props.theme.spaces.l}px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const Settings = () => {
  const { minutes, seconds, pause, start, isRunning } = useStopwatch({
    autoStart: true,
  });
  const { settings, setSettings } = useSettings();
  const theme = useTheme();
  const colors = [
    theme.isDark ? theme.colors.gray7 : theme.colors.blue2,
    theme.colors.unanswered,
    theme.colors.incorrect,
    theme.colors.tooSlow,
    theme.colors.correct,
    theme.colors.xyz,
    theme.colors.kva,
    theme.colors.nog,
  ];
  return (
    <Page
      header={{
        leftComponent: <MenuIcon />,
        centerComponent: (
          <Heading level={1} size="s">
            Inställningar
          </Heading>
        ),
      }}
    >
      <Card>
        <SettingsContainer spacing={2}>
          <TimerFormContainer>
            <FormControlLabel
              sx={{
                color: `${theme.colors.textPrimary} !important`,
              }}
              control={
                <Checkbox
                  sx={{
                    minWidth: "42px",
                    color: `${theme.colors.textPrimary} !important`,
                  }}
                  checked={settings.hideTime}
                  onChange={() =>
                    setSettings({ ...settings, hideTime: !settings.hideTime })
                  }
                />
              }
              label="Göm klockan"
            />
            <TimerContainer>
              {!settings.hideTime && (
                <Heading size="s">
                  {stringifyTime(minutes * 60 + seconds)}
                </Heading>
              )}
            </TimerContainer>
          </TimerFormContainer>
          <DarkModeContainer>
            <FormControlLabel
              sx={{
                color: `${theme.colors.textPrimary} !important`,
              }}
              control={
                <Checkbox
                  sx={{
                    color: `${theme.colors.textPrimary} !important`,
                    minWidth: "42px",
                  }}
                  checked={settings.darkMode}
                  onChange={() =>
                    setSettings({ ...settings, darkMode: !settings.darkMode })
                  }
                />
              }
              label="Dark mode"
            />
            <RingContainer>
              {colors.map((color, index) => (
                <ThemeRing key={index} color={color} offset={index} />
              ))}
            </RingContainer>
          </DarkModeContainer>
          <ShowPauseButtonContainer>
            <FormControlLabel
              sx={{
                color: `${theme.colors.textPrimary} !important`,
                maxWidth: "70%",
              }}
              control={
                <Checkbox
                  sx={{
                    minWidth: "42px",
                    color: `${theme.colors.textPrimary} !important`,
                  }}
                  checked={settings.pauseButton}
                  onChange={() => {
                    setSettings({
                      ...settings,
                      pauseButton: !settings.pauseButton,
                    });
                  }}
                />
              }
              label="Visa pausknapp i början av varje ny fråga"
            />
            {settings.pauseButton && (
              <PauseButtonContainer
                onClick={() => (isRunning ? pause() : start())}
              />
            )}
          </ShowPauseButtonContainer>
        </SettingsContainer>
      </Card>
    </Page>
  );
};

export default Settings;
