import { BrowserRouter, Routes, Route } from "react-router-dom";
import FilterContainer from "../containers/FilterContainer";
import Result from "../containers/Results";
import Startpage from "../containers/Startpage";
import Testing from "../containers/Testing";
import Settings from "../containers/Settings";
import Areas from "../containers/Areas";
import Questions from "../containers/Questions";
import FilterProvider from "../providers/FilterProvider";
import { LIMIT_FILTER_OPTIONS } from "../constants/numbers";
import Question from "../containers/Question";
import Math from "../containers/Math";
import MathTraining from "../containers/MathTraining";
import SubjectTraining from "../containers/SubjectTraining";
import SubjectTesting from "../containers/SubjectTesting";
import SubjectResult from "../containers/SubjectResult";
import Sections from "../containers/Sections";
import Section from "../containers/Section";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import About from "../containers/About/About";

const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route index element={<Startpage />} />
        <Route path="testing" element={<Testing />} />
        <Route path="about" element={<About />} />
        <Route path="sections" element={<Sections />} />
        <Route
          path="section/:sectionId"
          element={
            <FilterProvider nameSpace="testing">
              <Section />
            </FilterProvider>
          }
        />
        <Route
          path="filter"
          element={
            <FilterProvider nameSpace="testing">
              <FilterContainer />
            </FilterProvider>
          }
        />
        <Route path="result" element={<Result />} />
        <Route path="settings" element={<Settings />} />
        <Route path="math-training" element={<MathTraining />} />
        <Route path="math-training/:subject" element={<SubjectTraining />} />
        <Route
          path="math-training/:subject/testing"
          element={<SubjectTesting />}
        />
        <Route
          path="math-training/:subject/result"
          element={<SubjectResult />}
        />
        <Route path="areas" element={<Areas />} />
        <Route
          path="questions"
          element={
            <FilterProvider
              nameSpace="questions"
              customFilter={{ limit: LIMIT_FILTER_OPTIONS.MAX_LIMIT }}
            >
              <Questions />
            </FilterProvider>
          }
        />
        <Route
          path="question/:questionId"
          element={
            <FilterProvider nameSpace="testing">
              <Question />
            </FilterProvider>
          }
        />
        <Route path="math" element={<Math />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
