import { Stack } from "@mui/material";
import { FC } from "react";
import { useTheme } from "styled-components";
import { useAnswers } from "../../providers/AnswersProvider";
import { CurrentQuestion } from "hp-types";
import splitQuestionsOnHistory from "../../utils/splitQuestionsOnHistory";
import { MicroLegend } from "../MicroLegend";

type Props = {
  result: CurrentQuestion[];
};

const MicroLegends: FC<Props> = ({ result }) => {
  const { answers } = useAnswers();
  const theme = useTheme();
  const { correct, incorrect, tooSlow } = splitQuestionsOnHistory(
    answers,
    result.filter(({ answer }) => answer)
  );
  const unanswered = result.filter(({ answer }) => !answer);
  return (
    <Stack direction="row" spacing={0.5}>
      <MicroLegend number={correct.length} color={theme.colors.correct} />
      <MicroLegend number={tooSlow.length} color={theme.colors.tooSlow} />
      <MicroLegend number={incorrect.length} color={theme.colors.incorrect} />
      <MicroLegend number={unanswered.length} color={theme.colors.unanswered} />
    </Stack>
  );
};

export default MicroLegends;
