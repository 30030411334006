import { Stack } from "@mui/material";
import { Heading } from "hp-fabric";
import { FC, ReactNode } from "react";
import styled from "styled-components";

const StyledStack = styled(Stack)`
  width: 100%;
`;

const StyledOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.spaces.xs}px;
`;

type Props = {
  title: string;
  size?: "s" | "m";
  children: ReactNode;
};

const SelectorGroup: FC<Props> = ({ title, size, children }) => {
  return (
    <StyledStack>
      <Heading size={size} level={3}>
        {title}
      </Heading>
      <StyledOptions>{children}</StyledOptions>
    </StyledStack>
  );
};

export default SelectorGroup;
