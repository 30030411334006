import { FC, ReactNode } from "react";
import { useSettings } from "../SettingsProvider";
import { ThemeProvider as FabricThemeProvider } from "hp-fabric";

type Props = {
  children: ReactNode;
};

const ThemeProvider: FC<Props> = ({ children }) => {
  const { settings } = useSettings();
  const themeName = settings.darkMode ? "dark" : "default";
  return (
    <FabricThemeProvider themeName={themeName}>{children}</FabricThemeProvider>
  );
};

export default ThemeProvider;
