import { Resource } from "../data/questions";
import { resourceCreators } from "../data/resourceCreators";

const getDisplayNameForResource = (resource: Resource) => {
  return (
    resourceCreators.find((r) => r.id === resource.name)?.displayName ||
    resource.name
  );
};

export default getDisplayNameForResource;
