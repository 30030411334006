import { Stack } from "@mui/material";
import { FC } from "react";
import CurrentTestHistoryPieChart from "../../../components/CurrentTestHistoryChart";
import { CurrentQuestion } from "hp-types";
import MicroLegends from "../../../components/MicroLegends";
import { Text } from "hp-fabric";

type Props = {
  result: CurrentQuestion[];
  name: string;
};

export const SegmentChart: FC<Props> = ({ result, name }) => {
  return result.length ? (
    <Stack sx={{ alignItems: "center" }}>
      <CurrentTestHistoryPieChart result={result} />
      <div
        style={{
          position: "relative",
          top: -12,
          marginBottom: -12,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Text>{name}</Text>
        <MicroLegends result={result} />
      </div>
    </Stack>
  ) : null;
};
