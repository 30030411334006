import {
  ResultObject,
  TrainingData,
  TrainingType,
} from "../../providers/MathTrainingProvider";
import getAllQuestionsWithCompletedLevel from "../../utils/getAllQuestionsWithCompletedLevel";

type Props = {
  subject: TrainingType;
  trainingData: TrainingData;
};

// https://stackoverflow.com/a/47061616/4136445
const interleave = <T>([x, ...xs]: T[], ys: T[] = []): T[] =>
  x === undefined
    ? ys // base: no x
    : // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [x, ...interleave(ys, xs)]; // inductive: some x

const sortQuestions = (a: ResultObject, b: ResultObject) => {
  if (a.timestamp && b.timestamp) {
    return a.timestamp - b.timestamp;
  }
  if (a.timestamp && !b.timestamp) {
    return 1;
  }
  if (!a.timestamp && b.timestamp) {
    return -1;
  }
  return 0.5 - Math.random();
};

const getNextQuestions = ({ subject, trainingData }: Props) => {
  const unanswered = getAllQuestionsWithCompletedLevel({
    subject,
    trainingData,
    completedLevel: 0,
  })
    .sort(sortQuestions)
    .filter(({ arg1, arg2 }) => {
      return subject !== "division" || String((arg1 / arg2) % 1).length < 5;
    })
    .slice(0, 10)
    .sort(() => 0.5 - Math.random());
  const incorrect1 = getAllQuestionsWithCompletedLevel({
    subject,
    trainingData,
    completedLevel: -1,
  })
    .sort(sortQuestions)
    .filter(({ arg1, arg2 }) => {
      return subject !== "division" || String((arg1 / arg2) % 1).length < 5;
    })
    .slice(0, 10)
    .sort(() => 0.5 - Math.random());

  const correct1 = getAllQuestionsWithCompletedLevel({
    subject,
    trainingData,
    completedLevel: 1,
  })
    .sort(sortQuestions)
    .filter(({ arg1, arg2 }) => {
      return subject !== "division" || String((arg1 / arg2) % 1).length < 5;
    })
    .slice(0, 10)
    .sort(() => 0.5 - Math.random());

  const correct2 = getAllQuestionsWithCompletedLevel({
    subject,
    trainingData,
    completedLevel: 2,
  })
    .sort(sortQuestions)
    .filter(({ arg1, arg2 }) => {
      return subject !== "division" || String((arg1 / arg2) % 1).length < 5;
    })
    .slice(0, 10)
    .sort(() => 0.5 - Math.random());

  const correct3 = getAllQuestionsWithCompletedLevel({
    subject,
    trainingData,
    completedLevel: 3,
  })
    .sort(sortQuestions)
    .filter(({ arg1, arg2 }) => {
      return subject !== "division" || String((arg1 / arg2) % 1).length < 5;
    })
    .slice(0, 10)
    .sort(() => 0.5 - Math.random());

  const correct4 = getAllQuestionsWithCompletedLevel({
    subject,
    trainingData,
    completedLevel: 4,
  })
    .sort(sortQuestions)
    .filter(({ arg1, arg2 }) => {
      return subject !== "division" || String((arg1 / arg2) % 1).length < 5;
    })
    .slice(0, 10)
    .sort(() => 0.5 - Math.random());

  const completed = getAllQuestionsWithCompletedLevel({
    subject,
    trainingData,
    completedLevel: 5,
  })
    .sort(sortQuestions)
    .filter(({ arg1, arg2 }) => {
      return subject !== "division" || String((arg1 / arg2) % 1).length < 5;
    })
    .slice(0, 10)
    .sort(() => 0.5 - Math.random());

  const prio = interleave(unanswered, incorrect1)
    .slice(0, 10)
    .sort(sortQuestions);

  return [
    ...prio,
    ...correct1,
    ...correct2,
    ...correct3,
    ...correct4,
    ...completed,
  ]
    .slice(0, 10)
    .sort(() => 0.5 - Math.random());
};

export default getNextQuestions;
