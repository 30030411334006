import { FC } from "react";
import styled from "styled-components";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearIcon from "@mui/icons-material/Clear";
import { Text } from "hp-fabric";
import { Question } from "hp-types";

const IconContainer = styled.div`
  color: ${(props) => props.theme.colors.textPrimary};
  width: fit-content;
  cursor: pointer;
`;

const TopContainer = styled.div`
  height: ${(props) => props.theme.spaces.xxl}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spaces.m}px;
`;

type Props = {
  filtered: Question[];
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

const FooterTop: FC<Props> = ({ open, close, isOpen, filtered }) => {
  return (
    <TopContainer onClick={() => !isOpen && open()}>
      {isOpen ? <Text>{filtered.length} frågor</Text> : <Text>Filter</Text>}
      <IconContainer>
        {isOpen ? <ClearIcon onClick={close} /> : <FilterListIcon />}
      </IconContainer>
    </TopContainer>
  );
};

export default FooterTop;
