import Ripples from "react-ripples";
import { FC, ReactNode } from "react";
import styled, { css, useTheme } from "styled-components";
import { Button } from "hp-fabric";

const StyledRipples = styled(Ripples)`
  width: 100%;
  height: 100%;
`;

const StyledButtonContainer = styled.div<{ height?: number }>`
  box-sizing: border-box;
  width: ${(props) => {
    const containerWidth = Math.min(
      600,
      (props.theme.dimensions.width || 300) - 2 * props.theme.spaces.m
    );

    return containerWidth / 4;
  }}px;
  height: ${(props) => (props.height ? props.height * 70 : 70)}px;
  padding: ${5}px;
  flex-shrink: 0;
`;

type Props = {
  height?: number;
  onClick: () => void;
  disabled?: boolean;
  children: ReactNode;
};

const RippleButton: FC<Props> = ({ children, onClick, height, disabled }) => {
  const theme = useTheme();
  return (
    <StyledButtonContainer height={height}>
      <StyledRipples
        color={theme.isDark ? theme.colors.gray8 : theme.colors.blue0}
      >
        <Button
          kind="secondary"
          customCss={css`
            width: 100%;
            height: 100%;
            min-width: 0 !important;
          `}
          onClick={onClick}
          disabled={disabled}
        >
          {children}
        </Button>
      </StyledRipples>
    </StyledButtonContainer>
  );
};

export default RippleButton;
