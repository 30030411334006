import { FC, KeyboardEvent, useEffect, useState } from "react";
import styled, { css, useTheme } from "styled-components";
import CheckIcon from "@mui/icons-material/Check";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import {
  TrainingArgument,
  TrainingType,
} from "../../../providers/MathTrainingProvider";
import RippleButton from "../RippleButton";
import { useParams } from "react-router-dom";
import { Text } from "hp-fabric";

export const BUTTON_HEIGHT = 70;
export const BUTTON_PADDING = 5;
export const VALUE_HEIGHT = 50;
export const BOTTOM_PADDING = 36;
export const BORDER_WIDTH = 1;
export const TRAINING_FOOTER_HEIGHT =
  BUTTON_HEIGHT * 4 + VALUE_HEIGHT + 2 * BORDER_WIDTH + BOTTOM_PADDING;

const argumentList: TrainingArgument[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const StyledButtonPlaceholder = styled.div<{ height?: number }>`
  box-sizing: border-box;
  width: ${(props) => {
    const containerWidth = Math.min(
      600,
      (props.theme.dimensions.width || 300) - 2 * props.theme.spaces.m
    );

    return containerWidth / 4;
  }}px;
  height: ${(props) => (props.height ? props.height * 70 : 70)}px;
  padding: 5px;
  flex-shrink: 0;
`;

const StyledFooter = styled.footer`
  position: absolute;
  left: 0;
  bottom: 0;
  padding-bottom: ${BOTTOM_PADDING}px;
  background-color: ${(props) => props.theme.colors.surface};
  min-width: 100vw;
  border-top: 1px solid ${(props) => props.theme.colors.borderGray};
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  margin: 0 auto;
  width: ${(props) =>
    (props.theme.dimensions.width || 300) - 2 * props.theme.spaces.m}px;
  max-width: 600px;
`;

const StyledControlButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledNumberButtons = styled.div`
  width: ${(props) => {
    const containerWidth = Math.min(
      600,
      (props.theme.dimensions.width || 300) - 2 * props.theme.spaces.m
    );

    return containerWidth - containerWidth / 4;
  }}px;
  display: flex;
  flex-wrap: wrap;
`;

const StyledValueContainer = styled.div`
  height: ${VALUE_HEIGHT}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderGray};
`;

type Props = {
  handleAnswer: (value: number) => void;
};

const TestingFooter: FC<Props> = ({ handleAnswer }) => {
  const { subject } = useParams() as { subject: TrainingType };
  const [value, setValue] = useState("");
  const handleInput = (newValue: string) => {
    if (newValue.length < 7) {
      setValue(newValue);
    }
  };
  const theme = useTheme();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleKeyDown = (event: KeyboardEvent): void => {
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
        if ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(parseInt(event.key))) {
          handleInput(`${value}${event.key}`);
        } else if (event.key === ".") {
          !value.includes(".") && handleInput(`${value.length ? value : 0}.`);
        } else if (event.key === "Backspace") {
          handleInput(value.slice(0, -1));
        } else if (event.key === "Enter") {
          if (value.length && value !== "0.") {
            handleInput("");
            handleAnswer(Number.parseFloat(value));
          }
        } else if (event.key === "-") {
          if (value[0] === "-") {
            handleInput(value.slice(1));
          } else {
            handleInput(`-${value}`);
          }
        }
      };

      window.addEventListener(
        "keydown",
        handleKeyDown as unknown as EventListenerOrEventListenerObject
      );
      return () =>
        window.removeEventListener(
          "keydown",
          handleKeyDown as unknown as EventListenerOrEventListenerObject
        );
    }
  }, [handleAnswer, value]);
  return (
    <StyledFooter>
      <StyledValueContainer>
        <Text
          customCss={css`
            font-size: 2rem;
          `}
        >
          {value}
        </Text>
      </StyledValueContainer>
      <StyledButtonsContainer>
        <StyledNumberButtons>
          {argumentList.map((argument) => (
            <RippleButton
              key={argument}
              onClick={() => handleInput(`${value}${argument}`)}
            >
              {argument}
            </RippleButton>
          ))}
          <StyledButtonPlaceholder />
          <RippleButton onClick={() => handleInput(`${value}${0}`)}>
            {0}
          </RippleButton>
          {subject === "division" && (
            <RippleButton
              onClick={() =>
                !value.includes(".") &&
                handleInput(`${value.length ? value : 0}.`)
              }
            >
              .
            </RippleButton>
          )}
          {subject === "subtraktion" && (
            <RippleButton
              onClick={() => {
                if (value[0] === "-") {
                  handleInput(value.slice(1));
                } else {
                  handleInput(`-${value}`);
                }
              }}
            >
              –
            </RippleButton>
          )}
        </StyledNumberButtons>
        <StyledControlButtonsContainer>
          <RippleButton
            height={2}
            onClick={() => handleInput(value.slice(0, -1))}
          >
            <BackspaceOutlinedIcon
              sx={{ color: theme.colors.buttons.outlined.color }}
            />
          </RippleButton>
          <RippleButton
            height={2}
            onClick={() => {
              if (value.length && value !== "0.") {
                handleAnswer(parseFloat(value));
                handleInput("");
              }
            }}
          >
            <CheckIcon sx={{ color: theme.colors.buttons.outlined.color }} />
          </RippleButton>
        </StyledControlButtonsContainer>
      </StyledButtonsContainer>
    </StyledFooter>
  );
};

export default TestingFooter;
