import React, { FC, useEffect } from "react";
import styled from "styled-components";
import PauseButton from "../../../components/PauseButton";

const StyledContainer = styled.div<{ showButton: boolean }>`
  opacity: ${(props) => (props.showButton ? 1 : 0)};
  transition: opacity 1s ease-in-out;
`;

enum PauseButtonState {
  STARTED = "STARTED",
  HIDDEN = "HIDDEN",
  FADE_IN = "FADE_IN",
  RUNNING = "RUNNING",
  FADE_OUT = "FADE_OUT",
}

type Props = {
  onClick: () => void;
};

const PauseButtonContainer: FC<Props> = ({ onClick }) => {
  const [currentState, setCurrentState] = React.useState(
    PauseButtonState.STARTED
  );
  const fadedIn = [PauseButtonState.FADE_IN, PauseButtonState.RUNNING].includes(
    currentState
  );
  const visible = currentState !== PauseButtonState.HIDDEN;

  useEffect(() => {
    if (currentState === PauseButtonState.STARTED) {
      setTimeout(() => {
        setCurrentState(PauseButtonState.FADE_IN);
      }, 0);
    }
    if (currentState === PauseButtonState.HIDDEN) {
      setTimeout(() => {
        setCurrentState(PauseButtonState.FADE_IN);
      }, 1000);
    }
    if (currentState === PauseButtonState.FADE_IN) {
      setTimeout(() => {
        setCurrentState(PauseButtonState.RUNNING);
      }, 1000);
    }
    if (currentState === PauseButtonState.RUNNING) {
      setTimeout(() => {
        setCurrentState(PauseButtonState.FADE_OUT);
      }, 5000);
    }
    if (currentState === PauseButtonState.FADE_OUT) {
      setTimeout(() => {
        setCurrentState(PauseButtonState.HIDDEN);
      }, 1000);
    }
  }, [currentState]);
  return (
    <StyledContainer showButton={fadedIn}>
      {visible && (
        <PauseButton
          running={currentState === PauseButtonState.RUNNING}
          onClick={onClick}
        />
      )}
    </StyledContainer>
  );
};

export default PauseButtonContainer;
