import React, { FC, useEffect } from "react";
import styled, { css } from "styled-components";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const SIZE = 50;
const RADIUS = SIZE / 2;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
const STROKE_WIDTH = 0.8;

const StyledPauseButton = styled.button`
  width: ${SIZE}px;
  height: ${SIZE}px;
  min-width: ${SIZE}px;
  min-height: ${SIZE}px;
  border-radius: ${SIZE}%;
  box-shadow: 0px 1px 10px -3px rgba(0, 0, 0, 0.5);
  border: none;
  background-color: ${(props) => props.theme.colors.surface};
  padding: 0;
  margin: 0;
  position: relative;
`;

const iconStyle = css`
  font-size: 29px !important;
  transform: translate(1px, 3px);
`;

const StyledPauseIcon = styled(PauseIcon)`
  color: ${(props) => props.theme.colors.textPrimary};
  ${iconStyle}
`;

const StyledPlayIcon = styled(PlayArrowIcon)`
  color: ${(props) => props.theme.colors.textPrimary};
  ${iconStyle}
`;

const StyledCircle = styled.circle<{ filled: boolean }>`
  stroke-dasharray: ${(props) =>
    `${props.filled ? CIRCUMFERENCE : 0} ${CIRCUMFERENCE}`};
  transition: stroke-dasharray 5s linear;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke: ${(props) =>
    props.theme.isDark ? props.theme.colors.gray3 : props.theme.colors.blue2};
`;

export enum PauseButtonIcon {
  PAUSE = "pause",
  PLAY = "play",
}

type Props = {
  onClick: () => void;
  running: boolean;
  icon?: PauseButtonIcon;
};

const PauseButton: FC<Props> = ({
  running,
  onClick,
  icon = PauseButtonIcon.PAUSE,
}) => {
  const [filled, setFilled] = React.useState(false);
  useEffect(() => {
    if (running) {
      setTimeout(() => {
        setFilled(true);
      }, 0);
    }
  }, [running]);

  return (
    <StyledPauseButton onClick={onClick}>
      <svg
        height={SIZE}
        width={SIZE}
        fill="transparent"
        style={{ position: "absolute", top: 0, left: 0 }}
      >
        <StyledCircle
          filled={filled}
          stroke-width={STROKE_WIDTH}
          fill="transparent"
          r={RADIUS - STROKE_WIDTH * 2}
          cx={RADIUS}
          cy={RADIUS}
        ></StyledCircle>
      </svg>
      {icon === PauseButtonIcon.PAUSE ? (
        <StyledPauseIcon />
      ) : (
        <StyledPlayIcon />
      )}
    </StyledPauseButton>
  );
};

export default PauseButton;
