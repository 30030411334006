import { FC, useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStopwatch } from "react-timer-hook";
import Container from "../../components/Container";
import {
  TrainingType,
  useMathTraining,
} from "../../providers/MathTrainingProvider";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import getNextQuestions from "./getNextQuestions";
import TestingFooter from "./TestingFooter";
import QuestionContainer from "./QuestionContainer";
import sum from "../../utils/sum";
import { Button, Heading } from "hp-fabric";

const SubjectTesting: FC = () => {
  const { subject } = useParams() as { subject: TrainingType };
  const { getTrainingData, registerAnswer, settings, latestResult } =
    useMathTraining();
  const trainingData = getTrainingData(subject);
  const [currentQuestions, setCurrentQuestions] = useState(
    getNextQuestions({ subject, trainingData })
  );
  const navigate = useNavigate();
  const { minutes, seconds, reset } = useStopwatch({
    autoStart: true,
  });
  useEffect(() => {
    if (settings.type === "minutes") {
      if (minutes >= settings.amount) {
        navigate(`/math-training/${subject}/result`);
      }
    }
    if (settings.type === "questions") {
      if (latestResult.length >= settings.amount) {
        navigate(`/math-training/${subject}/result`);
      }
    }
    if (currentQuestions.length === 1) {
      setCurrentQuestions([
        currentQuestions[0],
        ...getNextQuestions({ subject, trainingData }),
      ]);
    }
  }, [
    currentQuestions,
    currentQuestions.length,
    latestResult.length,
    minutes,
    navigate,
    settings.amount,
    settings.type,
    subject,
    trainingData,
  ]);

  const totalTime =
    sum(latestResult.map((r) => r.seconds)) + minutes * 60 + seconds;

  const progress =
    settings.type === "minutes"
      ? (totalTime / (settings.amount * 60)) * 100
      : (latestResult.length / settings.amount) * 100;

  const currentQuestion = currentQuestions[0];

  const handleAnswer = useCallback(
    (value: number) => {
      const answer = {
        seconds: minutes * 60 + seconds,
        timestamp: new Date().getTime(),
        answer: value,
      };
      registerAnswer(
        subject,
        currentQuestion.arg1,
        currentQuestion.arg2,
        answer
      );
      reset();
      setCurrentQuestions(currentQuestions.slice(1));
    },
    [
      currentQuestion.arg1,
      currentQuestion.arg2,
      currentQuestions,
      minutes,
      registerAnswer,
      reset,
      seconds,
      subject,
    ]
  );

  return (
    <Container
      header={{
        centerComponent: (
          <Heading level={1} size="s">
            {capitalizeFirstLetter(subject)}
          </Heading>
        ),
        rightComponent: (
          <Button
            kind="primary"
            disabled={!latestResult.length}
            link={{ to: `/math-training/${subject}/result`, component: Link }}
          >
            Rätta
          </Button>
        ),
      }}
    >
      <QuestionContainer
        progress={progress}
        arg1={currentQuestion.arg1}
        arg2={currentQuestion.arg2}
        subject={subject}
      />
      <TestingFooter handleAnswer={handleAnswer} />
    </Container>
  );
};

export default SubjectTesting;
