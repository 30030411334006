import { FC } from "react";
import { useParams } from "react-router-dom";
import styled, { css, useTheme } from "styled-components";
import DialUpChart from "../../../components/DialUpChart";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  TrainingAnswer,
  TrainingArgument,
  TrainingType,
  useMathTraining,
} from "../../../providers/MathTrainingProvider";
import getFinishedLevelFromTrainingData from "../../../utils/getFinishedLevelFromTrainingData";
import getColorForChartSquare from "../SubjectChart/getColorForChartSquare";
import QuestionText from "./QuestionText";
import stringifyTime from "../../../utils/stringifyTime";
import { Text } from "hp-fabric";

const StyledAccessTimeIcon = styled(AccessTimeIcon)`
  color: ${(props) =>
    props.theme.isDark ? props.theme.colors.gray5 : props.theme.colors.gray7};
`;

const StyledTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledAverageTimeContainer = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 5px;
`;

const NUMBER_OF_BOXES = 7;

const StyledTextContainer = styled.div`
  width: 145px;
`;

const StyledChartContainer = styled.div`
  width: 80px;
`;

const GenralInfoContainer = styled.div`
  min-height: ${(props) =>
    (Math.min(600, props.theme.dimensions.width || 0) / 100) * 20}px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const QuestionInfoContainer = styled.div`
  min-height: ${(props) =>
    (Math.min(600, props.theme.dimensions.width || 0) / 100) * 20}px;
  padding-left: ${(props) =>
    (Math.min(600, props.theme.dimensions.width || 0) / 100) * 7}px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding-right: ${(props) =>
    (Math.min(600, props.theme.dimensions.width || 0) / 100) * 1}px;
`;

const StyledBoxArray = styled.div`
  box-sizing: border-box;
  display: flex;
  margin-left: auto;
`;

const Box = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) =>
    (Math.min(600, props.theme.dimensions.width || 0) / 100) * 9}px;
  height: ${(props) =>
    (Math.min(600, props.theme.dimensions.width || 0) / 100) * 9}px;
  background-color: ${(props) => props.color};
  flex-shrink: 0;
  margin-left: ${(props) =>
    (Math.min(600, props.theme.dimensions.width || 0) / 100) * 1.2}px;
`;

export type QuestionData = {
  arg1: TrainingArgument;
  arg2: TrainingArgument;
  history: TrainingAnswer[];
};

type Props = {
  selectedQuestion: QuestionData | undefined;
};

const CardHeader: FC<Props> = ({ selectedQuestion }) => {
  const { getTrainingData } = useMathTraining();
  const { subject } = useParams() as { subject: TrainingType };
  const theme = useTheme();
  const trainingData = getTrainingData(subject);
  const finishedLevel = getFinishedLevelFromTrainingData({
    trainingData,
    subject,
  });
  const fontSize = (Math.min(600, theme.dimensions.width || 0) / 100) * 6;
  return selectedQuestion ? (
    <QuestionInfoContainer>
      <StyledTopContainer>
        <QuestionText
          arg1={selectedQuestion.arg1}
          arg2={selectedQuestion.arg2}
          subject={subject}
          fontSize={fontSize}
        />
        {!!selectedQuestion.history[selectedQuestion.history.length - 1] && (
          <StyledAverageTimeContainer>
            <StyledAccessTimeIcon sx={{ fontSize: `${fontSize}px` }} />
            <Text
              customCss={css`
                font-size: ${fontSize}px;
              `}
            >
              {stringifyTime(
                selectedQuestion.history[selectedQuestion.history.length - 1]
                  .seconds
              )}
            </Text>
          </StyledAverageTimeContainer>
        )}
      </StyledTopContainer>
      <StyledBoxArray>
        {selectedQuestion.history
          .slice(-NUMBER_OF_BOXES)
          .map((answer, index) => {
            const questionHistory = selectedQuestion.history
              .slice(-NUMBER_OF_BOXES)
              .slice(0, index + 1);
            const color = getColorForChartSquare({
              arg1: selectedQuestion.arg1,
              arg2: selectedQuestion.arg2,
              theme,
              subject,
              history: questionHistory,
            });
            return (
              <Box color={color} key={index}>
                <Text
                  weight={800}
                  color={theme.colors.gray10}
                  customCss={css`
                    font-size: ${(Math.min(600, theme.dimensions.width || 0) /
                      100) *
                    4}px;
                  `}
                >
                  {answer.answer}
                </Text>
              </Box>
            );
          })}
      </StyledBoxArray>
    </QuestionInfoContainer>
  ) : (
    <GenralInfoContainer>
      <StyledTextContainer>
        <Text
          color={theme.isDark ? theme.colors.gray5 : theme.colors.gray8}
          customCss={css`
            font-size: 10px;
            line-height: 12px;
          `}
        >
          Få tre rätt i rad på alla uppgifterna för att uppnå 100%
        </Text>
      </StyledTextContainer>
      <StyledChartContainer>
        <DialUpChart score={finishedLevel} />
      </StyledChartContainer>
    </GenralInfoContainer>
  );
};

export default CardHeader;
