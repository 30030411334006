import { FC, createContext, useContext, ReactNode } from "react";
import { scaleBand, scaleLinear } from "@visx/scale";
import { ScaleLinear, ScaleBand } from "d3-scale";

export type BarType = {
  label: string;
  value: number;
};

type BarChartContextInputType = {
  width: number;
  height: number;
  marginTop: number;
  marginLeft: number;
  marginBottom: number;
  marginRight: number;
  bars: BarType[];
};

type BarChartContextOutput = {
  xScale: ScaleBand<string>;
  yScale: ScaleLinear<number, number, never>;
} & BarChartContextInputType;

export const BarChartContext = createContext<BarChartContextOutput | null>(
  null
);

export const useBarChart = (): BarChartContextOutput => {
  const context = useContext(BarChartContext);
  if (!context) {
    throw new Error("useSettings must be inside the SettingsProvider");
  }
  return context;
};

const BarChartProvider: FC<
  BarChartContextInputType & { children: ReactNode }
> = ({
  width,
  height,
  marginTop,
  marginLeft,
  marginBottom,
  marginRight,
  bars,
  children,
}) => {
  const yScale = scaleLinear({
    domain: [0, Math.max(...bars.map(({ value }) => value))],
    range: [height - marginTop, marginTop],
  });

  const xScale = scaleBand({
    domain: bars.map(({ label }) => label),
    range: [0, width - marginRight],
    padding: 0.4,
  });

  return (
    <BarChartContext.Provider
      value={{
        width,
        height,
        marginTop,
        marginLeft,
        marginBottom,
        marginRight,
        bars,
        xScale,
        yScale,
      }}
    >
      <svg
        width={width}
        height={height + marginTop + marginBottom}
        style={{ marginLeft: "auto", marginRight: "auto" }}
      >
        {children}
      </svg>
    </BarChartContext.Provider>
  );
};

export default BarChartProvider;
