import { FC } from "react";
import { Arc } from "@visx/shape";
import { useSpring, animated, config } from "@react-spring/web";
import { useTheme } from "styled-components";

const ARC_LENGTH = 1.1 * Math.PI;
const START_ANGLE = -ARC_LENGTH / 2;
const END_ANGLE = ARC_LENGTH / 2;
const NUMBER_OF_BARS = 41;
const NUMBER_OF_MASKS = 40;
const CHART_WIDTH = 200;
const CHART_HEIGHT = 130;
const CIRCLE_CENTER_X = CHART_WIDTH / 2;
const CIRCLE_CENTER_Y = 100;
const CIRCLE_CENTER = `translate(${CIRCLE_CENTER_X}px,${CIRCLE_CENTER_Y}px)`;

type Props = {
  score: number;
};

const AnimatedArc = animated(Arc);

const DialUpChart: FC<Props> = ({ score }) => {
  const theme = useTheme();
  const barWidth = ARC_LENGTH / (NUMBER_OF_BARS + 3 * NUMBER_OF_MASKS);
  const maskWidth = 3 * barWidth;
  const maskAngles = Array.from(Array(NUMBER_OF_MASKS)).map((_, i) => {
    return {
      startAngle: START_ANGLE + i * (barWidth + maskWidth) + barWidth,
      endAngle: START_ANGLE + i * (barWidth + maskWidth) + 4 * barWidth,
    };
  });

  const { endAngle, value } = useSpring({
    from: { endAngle: START_ANGLE, value: 0 },
    to: { endAngle: START_ANGLE + (ARC_LENGTH * score) / 100, value: score },
    delay: 200,
    config: config.molasses,
  });

  return (
    <svg
      height="100%"
      viewBox={`0 0 ${CHART_WIDTH} ${CHART_HEIGHT}`}
      width="100%"
    >
      <defs>
        <linearGradient id="gradient">
          <stop offset="-4%" stopColor={theme.colors.positive0} />
          <stop offset="104%" stopColor={theme.colors.positive2} />
        </linearGradient>
      </defs>
      <AnimatedArc
        endAngle={END_ANGLE}
        fill={theme.colors.borderGray}
        innerRadius={70}
        outerRadius={98}
        startAngle={START_ANGLE}
        style={{ transform: CIRCLE_CENTER }}
      />
      <AnimatedArc
        endAngle={endAngle}
        fill={"url(#gradient)"}
        innerRadius={70}
        outerRadius={98}
        startAngle={START_ANGLE}
        style={{ transform: CIRCLE_CENTER }}
      />
      <Arc
        cornerRadius={3}
        endAngle={END_ANGLE}
        fill={theme.colors.borderGray} // #e7e9f0
        innerRadius={63}
        outerRadius={65}
        startAngle={START_ANGLE}
        style={{ transform: CIRCLE_CENTER }}
      />
      {maskAngles.map((mask) => (
        <Arc
          key={mask.startAngle}
          endAngle={mask.endAngle}
          fill={theme.colors.surface}
          innerRadius={40}
          outerRadius={100}
          startAngle={mask.startAngle}
          style={{ transform: CIRCLE_CENTER }}
        />
      ))}
      <animated.text
        fill={theme.colors.textPrimary}
        fontFamily={"Lato"}
        fontSize={40}
        fontWeight={800}
        textAnchor="middle"
        x={CIRCLE_CENTER_X + 5}
        y={CIRCLE_CENTER_Y}
      >
        {value.to((n) => `${n.toFixed(0)}%`)}
      </animated.text>
    </svg>
  );
};

export default DialUpChart;
