import { Text } from "hp-fabric";
import { FC } from "react";
import styled, { css, DefaultTheme, useTheme } from "styled-components";
import Card from "../../../../components/Card";
import segments from "../../../../data/segments";
import { AnswerData, useAnswers } from "../../../../providers/AnswersProvider";
import getLatestAnswerForQuestion from "../../../../utils/getLatestAnswerForQuestion";
import makeAltText from "../../../../utils/makeAltText";
import ProblemImage from "./ProblemImage";
import { Question } from "hp-types";

const CardContent = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spaces.m}px;
  flex-direction: column;
  @media (min-width: 400px) {
    flex-direction: row;
  }
`;

const TopLineContainer = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
`;

const TopLine = styled.div<{ color: string }>`
  width: 50%;
  height: 13;
  text-align: center;
  background-color: ${(props) => props.color};
  @media (min-width: 400px) {
    font-size: 11;
  }
`;

const ImageContainer = styled.div`
  box-sizing: border-box;
  height: 176px;
  width: calc(100% + ${(props) => props.theme.spaces.m * 2}px);
  min-height: 176px;
  min-width: 176px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #fff;
  ${(props) => props.theme.isDark && `filter: brightness(0.98);`}
  border-right: 1px solid ${(props) => props.theme.colors.borderGray};
  margin-top: -${(props) => props.theme.spaces.m}px;
  padding: 2px;
  padding-top: 11px;
  margin-bottom: -${(props) => props.theme.spaces.m}px;
  margin-left: -${(props) => props.theme.spaces.m}px;
  @media (min-width: 400px) {
    height: 130px;
    width: 130px;
    min-height: 130px;
    min-width: 130px;
  }
`;

const InfoContainer = styled.div`
  width: 100%;
  padding-top: ${(props) => props.theme.spaces.m}px;
  @media (min-width: 400px) {
    padding-top: 0;
  }
`;

const getAnswerData = (
  answer: AnswerData | undefined,
  theme: DefaultTheme,
  question: Question
): { color: string; name: string } => {
  if (!answer) {
    return { color: theme.colors.unanswered, name: "Obesvarad" };
  }
  if (answer.answer !== question.solution) {
    return { color: theme.colors.incorrect, name: "Felbesvarad" };
  }
  if (answer.seconds > segments[question.segment].secondsPerQuestion) {
    return { color: theme.colors.tooSlow, name: "För långsam" };
  }
  return { color: theme.colors.correct, name: "Rättsvarad" };
};

type Props = {
  question: Question;
};

const QuestionCard: FC<Props> = ({ question }) => {
  const theme = useTheme();
  const { answers } = useAnswers();
  const answer = getLatestAnswerForQuestion(answers, question.id);
  const { color: answerColor, name: answerText } = getAnswerData(
    answer,
    theme,
    question
  );
  return (
    <Card to={`/question/${question.id}`}>
      <CardContent>
        <ImageContainer>
          <TopLineContainer>
            <TopLine
              color={theme.colors[question.segment.toLowerCase() as "xyz"]}
            >
              <Text
                customCss={css`
                  font-size: 10px;
                  @media (min-width: 400px) {
                    font-size: 8px;
                  }
                `}
              >
                {question.segment}
              </Text>
            </TopLine>
            <TopLine color={answerColor}>
              <Text
                customCss={css`
                  font-size: 10px;
                  @media (min-width: 400px) {
                    font-size: 8px;
                  }
                `}
              >
                {answerText}
              </Text>
            </TopLine>
          </TopLineContainer>
          <ProblemImage alt={makeAltText(question)} path={question.image} />
        </ImageContainer>
        <InfoContainer>
          <div>
            <Text>Datum: {question.date}</Text>
            <Text>Provpass: {question.partNumber}</Text>
            <Text>Fråga: {question.questionNumber}</Text>
          </div>
        </InfoContainer>
      </CardContent>
    </Card>
  );
};

export default QuestionCard;
