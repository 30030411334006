import { Header } from "hp-fabric";
import { FC, ReactNode, useEffect } from "react";
import styled from "styled-components";

const OuterContainer = styled.div<{ bottomOffset?: number }>`
  background-color: ${(props) => props.theme.colors.backgroundDark};
  min-height: ${(props) =>
    props.theme.dimensions.height
      ? `${props.theme.dimensions.height - (props.bottomOffset || 0)}px`
      : "100vh"};
  width: 100vw;
  ${(props) =>
    props.bottomOffset &&
    `padding-bottom: ${props.bottomOffset + 3 * props.theme.spaces.m}px;`}
`;

const InnerContainer = styled.div`
  padding: ${(props) => props.theme.spaces.m}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    max-width: 600px;
  }
`;

type Props = {
  header: {
    leftComponent?: ReactNode;
    centerComponent?: ReactNode;
    rightComponent?: ReactNode;
  };
  bottomOffset?: number;
  children: ReactNode;
};

const Container: FC<Props> = ({ children, header, bottomOffset }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <OuterContainer bottomOffset={bottomOffset}>
      <Header {...header} />
      <InnerContainer>{children}</InnerContainer>
    </OuterContainer>
  );
};

export default Container;
