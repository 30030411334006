import { Link, useParams } from "react-router-dom";
import { useQuestions } from "../../providers/QuestionsProvider";
import { QuestionPage } from "hp-fabric";
import getQuestionFromId from "../../utils/getQuestionFromId";
import { useMetaData } from "../../providers/MetaDataProvider/MetaDataProvider";
import { useDtkObject } from "../../providers/DtkObjectProvider/DtkObjectProvider";
import MenuIcon from "../../components/MenuIcon";
import { useAnswers } from "../../providers/AnswersProvider";
import { useMutation } from "react-query";
import { addFeedback } from "../../data/queries/addFeedback";
import { FEEDBACK } from "hp-types";

const Question = () => {
  const { questionId } = useParams();
  const { questions, loadingQuestions } = useQuestions();
  const { sections, loadingMetaData } = useMetaData();
  const { dtkObject, loadingDtkObject } = useDtkObject();
  const { answers } = useAnswers();
  const question = questionId && getQuestionFromId(questions, questionId);
  const { mutate } = useMutation(addFeedback);

  if (!question) return null;

  const loading = loadingQuestions || loadingMetaData || loadingDtkObject;

  const sendResourceError = (questionId: string, errorText: string) => {
    mutate({
      questionId,
      message: errorText,
      feedbackType: FEEDBACK.RESOURCE,
    });
  };

  const sendError = (errorText: string) => {
    mutate({
      questionId,
      message: errorText,
      feedbackType: FEEDBACK.QUESTION,
    });
  };

  return (
    <QuestionPage
      question={question}
      sections={sections}
      dtkObject={dtkObject?.[question?.segmentData || ""]}
      loading={loading}
      menuIcon={<MenuIcon />}
      linkComponent={Link}
      questions={questions}
      answers={answers}
      sendQuestionResourceError={sendResourceError}
      sendQuestionError={sendError}
    />
  );
};

export default Question;
