import { useEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";
import { Section, SectionName } from "../../../../data/sections";

type Props = {
  section: Section;
  xCenter: number;
  yCenter: number;
  highlighted: boolean;
  setSelectedNode: (sectionName: SectionName | null) => void;
};

const Node = ({
  section,
  xCenter,
  yCenter,
  highlighted,
  setSelectedNode,
}: Props) => {
  const ref = useRef<SVGTextElement | null>(null);
  const margin = 5;
  const [width, setWidth] = useState(
    (ref.current?.getBBox().width || 0) + 2 * margin
  );
  const height = 20;
  const theme = useTheme();
  useEffect(() => {
    if (ref.current?.getBBox()) {
      setWidth((ref.current?.getBBox().width || 0) + 2 * margin);
    }
  }, [ref]);
  return (
    <g onClick={() => setSelectedNode(section.name)}>
      <rect
        x={xCenter - width / 2}
        y={yCenter - height / 2 - margin}
        width={width}
        height={height}
        stroke={
          highlighted ? theme.colors.textPrimary : theme.colors.borderGray
        }
        strokeWidth="1"
        fill={theme.colors.surface}
        rx="2"
      />
      <text
        cursor="default"
        ref={ref}
        transform={`translate(${xCenter - width / 2 + margin}, ${
          yCenter - height / 2 + margin * 1.9
        })`}
        fontSize={12}
        fill={theme.colors.textPrimary}
      >
        {section.name}
      </text>
      {width}
    </g>
  );
};

export default Node;
