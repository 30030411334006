import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CurrentQuestion } from "hp-types";
import { FC } from "react";
import QuestionCarousel from "./QuestionCarousel";
import styled from "styled-components";
import { Text } from "hp-fabric";

const StyledAccordionContainer = styled.div<{ color: string }>`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 6px solid ${({ color }) => color};
`;

const StyledAccordion = styled(Accordion)`
  background-color: ${(props) => props.theme.colors.surface} !important;
  > div > .MuiAccordionSummary-expandIconWrapper {
    color: ${(props) => props.theme.colors.textPrimary};
  }
`;

type Props = {
  name: string;
  color: string;
  result: CurrentQuestion[];
};

const QuestionAccordion: FC<Props> = ({ color, name, result }) => {
  return (
    <StyledAccordionContainer color={color}>
      <StyledAccordion disableGutters elevation={0} square sx={{}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Text>{`${name} (${result.length})`}</Text>
        </AccordionSummary>
        <AccordionDetails>
          <QuestionCarousel result={result} />
        </AccordionDetails>
      </StyledAccordion>
    </StyledAccordionContainer>
  );
};

export default QuestionAccordion;
