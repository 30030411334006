import { FC } from "react";
import styled from "styled-components";

const StyledProblemImage = styled.img`
  box-sizing: border-box;
  display: block;
  max-height: 165px;
  padding: 2px;
  padding-top: 8px;
  width: 100%;
  max-width: 100%;
  object-fit: contain;
  @media (min-width: 400px) {
    max-height: 118px;
    padding-top: 3px;
    width: 98%;
    max-width: 98%;
  }
`;

const StyledPlaceholder = styled.div`
  height: 24px;
`;

type Props = {
  path?: string;
  alt?: string;
  width?: number;
  height?: number;
  style?: Record<string, string>;
};

const ProblemImage: FC<Props> = ({ path, alt = "" }) => {
  return path ? (
    <StyledProblemImage src={path} alt={alt} />
  ) : (
    <StyledPlaceholder />
  );
};

export default ProblemImage;
