import { FC } from "react";
import styled, { useTheme } from "styled-components";
import {
  TrainingArgument,
  TrainingType,
} from "../../../providers/MathTrainingProvider";
import { useTransition, animated, config } from "@react-spring/web";
import { TRAINING_FOOTER_HEIGHT } from "../TestingFooter";
import { LinearProgress } from "@mui/material";
import getSubjectSign from "../../../utils/getSubjectSign";

const StyledProgressbar = styled.div`
  width: 100vw;
  height: 10px;
  position: absolute;
  top: 0;
  background-color: "#fff";
  z-index: 2200;
`;

const StyledQuestionContainer = styled.div<{
  largeHeight: number;
  smallHeight: number;
}>`
  position: relative;
  margin-top: -${(props) => props.theme.spaces.m}px;
  min-height: ${(props) => props.smallHeight}px;
  max-height: ${(props) => props.smallHeight}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 400px) {
    min-height: ${(props) => props.largeHeight}px;
    max-height: ${(props) => props.largeHeight}px;
  }
`;

type Props = {
  arg1: TrainingArgument;
  arg2: TrainingArgument;
  progress: number;
  subject: TrainingType;
};

const QuestionContainer: FC<Props> = ({ arg1, arg2, progress, subject }) => {
  const theme = useTheme();

  const smallHeight =
    (theme?.dimensions?.height || window?.visualViewport?.height || 0) -
    theme.spaces.xxl -
    TRAINING_FOOTER_HEIGHT;
  const largeHeight =
    (theme?.dimensions?.height || window?.visualViewport?.height || 0) -
    theme.spaces.xxxl -
    TRAINING_FOOTER_HEIGHT;

  const height =
    (theme?.dimensions?.width || window?.visualViewport?.width || 0) > 400
      ? largeHeight
      : smallHeight;

  const screenWidth =
    (theme?.dimensions?.width || window?.visualViewport?.width || 0) -
    2 * theme.spaces.m;
  const width = Math.min(screenWidth, 600);

  const top = -height;
  const middle = height * 0.65;
  const bottom = 2 * height;
  const arg1YTransition = useTransition(arg1, {
    from: { transform: `translate(${width * 0.25},${top})` },
    enter: { transform: `translate(${width * 0.25},${middle})` },
    leave: { transform: `translate(${width * 0.25},${bottom})` },
    config: config.molasses,
    delay: 1,
    key: `${arg1}${arg2}`,
  });

  const arg2YTransition = useTransition(arg2, {
    from: { transform: `translate(${width * 0.75},${bottom})` },
    enter: { transform: `translate(${width * 0.75},${middle})` },
    leave: { transform: `translate(${width * 0.75},${top})` },
    config: config.molasses,
    delay: 1,
    key: `${arg1}${arg2}`,
  });

  const fontSize = Math.min(125, height * 0.7);

  return (
    <StyledQuestionContainer
      largeHeight={largeHeight}
      smallHeight={smallHeight}
    >
      <StyledProgressbar>
        <LinearProgress variant="determinate" value={progress} />
      </StyledProgressbar>
      <svg height={height} viewBox={`0 0 ${width} ${height}`}>
        {arg1YTransition(({ transform }, item) => {
          return (
            <animated.text
              textAnchor="middle"
              fontSize={fontSize}
              transform={transform}
              fill={theme.colors.textPrimary}
            >
              {item}
            </animated.text>
          );
        })}
        <text
          fill={theme.colors.textPrimary}
          textAnchor="middle"
          fontSize={fontSize}
          x={width / 2}
          y={middle}
        >
          {getSubjectSign(subject)}
        </text>
        {arg2YTransition(({ transform }, item) => {
          return (
            <animated.text
              textAnchor="middle"
              fontSize={fontSize}
              fill={theme.colors.textPrimary}
              transform={transform}
            >
              {item}
            </animated.text>
          );
        })}
      </svg>
    </StyledQuestionContainer>
  );
};

export default QuestionContainer;
