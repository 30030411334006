import { FC, useState } from "react";
import { CurrentQuestion } from "hp-types";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { IconButton, Stack } from "@mui/material";
import stringifyTime from "../../../../../../utils/stringifyTime";
import segments from "../../../../../../data/segments";
import VideosModal from "./VideosModal";
import ImageModal from "./ImageModal";
import styled from "styled-components";
import makeAltText from "../../../../../../utils/makeAltText";
import { Button, Text } from "hp-fabric";

const StyledSquareImage = styled.div`
  position: relative;
  width: 100%;
  background-color: ${(props) =>
    props.theme.isDark ? props.theme.colors.gray0 : props.theme.colors.surface};
  box-shadow: 0 0 25px -5px rgb(0 0 0 / 20%);
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.theme.isDark && `filter: brightness(0.98);`}

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

const StyledImage = styled.img`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 95%;
  object-fit: contain;
`;

type Props = {
  question: CurrentQuestion;
};

export const QuestionSlide: FC<Props> = ({ question }) => {
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        padding: "0px 14px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 5,
        }}
      >
        <Text>{question.date}</Text>
        <Text>Provpass {question.partNumber}</Text>
      </div>
      <StyledSquareImage>
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <IconButton
            size="small"
            aria-label="enlarge picture"
            onClick={() => setOpenImageModal(true)}
          >
            <ZoomInIcon />
          </IconButton>
        </div>
        <StyledImage src={question.image} alt={makeAltText(question)} />
      </StyledSquareImage>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          marginTop: 5,
          marginBottom: 10,
        }}
      >
        <Text>Fråga {question.questionNumber}</Text>
        <Text>{question.segment}</Text>
      </div>
      <div
        style={{
          width: "100%",
        }}
      >
        <Stack spacing={1}>
          <Stack>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Text overflowWidth={"100%"} noWrap>
                Ditt svar:
              </Text>
              <Text overflowWidth={"100%"}>{question.answer}</Text>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Text overflowWidth={"100%"} noWrap>
                Din tid:
              </Text>
              <Text>{stringifyTime(question.seconds)}</Text>
            </Stack>
          </Stack>
          <Stack>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Text overflowWidth={"100%"} noWrap>
                Facit:
              </Text>
              <Text>{question.solution}</Text>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Text overflowWidth={"100%"} noWrap>
                Rekommenderad tid:
              </Text>
              <Text>
                {stringifyTime(segments[question.segment].secondsPerQuestion)}
              </Text>
            </Stack>
          </Stack>
        </Stack>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "fit-content",
            marginTop: 16,
          }}
        >
          <Button kind="secondary" onClick={() => setOpenVideoModal(true)}>
            Se videolösningar
          </Button>
        </div>
        {openVideoModal && (
          <VideosModal
            open={openVideoModal}
            setOpen={setOpenVideoModal}
            question={question}
          />
        )}
        {openImageModal && (
          <ImageModal
            open={openImageModal}
            setOpen={setOpenImageModal}
            question={question}
          />
        )}
      </div>
    </div>
  );
};
