import { CircularProgress, Stack } from "@mui/material";
import { Text } from "hp-fabric";
import { FC } from "react";
import styled from "styled-components";
import { VictoryPie, VictoryContainer } from "victory";
import Loader from "../Loader";
import { MicroLegend } from "../MicroLegend";

const PieContainer = styled(Stack)`
  max-width: 300px;
  justify-content: center;
  align-items: center;
`;

const LegendContainer = styled.div<{ direction?: string }>`
  display: flex;
  flex-direction: column;
  margin-top: -18px;
  margin-left: 18px;
  @media (min-width: 400px) {
    margin: 0;
  }
`;

const TooltipWrapper: FC = () => <svg width="100%" height="100%"></svg>;

type Props = {
  colorScale: string[];
  legendData?: { name: string }[];
  data: number[];
  direction?: "column" | "row";
  loading?: boolean;
};
const PieChart: FC<Props> = ({
  direction = "column",
  colorScale,
  legendData,
  data,
  loading,
}) => {
  if (!legendData) {
    if (loading) {
      return <CircularProgress />;
    }
    return (
      <VictoryPie
        labelComponent={<TooltipWrapper />}
        animate={{
          easing: "cubicInOut",
          duration: 300,
        }}
        colorScale={colorScale}
        data={data}
        containerComponent={
          <VictoryContainer
            style={{
              pointerEvents: "auto",
              touchAction: "auto",
            }}
          />
        }
      />
    );
  }

  return (
    <PieContainer direction={direction}>
      {loading ? (
        <Loader />
      ) : (
        <VictoryPie
          labelComponent={<TooltipWrapper />}
          animate={{
            easing: "cubicInOut",
            duration: 300,
          }}
          colorScale={colorScale}
          data={data}
          containerComponent={
            <VictoryContainer
              style={{
                pointerEvents: "auto",
                touchAction: "auto",
              }}
            />
          }
        />
      )}
      <LegendContainer direction={direction}>
        {legendData.map((legend, index) => (
          <Stack
            key={legend.name}
            sx={{ alignItems: "center" }}
            direction="row"
            spacing={1}
          >
            <MicroLegend color={colorScale[index]} />
            <Text noWrap>{legend.name}</Text>
          </Stack>
        ))}
      </LegendContainer>
    </PieContainer>
  );
};

export default PieChart;
