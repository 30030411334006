import { useAnswers } from "../../providers/AnswersProvider";
import { FC } from "react";
import PieChart from "../PieChart";
import splitQuestionsOnHistory from "../../utils/splitQuestionsOnHistory";
import useCurrentTimeStamp from "../../containers/Results/utils/useCurrentTimeStamp";
import getLatestAnswerForQuestion from "../../utils/getLatestAnswerForQuestion";
import { CurrentQuestion } from "hp-types";
import { useTheme } from "styled-components";

type Props = {
  result: CurrentQuestion[];
  legends?: boolean;
  direction?: "column" | "row";
  loading?: boolean;
};

const CurrentTestHistoryPieChart: FC<Props> = ({
  direction,
  legends,
  loading,
  result,
}) => {
  const theme = useTheme();
  const currentTimeStamp = useCurrentTimeStamp();
  const { answers } = useAnswers();
  const { incorrect, tooSlow, correct } = splitQuestionsOnHistory(
    answers,
    result.filter(({ id }) => {
      const answer = getLatestAnswerForQuestion(answers, id);
      return answer?.timeStamp === currentTimeStamp;
    })
  );
  const unanswered = result.filter(({ answer }) => !answer);
  return (
    <PieChart
      loading={loading}
      direction={direction}
      legendData={
        legends
          ? [
              { name: `Obesvarade (${unanswered.length})` },
              { name: `Felsvarade (${incorrect.length})` },
              { name: `För långsamma (${tooSlow.length})` },
              { name: `Rättsvarade (${correct.length})` },
            ]
          : undefined
      }
      colorScale={[
        theme.colors.unanswered,
        theme.colors.incorrect,
        theme.colors.tooSlow,
        theme.colors.correct,
      ]}
      data={[
        unanswered.length,
        incorrect.length,
        tooSlow.length,
        correct.length,
      ]}
    />
  );
};

export default CurrentTestHistoryPieChart;
