import { doc, getDoc } from "firebase/firestore";
import { DTKapiData, DTKobject } from "hp-types";
import { fireStore } from "../firebase";

const convertApiToLocalDtkObject = (apiObject: DTKapiData): DTKobject => ({
  ...apiObject,
  images: apiObject.images ? Object.values(apiObject.images) : [],
});

export const fetchDtkObject = async (): Promise<Record<string, DTKobject>> => {
  const reference = doc(fireStore, "questions", "dtk");
  const dtkApiData = (await getDoc(reference).then((doc) =>
    doc.data()
  )) as Record<string, DTKapiData>;

  let parsedDtkData =
    dtkApiData &&
    Object.values(dtkApiData).reduce((previous, current) => {
      return {
        ...previous,
        [current.id]: convertApiToLocalDtkObject(current),
      };
    }, {});

  return parsedDtkData;
};

export default fetchDtkObject;
