import { Filter } from "hp-types";
import { Question } from "../../data/questions";
import { AnswerData } from "../AnswersProvider";
import checkCorrectFilter from "./checkCorrectFilter";
import checkDTKFilter from "./checkDTKFilter";
import checkIncorrectFilter from "./checkIncorrectFilter";
import checkKVAFilter from "./checkKVAFilter";
import checkNOGFilter from "./checkNOGFilter";
import checkTestsFilter from "./checkTestsFilter";
import checkTooSlowFilter from "./checkTooSlowFilter";
import checkUnansweredFilter from "./checkUnansweredFilter";
import checkXYZFilter from "./checkXYZFilter";
import { checkSectionsFilter } from "./checkSectionsFilter";

export const filterQuestions = (
  questions: Question[] | undefined,
  filter: Filter,
  answers: AnswerData[]
) => {
  const {
    tests,
    unanswered,
    incorrect,
    tooSlow,
    correct,
    XYZ,
    KVA,
    NOG,
    DTK,
    sections,
    exclusiveSections,
  } = filter;
  const filtered =
    questions?.filter((q) => {
      const testsFilter = checkTestsFilter(tests, q);
      const unansweredFilter = checkUnansweredFilter(unanswered, answers, q);
      const incorrectFilter = checkIncorrectFilter(incorrect, answers, q);
      const tooSlowFilter = checkTooSlowFilter(tooSlow, answers, q);
      const correctFilter = checkCorrectFilter(correct, answers, q);
      const XYZFilter = checkXYZFilter(XYZ, q);
      const KVAFilter = checkKVAFilter(KVA, q);
      const NOGFilter = checkNOGFilter(NOG, q);
      const DTKFilter = checkDTKFilter(DTK, q);
      const sectionsFilter = checkSectionsFilter(
        sections,
        exclusiveSections,
        q
      );

      return (
        testsFilter &&
        unansweredFilter &&
        incorrectFilter &&
        tooSlowFilter &&
        correctFilter &&
        XYZFilter &&
        KVAFilter &&
        NOGFilter &&
        DTKFilter &&
        sectionsFilter
      );
    }) || [];
  return filtered;
};
