import { useTheme } from "styled-components";
import BarChartProvider, { BarType } from "./BarChartProvider";
import Bars from "./Bars";
import BottomAxis from "./BottomAxis";
import LeftAxis from "./LeftAxis";

const CHART_MARGIN_LEFT = 30;
const CHART_MARGIN_TOP = 5;
const CHART_MARGIN_BOTTOM = 95;
const CHART_MARGIN_RIGHT = 60;
const CHART_HEIGHT = 200;

type Props = {
  bars: BarType[];
};

const BarChart = ({ bars }: Props) => {
  const theme = useTheme();

  const CHART_WIDTH = (theme.dimensions.width || 0) - theme.spaces.m * 2;

  return (
    <BarChartProvider
      marginTop={CHART_MARGIN_TOP}
      marginBottom={CHART_MARGIN_BOTTOM}
      marginLeft={CHART_MARGIN_LEFT}
      marginRight={CHART_MARGIN_RIGHT}
      width={Math.min(CHART_WIDTH, 600, bars.length * 70)}
      height={CHART_HEIGHT}
      bars={bars}
    >
      <LeftAxis />
      <Bars />
      <BottomAxis />
    </BarChartProvider>
  );
};

export default BarChart;
