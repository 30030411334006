import { Segment, Solution } from "hp-types";

type QuestionInput = {
  id: string;
  date: string;
  partNumber: number;
  questionNumber: number;
  segment: Segment;
  solution: Solution;
  resources: { url: string; name: string }[];
};

const getSegment = (questionNumber: number) => {
  if (questionNumber < 13) {
    return Segment.XYZ;
  }
  if (questionNumber < 23) {
    return Segment.KVA;
  }
  return Segment.NOG;
};

const generateQuestionData = (
  date: string,
  partNumber: number,
  questionNumber: number
): QuestionInput => {
  const segment = getSegment(questionNumber);
  return {
    id: `${date}-${partNumber}-${questionNumber}`,
    date,
    partNumber,
    questionNumber,
    segment,
    resources: [],
    solution: Solution.A,
  };
};

const generateQuestions = (
  date: string,
  partNumbers: number[]
): QuestionInput[] => {
  let questions: QuestionInput[] = [];
  partNumbers.forEach((partNumber) => {
    for (let i = 1; i <= 28; i++) {
      questions.push(generateQuestionData(date, partNumber, i));
    }
  });
  return questions;
};

export const allTestSessions = [
  "2021-10-24-1",
  "2021-10-24-4",
  "2022-03-12-3",
  "2022-03-12-5",
  "2022-05-07-1",
  "2022-05-07-4",
];

const testsData = [
  {
    date: "2022-05-07",
    partNumbers: [1, 4],
    season: "våren",
  },
  {
    date: "2022-03-12",
    partNumbers: [3, 5],
    season: "våren",
  },
  {
    date: "2021-10-24",
    partNumbers: [1, 4],
    season: "hösten",
  },
];

export type QuestionId = `${number}-${number}-${number}-${number}-${number}`;

export const getQuestionInputFromId = (id: QuestionId): QuestionInput => {
  const [year, month, day, partNumber, questionNumber] = id.split("-");
  return generateQuestionData(
    `${year}-${month}-${day}`,
    Number(partNumber),
    Number(questionNumber)
  );
};

export const getTests = () => {
  return testsData.reduce((tests, testData) => {
    const {
      partNumbers: [first, second],
      ...test
    } = testData;
    return [
      ...tests,
      {
        partNumber: first,
        ...test,
      },
      {
        partNumber: second,
        ...test,
      },
    ];
  }, [] as { date: string; partNumber: number; season: string }[]);
};

export default testsData.map((test) => ({
  date: test.date,
  partNumbers: test.partNumbers,
  questions: generateQuestions(test.date, test.partNumbers),
}));
