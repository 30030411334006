import { FC } from "react";
import { useTheme } from "styled-components";
import {
  TrainingAnswer,
  TrainingArgument,
  TrainingType,
} from "../../../../providers/MathTrainingProvider";
import getColorForChartSquare from "../getColorForChartSquare";
import { useSpring, animated, easings } from "@react-spring/web";

type Props = {
  arg1: TrainingArgument;
  arg2: TrainingArgument;
  subject: TrainingType;
  history: TrainingAnswer[];
  handleSelectQuestion: (
    arg1: TrainingArgument,
    arg2: TrainingArgument
  ) => void;
};

const ChartSquare: FC<Props> = ({
  arg1,
  arg2,
  subject,
  history,
  handleSelectQuestion,
}) => {
  const theme = useTheme();
  const color = getColorForChartSquare({
    arg1,
    arg2,
    subject,
    history,
    theme,
  });
  const { fill } = useSpring({
    from: { fill: theme.colors.surface },
    to: { fill: color },
    delay: Math.floor(Math.random() * 1000),
    config: { duration: 2500, easing: easings.easeOutExpo },
  });
  return (
    <animated.rect
      key={`${arg1}${arg2}`}
      x={arg1 * 100}
      y={arg2 * 100}
      width={90}
      height={90}
      fill={fill}
      onClick={() => handleSelectQuestion(arg1, arg2)}
    />
  );
};

export default ChartSquare;
