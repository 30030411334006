import {
  FC,
  createContext,
  useContext,
  useCallback,
  useState,
  ReactNode,
} from "react";
import useLocalStorage from "../../utils/useLocalStorage";

export type TrainingType =
  | "addition"
  | "subtraktion"
  | "multiplikation"
  | "division";

export type TrainingArgument = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export const subjects: TrainingType[] = [
  "addition",
  "subtraktion",
  "multiplikation",
  "division",
];

export type TrainingAnswer = {
  answer: number;
  seconds: number;
  timestamp: number;
};

export type TrainingData = Record<
  TrainingArgument,
  Record<TrainingArgument, TrainingAnswer[]>
>;

type StoredTrainingData = Record<TrainingType, TrainingData>;

const defaultSubset = {
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
};

const defaultTrainingData = {
  1: defaultSubset,
  2: defaultSubset,
  3: defaultSubset,
  4: defaultSubset,
  5: defaultSubset,
  6: defaultSubset,
  7: defaultSubset,
  8: defaultSubset,
  9: defaultSubset,
};

const defaultStoredTrainingData: StoredTrainingData = {
  addition: defaultTrainingData,
  subtraktion: defaultTrainingData,
  multiplikation: defaultTrainingData,
  division: defaultTrainingData,
};

export type TrainingSettingsType = "minutes" | "questions";

type TrainingSettings = {
  type: TrainingSettingsType;
  amount: number;
};

const defaultSettings: TrainingSettings = {
  type: "questions",
  amount: 10,
};

export type ResultObject = {
  arg1: TrainingArgument;
  arg2: TrainingArgument;
} & TrainingAnswer;

type MathTrainingContextType = {
  getTrainingData: (type: TrainingType) => TrainingData;
  registerAnswer: (
    type: TrainingType,
    arg1: TrainingArgument,
    arg2: TrainingArgument,
    answer: TrainingAnswer
  ) => void;
  settings: TrainingSettings;
  setSettings: (settings: TrainingSettings) => void;
  latestResult: ResultObject[];
  resetResults: () => void;
};

export const MathTrainingContext =
  createContext<MathTrainingContextType | null>(null);

export const useMathTraining = (): MathTrainingContextType => {
  const context = useContext(MathTrainingContext);
  if (!context) {
    throw new Error("useMathTraining must be inside the MathTrainingProvider");
  }
  return context;
};

type Props = {
  children: ReactNode;
};

const MathTrainingProvider: FC<Props> = ({ children }) => {
  const [mathTraining, setMathTraining] = useLocalStorage<StoredTrainingData>(
    "MATH_TRAINING",
    defaultStoredTrainingData
  );

  const [settings, setSettings] = useLocalStorage<TrainingSettings>(
    "MATH_TRAINING_SETTINGS",
    defaultSettings
  );

  const [latestResult, setLatestResult] = useState<ResultObject[]>([]);

  const getTrainingData = useCallback(
    (type: TrainingType) => {
      return mathTraining[type];
    },
    [mathTraining]
  );

  const registerAnswer = useCallback(
    (
      type: TrainingType,
      arg1: TrainingArgument,
      arg2: TrainingArgument,
      answer: TrainingAnswer
    ) => {
      const trainingData = mathTraining[type];
      const previousAnswers = [...trainingData[arg1][arg2]];
      const newAnswers = [...previousAnswers, answer].slice(-10);
      const newData = {
        ...trainingData,
        [arg1]: {
          ...trainingData[arg1],
          [arg2]: newAnswers,
        },
      };
      setMathTraining({
        ...mathTraining,
        [type]: newData,
      });
      setLatestResult([...latestResult, { ...answer, arg1, arg2 }]);
    },
    [latestResult, mathTraining, setMathTraining]
  );

  return (
    <MathTrainingContext.Provider
      value={{
        getTrainingData,
        registerAnswer,
        settings,
        setSettings,
        latestResult,
        resetResults: () => setLatestResult([]),
      }}
    >
      {children}
    </MathTrainingContext.Provider>
  );
};

export default MathTrainingProvider;
