import { Dialog as MuiModal } from "@mui/material";
import { FC, ReactNode } from "react";
import styled from "styled-components";

const StyledModal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledContent = styled.div<{ padding: "l" | "m" }>`
  width: fit-content;
  background-color: ${(props) => props.theme.colors.surface};
  border-radius: 4px;
  padding: ${(props) => props.theme.spaces[props.padding]}px;
  box-shadow: 0px 2px 24px 5px rgb(0 0 0 / 20%);
`;

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  padding?: "l" | "m";
  children: ReactNode;
};

const Modal: FC<Props> = ({ open, setOpen, padding = "l", children }) => {
  return (
    <StyledModal open={open} onClose={() => setOpen(false)}>
      <StyledContent padding={padding}>{children}</StyledContent>
    </StyledModal>
  );
};

export default Modal;
