import { getTestSessionFromQuestion } from "hp-utils";
import { Question } from "../../data/questions";
import { sections } from "../../data/sections";
import { allTestSessions } from "../../data/tests";
import sum from "../../utils/sum";

const getTestSessionsWithQuestions = (questions: Question[]) =>
  allTestSessions.map((testSession) => {
    const questionsForSession = questions.filter(
      (q) => getTestSessionFromQuestion(q) === testSession
    );
    return {
      testSession,
      questions: questionsForSession,
    };
  });

const getSectionStatistics = (questions: Question[]) => {
  const testSessionsWithQuestions = getTestSessionsWithQuestions(questions);

  const testSessionsWithSectionCounters = testSessionsWithQuestions.map(
    ({ testSession, questions }) => {
      const sections: Record<string, number> = {};
      questions.forEach((q) => {
        q.sections?.forEach((section) => {
          if (sections[section]) {
            sections[section] += 1;
          } else {
            sections[section] = 1;
          }
        });
      });
      return {
        testSession,
        sections,
      };
    }
  );

  return sections.map((section) => {
    const countsForSection = testSessionsWithSectionCounters.map(
      ({ sections }) => {
        return sections[section.name] || 0;
      }
    );
    return {
      name: section.name,
      countsForSection,
      sum: sum(countsForSection),
      min: Math.min(...countsForSection),
      max: Math.max(...countsForSection),
      average: sum(countsForSection) / countsForSection.length,
    };
  });
};

export const getPointsForSectionList = (
  questions: Question[],
  sectionStrings: string[]
) => {
  const testSessionsWithQuestions = getTestSessionsWithQuestions(questions);
  const pointList = testSessionsWithQuestions.map((session) => {
    const sessionQuestions = session.questions;
    return sum(
      sessionQuestions.map((q) => {
        const covered = q.sections?.every((sectionString) =>
          sectionStrings.includes(sectionString)
        );
        return covered ? 1 : 0;
      })
    );
  });
  return {
    average: sum(pointList) / testSessionsWithQuestions.length,
    max: Math.max(...pointList),
    min: Math.min(...pointList),
  };
};

export default getSectionStatistics;
