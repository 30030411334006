import { TrainingType } from "../providers/MathTrainingProvider";

const getSubjectSign = (subject: TrainingType) => {
  switch (subject) {
    case "addition":
      return "+";
    case "subtraktion":
      return "-";
    case "multiplikation":
      return "×";
    case "division":
      return "/";
  }
};

export default getSubjectSign;
