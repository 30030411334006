import { DefaultTheme } from "styled-components";
import {
  TrainingAnswer,
  TrainingArgument,
  TrainingType,
} from "../../../providers/MathTrainingProvider";
import getTrainingCompletedLevel from "../../../utils/getTrainingCompletedLevel";

type Props = {
  arg1: TrainingArgument;
  arg2: TrainingArgument;
  subject: TrainingType;
  history: TrainingAnswer[];
  theme: DefaultTheme;
};

const getColorForChartSquare = ({
  arg1,
  arg2,
  history,
  subject,
  theme,
}: Props) => {
  if (subject === "division" && String((arg1 / arg2) % 1).length > 4) {
    return theme.colors.gray12;
  }
  const completedLevel = getTrainingCompletedLevel({
    arg1,
    arg2,
    history,
    subject,
  });
  switch (completedLevel) {
    case -1:
      return theme.colors.pink1;
    case 0:
      return theme.colors.gray6;
    case 1:
      return theme.colors.positive0;
    case 2:
      return theme.colors.positive1;
    default:
      return theme.colors.positive2;
  }
};

export default getColorForChartSquare;
