import Router from "./routes";
import AnswersProvider from "./providers/AnswersProvider";
import CurrentQuestionProvider from "./providers/CurrentQuestionProvider";
import ErrorBoundary from "./components/ErrorBoundary";
import { QueryClient, QueryClientProvider } from "react-query";
import SettingsProvider from "./providers/SettingsProvider";
import { printAll } from "./data/areas";
import ThemeProvider from "./providers/ThemeProvider";
import AdminProvider from "./providers/AdminProvider";
import MathTrainingProvider from "./providers/MathTrainingProvider";
import QuestionsProvider from "./providers/QuestionsProvider";
import MetaDataProvider from "./providers/MetaDataProvider/MetaDataProvider";
import DtkObjectProvider from "./providers/DtkObjectProvider/DtkObjectProvider";
// import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient();

function App() {
  printAll();
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <DtkObjectProvider>
          <MetaDataProvider>
            <QuestionsProvider>
              <MathTrainingProvider>
                <AdminProvider>
                  <SettingsProvider>
                    <AnswersProvider>
                      <CurrentQuestionProvider>
                        <ThemeProvider>
                          <Router />
                        </ThemeProvider>
                      </CurrentQuestionProvider>
                    </AnswersProvider>
                    {/* <ReactQueryDevtools /> */}
                  </SettingsProvider>
                </AdminProvider>
              </MathTrainingProvider>
            </QuestionsProvider>
          </MetaDataProvider>
        </DtkObjectProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
