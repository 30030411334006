import {
  TrainingArgument,
  TrainingType,
} from "../providers/MathTrainingProvider";
import getSolutionForTrainingData from "./getSolutionForTrainingData";

type Props = {
  type: TrainingType;
  arg1: TrainingArgument;
  arg2: TrainingArgument;
  answer: number;
};

const validateTraingDataAnswer = ({ type, arg1, arg2, answer }: Props) => {
  return answer === getSolutionForTrainingData({ type, arg1, arg2 });
};

export default validateTraingDataAnswer;
