import { SideMenu } from "hp-fabric";
import { Link, useLocation } from "react-router-dom";

const MenuIcon = () => {
  const { pathname } = useLocation();
  return (
    <SideMenu
      activePath={pathname}
      linkComponent={Link}
      menuItems={[
        { to: "/filter", text: "Träna", iconName: "pen" },
        { to: "/questions", text: "Frågebibliotek", iconName: "clipboard" },
        { to: "/settings", text: "Inställningar", iconName: "cog" },
        { to: "/math-training", text: "Mattegymmet", iconName: "clock" },
        {
          to: "/sections",
          text: "Högskoleprovets matematik",
          iconName: "math",
        },
        {
          to: "/about",
          text: "Om HP Campus",
          iconName: "help",
          bottomPositioned: true,
        },
      ]}
      logoPath={require("./logo.png")}
    />
  );
};

export default MenuIcon;
