import {
  Checkbox,
  Radio,
  FormControl,
  FormControlLabel,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styled, { useTheme } from "styled-components";
import { BaseSyntheticEvent, FC, useState } from "react";

const SelectContainer = styled.div<{ isOpen: boolean }>`
  // TODO: Improve this css
  & * {
    cursor: pointer;
    outline: none !important;
    color: ${(props) => props.theme.colors.textPrimary} !important;
    border-color: ${(props) => props.theme.colors.textPrimary} !important;
    border-width: 1px !important;
  }
`;

export type SelectOption = {
  label: string;
  id: string;
  selected: boolean;
};

type Props = {
  label: string;
  displayedText: string;
  allOption: string;
  options: SelectOption[];
  onChange: (selected: string[]) => void;
  dropDownSize: {
    width: number;
    maxHeight: number;
  };
};

const MultiSelect: FC<Props> = ({
  label,
  displayedText,
  allOption,
  options,
  onChange,
  dropDownSize,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event: BaseSyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const selected = options
    .filter((option) => option.selected)
    .map((option) => option.id);

  return (
    <SelectContainer isOpen={isOpen}>
      <FormControl fullWidth>
        <InputLabel id={label}>Tidigare prov</InputLabel>
        <OutlinedInput
          readOnly
          fullWidth
          id={label}
          label={label}
          size="small"
          value={displayedText}
          margin="dense"
          endAdornment={
            !isOpen && (
              <ArrowDropDownIcon sx={{ color: theme.colors.textPrimary }} />
            )
          }
          onClick={handleClick}
        />
        {isOpen && (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              style: dropDownSize,
              sx: {
                backgroundColor: theme.colors.surface,
              },
            }}
          >
            <MenuItem>
              <FormControlLabel
                sx={{
                  color: `${theme.colors.textPrimary} !important`,
                }}
                control={
                  <Radio
                    sx={{
                      color: `${theme.colors.textPrimary} !important`,
                    }}
                    checked={selected.length === 0}
                    onClick={() => {
                      onChange([]);
                      handleClose();
                    }}
                  />
                }
                label={allOption}
              />
            </MenuItem>

            {options.map((option) => (
              <MenuItem key={option.id}>
                <FormControlLabel
                  sx={{
                    color: `${theme.colors.textPrimary} !important`,
                  }}
                  control={
                    <Checkbox
                      sx={{
                        color: `${theme.colors.textPrimary} !important`,
                      }}
                      checked={option.selected}
                      onClick={() => {
                        if (option.selected) {
                          onChange(selected.filter((id) => id !== option.id));
                        } else {
                          onChange([...selected, option.id]);
                        }
                      }}
                    />
                  }
                  label={option.label}
                />
              </MenuItem>
            ))}
          </Menu>
        )}
      </FormControl>
    </SelectContainer>
  );
};

export default MultiSelect;
