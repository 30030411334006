import { Section } from "../data/sections";
import addNewToSectionList from "./addNewToSectionList";

const getAllDependencies = (section: Section): Section[] => {
  let dependencies: Section[] = [];
  let parents = [section];
  while (parents.length !== 0) {
    let newParents = parents.map((parent) => parent.dependencies).flat();
    const newDependencies = addNewToSectionList(dependencies, newParents);
    dependencies = newDependencies;
    parents = newParents;
  }
  return dependencies;
};

export default getAllDependencies;
