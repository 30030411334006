import { FC } from "react";
import { Bar } from "@visx/shape";
import { useTheme } from "styled-components";
import { useBarChart } from "../BarChartProvider";

const Bars: FC = () => {
  const theme = useTheme();
  const { bars, xScale, yScale, height, marginTop, marginLeft } = useBarChart();
  return (
    <>
      {bars.map(({ label, value }, i) => {
        const barWidth = xScale.bandwidth();
        const barHeight = height - marginTop - yScale(value) ?? 0;
        const barX = marginLeft + (xScale(label) ?? 0);
        const barY = height - barHeight;
        return (
          <Bar
            key={`bar-${label}`}
            x={barX}
            y={barY}
            width={barWidth}
            height={barHeight}
            fill={theme.isDark ? theme.colors.gray6 : theme.colors.blue1}
          />
        );
      })}
    </>
  );
};

export default Bars;
