import { FC, createContext, useContext, ReactNode } from "react";
import { useQuery } from "react-query";
import { fetchAllQuestions } from "../../data/queries/fetchAllQuestions";
import { Question } from "../../data/questions";

type QuestionsContextType = {
  questions: Question[];
  loadingQuestions: boolean;
};

export const QuestionsContext = createContext<QuestionsContextType | null>(
  null
);

export const useQuestions = (): QuestionsContextType => {
  const context = useContext(QuestionsContext);
  if (!context) {
    throw new Error("useQuestions must be inside the QuestionsProvider");
  }
  return context;
};

type Props = {
  children: ReactNode;
};

const QuestionsProvider: FC<Props> = ({ children }) => {
  const { data: questions, isLoading } = useQuery(
    ["questions"],
    fetchAllQuestions
  );

  return (
    <QuestionsContext.Provider
      value={{
        questions: questions || [],
        loadingQuestions: isLoading,
      }}
    >
      {children}
    </QuestionsContext.Provider>
  );
};

export default QuestionsProvider;
