import { FC } from "react";
import styled from "styled-components";
import {
  TrainingType,
  useMathTraining,
} from "../../../providers/MathTrainingProvider";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import getFinishedLevelFromTrainingData from "../../../utils/getFinishedLevelFromTrainingData";
import DialUpChart from "../../../components/DialUpChart";
import { Card, Heading } from "hp-fabric";

type Props = {
  subject: TrainingType;
};

const StyledTrainingContent = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${(props) => props.theme.spaces.l}px;
  @media (min-width: 400px) {
    padding: 0 ${(props) => props.theme.spaces.xl}px;
  }
  @media (min-width: 600px) {
    padding: 0 ${(props) => props.theme.spaces.xxl}px;
  }
`;

const TrainingCard: FC<Props> = ({ subject }) => {
  const { getTrainingData } = useMathTraining();
  const trainingData = getTrainingData(subject);
  const finishedLevel = getFinishedLevelFromTrainingData({
    trainingData,
    subject,
  });

  return (
    <Card to={`/math-training/${subject}`}>
      <StyledTrainingContent>
        <div>
          <Heading size="s" level={2}>
            {capitalizeFirstLetter(subject)}
          </Heading>
        </div>
        <div style={{ maxWidth: 110 }}>
          <DialUpChart score={finishedLevel} />
        </div>
      </StyledTrainingContent>
    </Card>
  );
};

export default TrainingCard;
