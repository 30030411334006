import { FC, useCallback, useRef } from "react";
import QuestionCard from "./QuestionCard";
import styled, { useTheme } from "styled-components";
import { useVirtual } from "@tanstack/react-virtual";
import { Question } from "hp-types";

const InnerListContainer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  max-width: 600px;
  position: relative;
  margin: 0 auto;
`;

const ItemContainer = styled.div<{ height: number; startY: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => props.height}px;
  transform: translateY(${(props) => props.startY}px);
`;

type Props = {
  questions: Question[];
};

const QuestionList: FC<Props> = ({ questions }) => {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const isMobile = (theme.dimensions.width || 300) < 400;

  // The virtualizer
  const rowVirtualizer = useVirtual({
    size: questions.length,
    estimateSize: useCallback(
      () => (isMobile ? 274 + theme.spaces.m : 143),
      [isMobile, theme.spaces.m]
    ),
    parentRef,
    overscan: 10,
  });

  return (
    <div
      ref={parentRef}
      // TODO: See if we can make react-virtual work with styled-components
      style={{
        position: "relative",
        top: -theme.spaces.m,
        boxSizing: "border-box",
        height:
          (theme.dimensions.height || 600) -
          theme.spaces.xxxl -
          (isMobile ? theme.spaces.xxl : theme.spaces.xxxl),
        width: "100vw",
        maxWidth: "100vw",
        paddingLeft: theme.spaces.m,
        paddingRight: theme.spaces.m,
        paddingTop: theme.spaces.m,
        overflow: "auto",
      }}
    >
      <InnerListContainer height={rowVirtualizer.totalSize}>
        {rowVirtualizer.virtualItems.map((virtualItem) => (
          <ItemContainer
            height={virtualItem.size}
            startY={virtualItem.start}
            key={virtualItem.key}
          >
            <QuestionCard
              key={questions[virtualItem.index].id}
              question={questions[virtualItem.index]}
            />
          </ItemContainer>
        ))}
      </InnerListContainer>
    </div>
  );
  // </div>
};

export default QuestionList;
