import { doc, getDoc } from "firebase/firestore";
import { fireStore } from "../firebase";
// import prodBackup from "../prodBackup";
import { Question } from "../questions";

const makeQuestionsArray = (data: Record<string, Question>): Question[] => {
  return Object.values(data);
};

export const fetchAllQuestions = async (): Promise<Question[]> => {
  const reference = doc(fireStore, "questions", "meta");
  const metaData = await getDoc(reference).then((doc) => doc.data());

  const allTestSessions = (metaData as { testSessions: string[] }).testSessions;

  const requests = allTestSessions.map(async (testSession) => {
    const documentReference = doc(fireStore, "questions", testSession);
    const questionsDocument = await getDoc(documentReference);
    const data = questionsDocument.data();
    return makeQuestionsArray(data as unknown as Record<string, Question>);
  });
  return Promise.all(requests).then((questionArrays) => questionArrays.flat());
};
