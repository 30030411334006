import { doc, increment, updateDoc } from "firebase/firestore";
import { AnswerData } from "../../providers/AnswersProvider";
import { Question, Solution } from "hp-types";
import { fireStore } from "../firebase";
import { getTestSessionFromQuestion } from "hp-utils";

type Variables = {
  questions: Question[];
  answers: AnswerData[];
  oldAnswers: AnswerData[];
};

export type AnswerHistoryData = {
  answer: Solution;
  seconds: number;
  attemptNumber?: number;
  timeStamp?: number;
};

const generateAnswers = ({
  answers,
  oldAnswers,
}: Variables): (AnswerHistoryData & { questionId: string })[] => {
  return answers.map((answer) => {
    const earlierAnswers = oldAnswers.filter(
      (oldAnswer) => oldAnswer.questionId === answer.questionId
    );
    return {
      questionId: answer.questionId,
      answer: answer.answer,
      seconds: answer.seconds,
      attemptNumber: earlierAnswers.length,
    };
  });
};

export const appendAnswersV2 = async ({
  questions,
  answers,
  oldAnswers,
}: Variables) => {
  const historyAnswers = generateAnswers({ questions, answers, oldAnswers });
  historyAnswers.forEach(async (answer) => {
    const updateObject = {
      [`${answer.questionId}.answers.attempt.${answer.attemptNumber}.seconds.${answer.seconds}.${answer.answer}`]:
        increment(1),
    };
    const question = questions.find((q) => q.id === answer.questionId);
    if (!question) {
      console.log("question not found");
      return;
    }
    const testSession = getTestSessionFromQuestion(question);
    const documentReference = doc(fireStore, "questions", testSession);
    await updateDoc(documentReference, updateObject);
  });
};
