import { Switch } from "@mui/material";
import { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { css, useTheme } from "styled-components";
import MenuIcon from "../../components/MenuIcon";
import OutsideClickHandler from "react-outside-click-handler";
import {
  TrainingArgument,
  TrainingType,
  useMathTraining,
} from "../../providers/MathTrainingProvider";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import getAllLatestAnswersFromTrainingData from "../../utils/getAllLatestAnswersFromTrainingData";
import stringifyTime from "../../utils/stringifyTime";
import sum from "../../utils/sum";
import CardHeader, { QuestionData } from "./CardHeader";
import SubjectChart from "./SubjectChart";
import { Button, Card, Heading, Page, Text } from "hp-fabric";

type Props = {};

const MINUTE_OPTIONS = [2, 3, 4, 5];
const QUESTIONS_OPTIONS = [10, 20, 30, 40];

const SubjectTraining: FC<Props> = () => {
  const { getTrainingData, settings, setSettings, resetResults } =
    useMathTraining();
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionData>();

  const options =
    settings.type === "minutes" ? MINUTE_OPTIONS : QUESTIONS_OPTIONS;
  const { subject } = useParams() as { subject: TrainingType };
  const theme = useTheme();
  const trainingData = getTrainingData(subject);
  const handleSelectQuestion = (
    arg1: TrainingArgument,
    arg2: TrainingArgument
  ) => {
    setSelectedQuestion({
      arg1,
      arg2,
      history: trainingData[arg1][arg2],
    });
  };
  const allAnswers = getAllLatestAnswersFromTrainingData(trainingData);
  const averageTimeInSeconds =
    allAnswers.length &&
    sum(allAnswers.map((answer) => answer.seconds)) / allAnswers.length;
  return (
    <Page
      header={{
        leftComponent: <MenuIcon />,
        centerComponent: (
          <Heading level={1} size="s">
            {capitalizeFirstLetter(subject)}
          </Heading>
        ),
      }}
    >
      <Card>
        <CardHeader selectedQuestion={selectedQuestion} />
        <OutsideClickHandler
          onOutsideClick={() => {
            setSelectedQuestion(undefined);
          }}
        >
          <SubjectChart
            subject={subject}
            trainingData={trainingData}
            handleSelectQuestion={handleSelectQuestion}
          />
        </OutsideClickHandler>
        {!!allAnswers.length && (
          <Text>Genonsnittlig tid: {stringifyTime(averageTimeInSeconds)}</Text>
        )}
        <div
          style={{
            padding: "12px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "fit-content",
              marginLeft: "auto",
              marginRight: "auto",
              gap: 12,
            }}
          >
            {options.map((option) => (
              <Button
                kind="round"
                selected={settings.amount === option}
                color={theme.colors.gray6}
                key={option}
                onClick={() =>
                  setSettings({
                    ...settings,
                    amount: option,
                  })
                }
              >
                {option}
              </Button>
            ))}
          </div>
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "fit-content",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "auto",
                marginRight: "auto",
                width: "fit-content",
                padding: "12px 0",
              }}
            >
              <Text
                customCss={css`
                  color: ${settings.type === "questions"
                    ? theme.colors.textPrimary
                    : theme.colors.borderGray};
                `}
              >
                Frågor
              </Text>
              <div style={{ margin: "0 -5px" }}>
                <Switch
                  color="default"
                  checked={settings.type === "minutes"}
                  onChange={() =>
                    setSettings({
                      amount:
                        settings.type === "minutes"
                          ? QUESTIONS_OPTIONS[0]
                          : MINUTE_OPTIONS[0],
                      type:
                        settings.type === "minutes" ? "questions" : "minutes",
                    })
                  }
                />
              </div>
              <Text
                customCss={css`
                  color: ${settings.type === "minutes"
                    ? theme.colors.textPrimary
                    : theme.colors.borderGray};
                `}
              >
                Minuter
              </Text>
            </div>
            <div
              style={{
                width: "fit-content",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Button
                kind="secondary"
                onClick={() => resetResults()}
                link={{
                  to: `/math-training/${subject}/testing`,
                  component: Link,
                }}
              >
                Träna
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </Page>
  );
};

export default SubjectTraining;
