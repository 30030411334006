// import { BaseSyntheticEvent } from "react";
// import { Canvas, Node } from "reaflow";
// import { makeDataForTree } from "../../data/sections";

const Areas = () => {
  // const { nodes, edges } = makeDataForTree();
  return (
    <div style={{ border: "1px solid black", width: "fit-content" }}>
      {/* <Canvas
        direction="DOWN"
        pannable={true}
        nodes={nodes}
        edges={edges}
        node={
          <Node
            onClick={(_: BaseSyntheticEvent, edge: any) => console.log(edge)}
          />
        }
        fit={true}
      /> */}
    </div>
  );
};

export default Areas;
