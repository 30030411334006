import MenuIcon from "../../components/MenuIcon";
import { useQuestions } from "../../providers/QuestionsProvider";
import { StartPage } from "hp-fabric";
import { Link } from "react-router-dom";
import { useAnswers } from "../../providers/AnswersProvider";

const Startpage = () => {
  const { answers } = useAnswers();
  const { questions, loadingQuestions } = useQuestions();

  return (
    <StartPage
      answers={answers}
      questions={questions}
      loading={loadingQuestions}
      linkComponent={Link}
      menuIcon={<MenuIcon />}
    />
  );
};

export default Startpage;
