import {
  TrainingAnswer,
  TrainingArgument,
  TrainingData,
} from "../providers/MathTrainingProvider";

const getAllLatestAnswersFromTrainingData = (trainingData: TrainingData) => {
  const argumentList: TrainingArgument[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  let answers: TrainingAnswer[] = [];
  argumentList.forEach((arg1) => {
    argumentList.forEach((arg2) => {
      const history = trainingData[arg1][arg2];
      if (history.length) {
        answers = [...answers, history[history.length - 1]];
      }
    });
  });
  return answers;
};

export default getAllLatestAnswersFromTrainingData;
