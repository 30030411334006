import { Divider, useMediaQuery } from "@mui/material";
import { FC } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useCurrentQuestion } from "../../providers/CurrentQuestionProvider";
import splitQuestionsOnSegment from "../../utils/splitQuestionsOnSegment";
import { SegmentChart } from "./SegmentChart";
import TimeChart from "./TimeChart";
import CurrentTestHistoryPieChart from "../../components/CurrentTestHistoryChart";
import Questions from "./Questions";
import styled from "styled-components";
import MenuIcon from "../../components/MenuIcon";
import { useQuestions } from "../../providers/QuestionsProvider";
import { Card, Heading, Page } from "hp-fabric";

const StyledAccessTimeIcon = styled(AccessTimeIcon)`
  color: ${(props) =>
    props.theme.isDark ? props.theme.colors.gray5 : props.theme.colors.gray7};
`;

const SegmentChartsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
`;

const BigChartContainer = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  max-width: 180px;
  @media (min-width: 400px) {
    max-width: 350px;
  }
`;

const Result: FC = () => {
  const { currentQuestions } = useCurrentQuestion();
  const { loadingQuestions } = useQuestions();
  const mediumScreen = useMediaQuery("(min-width:400px)");
  const { xyz, kva, nog, dtk } = splitQuestionsOnSegment(currentQuestions);
  const numOfSegments = [
    xyz.length ? 1 : 0,
    kva.length ? 1 : 0,
    nog.length ? 1 : 0,
  ].reduce((partialSum, a) => partialSum + a, 0);

  return (
    <Page
      header={{
        leftComponent: <MenuIcon />,
        centerComponent: (
          <Heading level={1} size="s">
            Resultat
          </Heading>
        ),
      }}
    >
      <Card>
        <BigChartContainer>
          <CurrentTestHistoryPieChart
            legends
            loading={loadingQuestions}
            direction={mediumScreen ? "row" : "column"}
            result={currentQuestions}
          />
        </BigChartContainer>
        {numOfSegments > 1 && (
          <>
            <Divider sx={{ mt: 2, mb: 1 }} />
            <SegmentChartsContainer>
              <SegmentChart result={xyz} name="XYZ" />
              <SegmentChart result={kva} name="KVA" />
              <SegmentChart result={nog} name="NOG" />
              <SegmentChart result={dtk} name="DTK" />
            </SegmentChartsContainer>
          </>
        )}
      </Card>
      <Card>
        <StyledAccessTimeIcon fontSize="large" />
        <TimeChart result={currentQuestions} />
      </Card>
      <Card>
        <Questions />
      </Card>
    </Page>
  );
};

export default Result;
