import { FC } from "react";
import { Resource } from "../../data/questions";
import getDisplayNameForResource from "../../utils/getDisplayNameForResource";

type Props = {
  width: number;
  height: number;
  resource: Resource;
};

const VideoFrame: FC<Props> = ({ resource, width, height }) => {
  const stubRegex = /https:\/\/youtu.be\/|https:\/\/www.youtube.com\/live\//;
  const stub = resource.url.replace(stubRegex, "");
  const paramRegex = /\?.*t=/;
  const [id, time] = stub.split(paramRegex);

  const displayName = getDisplayNameForResource(resource);

  return (
    <iframe
      width={`${width}`}
      height={`${height}`}
      src={`https://www.youtube.com/embed/${id}${time ? `?start=${time}` : ""}`}
      title={displayName}
      frameBorder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
};

export default VideoFrame;
