import { useEffect } from "react";
import { useAnswers } from "../../../providers/AnswersProvider";
import { useCurrentQuestion } from "../../../providers/CurrentQuestionProvider";
import getLatestAnswerForQuestion from "../../../utils/getLatestAnswerForQuestion";
import splitQuestionsOnHistory from "../../../utils/splitQuestionsOnHistory";
import useCurrentTimeStamp from "../utils/useCurrentTimeStamp";
import QuestionAccordion from "./QuestionAccordion";
import styled, { useTheme } from "styled-components";
import QuestionCarousel from "./QuestionAccordion/QuestionCarousel";
import { Text } from "hp-fabric";

const StyledQuestionsContainer = styled.div`
  margin: 0 -${(props) => props.theme.spaces.m}px;
  @media (min-width: 400px) {
    margin-top: -${(props) => props.theme.spaces.m}px;
  }
`;

const Questions = () => {
  const { currentQuestions } = useCurrentQuestion();
  useEffect(() => {
    currentQuestions.forEach((question) => {
      const img = new Image();
      img.src = question.image;
    });
  }, [currentQuestions]);
  const currentTimeStamp = useCurrentTimeStamp();
  const theme = useTheme();
  const { answers } = useAnswers();
  const { incorrect, tooSlow, correct, unanswered } = splitQuestionsOnHistory(
    answers,
    currentQuestions.filter(({ id }) => {
      const answer = getLatestAnswerForQuestion(answers, id);
      return answer?.timeStamp === currentTimeStamp;
    })
  );

  const resultList = [
    {
      color: theme.colors.incorrect,
      name: "Felsvarade",
      result: incorrect,
    },
    {
      color: theme.colors.tooSlow,
      name: "För långasamma",
      result: tooSlow,
    },
    {
      color: theme.colors.correct,
      name: "Rättsvarade",
      result: correct,
    },
    {
      color: theme.colors.unanswered,
      name: "Obesvarade",
      result: unanswered,
    },
  ].filter((resultType) => resultType.result.length);
  return (
    <StyledQuestionsContainer>
      {resultList.length > 1 ? (
        resultList.map((resultType) => (
          <QuestionAccordion
            color={resultType.color}
            name={resultType.name}
            result={resultType.result}
          />
        ))
      ) : (
        <div style={{ margin: theme.spaces.m }}>
          <Text align="center">{resultList[0].name}</Text>
          <QuestionCarousel result={resultList[0].result} />
        </div>
      )}
    </StyledQuestionsContainer>
  );
};

export default Questions;
