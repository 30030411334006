import { FC } from "react";
import { useTheme } from "styled-components";
import {
  TrainingArgument,
  TrainingData,
  TrainingType,
} from "../../../providers/MathTrainingProvider";
import ChartSquare from "./ChartSquare";

const WIDTH = 1000;
const HEIGHT = 1000;

type Props = {
  trainingData: TrainingData;
  subject: TrainingType;
  handleSelectQuestion: (
    arg1: TrainingArgument,
    arg2: TrainingArgument
  ) => void;
};

const SubjectChart: FC<Props> = ({
  trainingData,
  subject,
  handleSelectQuestion,
}) => {
  const theme = useTheme();
  const argumentList: TrainingArgument[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <svg width="100%" viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
      {argumentList.map((argument) => {
        return (
          <text
            key={argument}
            fontSize={40}
            textAnchor="middle"
            x={50}
            y={100 * argument + 60}
            fill={theme.colors.textPrimary}
          >
            {argument}
          </text>
        );
      })}
      {argumentList.map((argument) => {
        return (
          <text
            key={argument}
            fontSize={40}
            textAnchor="middle"
            x={100 * argument + 40}
            y={60}
            fill={theme.colors.textPrimary}
          >
            {argument}
          </text>
        );
      })}
      {argumentList.map((arg1) => {
        return argumentList.map((arg2) => {
          return (
            <ChartSquare
              key={`${arg1}${arg2}`}
              arg1={arg1}
              arg2={arg2}
              history={trainingData[arg1][arg2]}
              subject={subject}
              handleSelectQuestion={handleSelectQuestion}
            />
          );
        });
      })}
    </svg>
  );
};

export default SubjectChart;
