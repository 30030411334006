import { FC, createContext, useContext, useState, ReactNode } from "react";
import useLocalStorage from "../../utils/useLocalStorage";
import md5 from "md5";

const HASH = "20c15be7ca26ab638e306330694dd41d";

type AdminContextType = {
  isAdmin: boolean;
  setPassword: (passWord: string) => void;
};

export const AdminContext = createContext<AdminContextType | null>(null);

export const useAdmin = (): AdminContextType => {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error("useAdmin must be inside the AdminProvider");
  }
  return context;
};

type Props = {
  children: ReactNode;
};

const AdminProvider: FC<Props> = ({ children }) => {
  const [password, setPassword] = useLocalStorage("ADMIN", "");
  const [tries, setTries] = useState(0);

  return (
    <AdminContext.Provider
      value={{
        isAdmin: md5(password) === HASH,
        setPassword: (password: string) => {
          setTries(tries + 1);
          setPassword(tries < 5 ? password : "");
        },
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
