import { TestData } from "hp-types";

const checkTestsFilter = <Type extends TestData>(
  testsFilter: Type[],
  question: Type
) => {
  return (
    testsFilter.some(
      (testData) =>
        question.date === testData.date &&
        question.partNumber === testData.partNumber
    ) || testsFilter.length === 0
  );
};

export default checkTestsFilter;
