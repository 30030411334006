import { CurrentQuestion, Question } from "hp-types";
import { Segment } from "hp-types";

const makeAltText = (question?: Question | CurrentQuestion) => {
  if (!question) {
    return "";
  }
  if (!question.text?.length) {
    return question.id;
  }
  switch (question.segment) {
    case Segment.XYZ:
      return question.text;
    case Segment.NOG:
      return `${question.text}\n
Tillräcklig information för lösningen erhålls
A — i (1) men ej i (2)
B — i (2) men ej i (1)
C — i (1) tillsammans med (2)
D — i (1) och (2) var för sig
E - ej genom de båda påståendena`;
    case Segment.KVA:
      return `${question.text}\n
A I är större än lI
B II är större än I
C I är lika med ll
D informationen är otillräcklig
`;
  }
};

export default makeAltText;
