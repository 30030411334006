import {
  ResultObject,
  TrainingArgument,
  TrainingData,
  TrainingType,
} from "../providers/MathTrainingProvider";
import getTrainingCompletedLevel from "./getTrainingCompletedLevel";

type Props = {
  subject: TrainingType;
  trainingData: TrainingData;
  completedLevel: -1 | 0 | 1 | 2 | 3 | 4 | 5;
};

const getAllQuestionsWithCompletedLevel = ({
  subject,
  trainingData,
  completedLevel,
}: Props) => {
  const argumentList: TrainingArgument[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  let questions: ResultObject[] = [];
  argumentList.forEach((arg1) => {
    argumentList.forEach((arg2) => {
      const history = trainingData[arg1][arg2];
      if (
        getTrainingCompletedLevel({
          arg1,
          arg2,
          subject,
          history,
        }) === completedLevel
      ) {
        questions = [
          ...questions,
          { arg1, arg2, ...history[history.length - 1] },
        ];
      }
    });
  });
  return questions;
};

export default getAllQuestionsWithCompletedLevel;
