import { Heading, Page } from "hp-fabric";
import { FC } from "react";
import MenuIcon from "../../components/MenuIcon";
import { subjects } from "../../providers/MathTrainingProvider";
import TrainingCard from "./TrainingCard";

type Props = {};

const MathTraining: FC<Props> = () => {
  return (
    <Page
      header={{
        leftComponent: <MenuIcon />,
        centerComponent: (
          <Heading level={1} size="s">
            Mattegymmet
          </Heading>
        ),
      }}
    >
      {subjects.map((subject) => (
        <TrainingCard key={subject} subject={subject} />
      ))}
    </Page>
  );
};

export default MathTraining;
