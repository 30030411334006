import { Stack } from "@mui/material";
import { FC } from "react";
import pSBC from "../../../utils/psBC";
import segments from "../../../data/segments";
import { useCurrentQuestion } from "../../../providers/CurrentQuestionProvider";
import { CurrentQuestion } from "hp-types";
import getQuestionFromId from "../../../utils/getQuestionFromId";
import stringifyTime from "../../../utils/stringifyTime";
import { useTheme } from "styled-components";
import { useQuestions } from "../../../providers/QuestionsProvider";
import { Text } from "hp-fabric";

const getWidthPercentages = (
  total: number,
  recommended: number
): { total: number; recommended: number } => {
  if (recommended > total) {
    return { total: (total / recommended) * 100, recommended: 100 };
  } else {
    return { total: 100, recommended: (recommended / total) * 100 };
  }
};

type Props = {
  result: CurrentQuestion[];
};

const TimeChart: FC<Props> = () => {
  const theme = useTheme();
  const { currentQuestions } = useCurrentQuestion();
  const { questions, loadingQuestions } = useQuestions();
  const totalTime = currentQuestions.reduce(
    (partialSum, question) => partialSum + question.seconds,
    0
  );
  const recommendedTime = currentQuestions.reduce(
    (partialSum, currentQuestion) => {
      if (!questions) {
        return 0;
      }
      const question = getQuestionFromId(questions, currentQuestion.id);
      return (
        partialSum +
        (question ? segments[question.segment].secondsPerQuestion : 0)
      );
    },
    0
  );

  const { total, recommended } = getWidthPercentages(
    totalTime,
    recommendedTime
  );

  return (
    <Stack spacing={1} sx={{ mt: 1 }}>
      <div>
        <Text size="s">Din tid</Text>
        <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
          <Text size="s">{stringifyTime(totalTime)}</Text>
          <div style={{ flexGrow: 1 }}>
            <div
              style={{
                width: `${loadingQuestions ? 50 : total}%`,
                height: "15px",
                backgroundColor: loadingQuestions
                  ? (pSBC(0.35, theme.colors.unanswered) as string)
                  : recommendedTime > totalTime
                  ? theme.colors.correct
                  : theme.colors.incorrect,
              }}
            />
          </div>
        </Stack>
      </div>
      <div>
        <Text size="s">Rekommenderad tid</Text>
        <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
          <Text size="s">{stringifyTime(recommendedTime)}</Text>
          <div style={{ flexGrow: 1 }}>
            <div
              style={{
                width: `${loadingQuestions ? 50 : recommended}%`,
                height: "15px",
                backgroundColor: loadingQuestions
                  ? (pSBC(0.35, theme.colors.unanswered) as string)
                  : theme.colors.tooSlow,
              }}
            />
          </div>
        </Stack>
      </div>
      {questions && currentQuestions.length ? (
        <div>
          <Text size="s">Genomsnittlig tid per uppgift</Text>
          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
            <Text size="s">
              {stringifyTime(Math.round(totalTime / currentQuestions.length))}
            </Text>
          </Stack>
        </div>
      ) : (
        <div
          style={{
            minHeight: 50,
          }}
        ></div>
      )}
    </Stack>
  );
};

export default TimeChart;
