import { FC, createContext, useContext, ReactNode } from "react";
import { useQuery } from "react-query";
import fetchMetaData from "../../data/queries/fetchMetaData";
import { MetaData } from "hp-types";

type MetaDataContex = MetaData & { loadingMetaData: boolean };

const defaultMetaData: MetaData = {
  testSessions: [],
  youtubeCreators: [],
  graphs: {
    defaultHeight: 0,
    subDomains: {},
    subdomainGraph: {
      nodes: [],
    },
  },
  sections: {},
  subDomains: {},
  methods: [],
  recurringQuestionTypes: [],
  mathGraph: {
    nodes: [],
    width: 0,
  },
  dtkGraph: {
    nodes: [],
    width: 0,
  },
  optimalPath: [],
  testData: [],
  resources: {},
};

export const MetaDataContext = createContext<MetaDataContex | null>(null);

export const useMetaData = (): MetaDataContex => {
  const context = useContext(MetaDataContext);
  if (!context) {
    throw new Error("useMetaData must be inside the MetaDataProvider");
  }
  return context;
};

type Props = {
  children: ReactNode;
};

const MetaDataProvider: FC<Props> = ({ children }) => {
  const { data: metaData, isLoading: loadingMetaData } = useQuery(
    ["meta-data"],
    () => fetchMetaData()
  );

  const testSessions =
    metaData?.testSessions.sort((a, b) => {
      const dateA = a.slice(0, 10);
      const dateB = b.slice(0, 10);
      const partNumberA = Number(a.slice(11));
      const partNumberB = Number(b.slice(11));
      return dateA.localeCompare(dateB) || partNumberA - partNumberB;
    }) || [];

  return (
    <MetaDataContext.Provider
      value={{
        ...(metaData ? metaData : defaultMetaData),
        testSessions,
        loadingMetaData,
      }}
    >
      {children}
    </MetaDataContext.Provider>
  );
};

export default MetaDataProvider;
