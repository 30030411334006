import { Line } from "@visx/shape";
import { AxisBottom } from "@visx/axis";
import { useBarChart } from "../BarChartProvider";
import { useTheme } from "styled-components";

type Props = {};

const BottomAxis = (props: Props) => {
  const theme = useTheme();
  const { bars, marginLeft, height, xScale } = useBarChart();

  return (
    <AxisBottom
      numTicks={bars.length}
      left={marginLeft}
      top={height}
      scale={xScale}
    >
      {(props) => {
        const tickLabelSize = 10;
        const tickRotate = 30;
        const tickColor = theme.colors.textPrimary;
        return (
          <g>
            <Line
              from={props.axisFromPoint}
              to={props.axisToPoint}
              stroke={tickColor}
            />
            {props.ticks.map((tick, i) => {
              const tickLabelX = tick.to.x - 5;
              const tickLabelY =
                tick.to.y + tickLabelSize + (props.tickLength || 0) - 5;
              return (
                <g key={`vx-tick-${tick.value}-${i}`}>
                  <Line from={tick.from} to={tick.to} stroke={tickColor} />
                  <text
                    transform={`translate(${tickLabelX}, ${tickLabelY}) rotate(${tickRotate})`}
                    fontSize={tickLabelSize}
                    textAnchor="start"
                    fill={tickColor}
                  >
                    {tick.formattedValue}
                  </text>
                </g>
              );
            })}
          </g>
        );
      }}
    </AxisBottom>
  );
};

export default BottomAxis;
