export const hpGuiden = {
  grunder: [
    "räkneordning",
    "grundläggande matematik",
    "aritmetik", // eget område?
    "addition",
    "subtraktion",
    "multiplikation",
    "division", // bråk?
    "negativa tal",
    "primtal",
    "primtalsfaktorisering",
    "algebra",
  ],
  bråk: [
    "mgn", // viktig
    "addition av bråk",
    "subtraktion av bråk",
    "multiplikation av bråk",
    "division av bråk",
    "jämföra bråk", // olikheter?
    "viktiga bråk, procent, decimaltal",
  ],
  decimaltal: [
    "subtraktion av decimaler",
    "multiplikation av decimaler",
    "division av decimaler",
  ],
  procent: [
    "tre basproblem procent",
    "procentuella förändringar",
    "procent och procentenheter",
  ],
  avrundning: ["addition", "multiplikatoin", "division", "allmänna"],
  koordinatsystemOchRätaLinjer: [
    "mittpunktsformeln",
    "räta linjens ekvation",
    "vinkelräta linjer",
  ],
  ekvationer: [
    // algebra ?
    "ekvationer med variabler",
    "andragradsekvationer",
    "ekvationssystem",
    "additionsmetoden",
    "substitutionsmetoden",
    "ekvationssystemens lösbarhet",
    "översätta text till ekvationer",
    "polynommultiplikation", // what?
  ],
  funktioner: ["andragradsfunktioner"],
  olikheter: [],
  geometri: [
    "grundläggande geometriska begrepp",
    "genometriska formler",
    "vinklar",
    "viktiga enhetsomvandlingar",
  ],
  trianglar: [
    "olika typer av trianglar",
    "yttervinkelsatsen",
    "pythagoras sats",
  ],
  statistiskamått: [
    "medelvärde",
    "median",
    "lägesmått för talföljder med på varandra följande tal",
  ],
  potenser: [
    "grundpotensform",
    "viktiga potenser",
    "potenslagarna",
    "potens vid multiplikation",
    "potens vid division",
    "potens av potens",
    "samband mellan potens och rötter",
    "räkna med kvadratrötter",
    "viktiga rötter",
    "exponentialekvationer",
  ],
  rörelse: ["medelhastighet"],
  ordningarOchOrdningsföljder: [],
  mängder: ["tabellerförmängder"],
  sannolikhet: [],
  XYZstrategier: [
    "skriv om uppgiften till ekvationsform",
    "använd svarsalternativen",
  ],
  KVAstrategier: [
    "att jämföra bråk KVA",
    "tänk på decimaltal",
    "att förenkla uttryck",
    "stryk autamtiskt D om",
    "resonera istället för att beräkna",
  ],
  NOGstrategier: [
    "organisera informationen",
    "illustrera problemet",
    "räkna inte ut uppgiften",
  ],
};

export const allaRätt = {
  algebra: [
    "andragradsekvationer",
    "ekvationer",
    "ekvationer i bråkformat",
    "olikheter",
  ],
  aritmetik: [
    "bråk och decimaler",
    "potensregler",
    "räkneregler",
    "procent och promille", // egen
    "rötter", // egen
    "talföljder", // egen
  ],
  funktionslära: [
    "funktioner",
    "potens & exponentialfunktioner",
    "räta linjens ekvation",
    // saknar koordinatsystem
  ],
  geometri: [
    "cirkeln",
    "enheter och prefix",
    "förhörningar",
    "likformighet",
    "omkrets, area och volym",
    "trianglar",
    "vinklar och parallella linjer",
  ],
  statistik: [
    "medelvärde, median och typvärde",
    "sannolikhet", // egen
  ],
};

export const egna = [
  "bråk",
  "ekvationssystem",
  "vst",
  "rita figur",
  "ekvationssystem",
  "pythagoras sats",
  "cirkelns ekvation",
  "olikheter",
  "fyrfältare",
  "estimat av antal",
  "sortering",
  "jämna och ojämna tal",
  "medelvärde",
  "förenkla",
  "ekvationer",
  "kvadrater",
  "negativa tal",
  "rektanglar",
  "trianglar",
  "funktioner",
  "procent",
  "omkrets",
  "radie",
  "sidlängd",
  "kvadratens ekvation",
  "potenslagar",
  "vinklar",
  "fyrfältare",
  "procent",
  "roten ur",
  "linjens ekvation",
  "triangelns ekvation",
  "godtyckliga figurer",
  "tabell",
  "kvadrater",
  "median",
  "multipler",
  "prioriteringsregler",
  "koordinatsystem",
  "faktorisering",
  "cylinder",
  "volym",
  "kvadreringsreglerna",
  "konjugatregeln",
  "jämn delbarhet",
  "division",
  "rektangel",
  "triangel",
  "räta linjens ekvation",
  "koordinatsystem",
  "konstanter",
  "kvadrat",
  "algebra",
  "aritmetik",
  "enhetsomvandling",
  "stapla klossar",
  "plankmålning",
];

const aristofanes = [
  "kvadratrotsberäkning",
  "pythagoras",
  "hastighetsuppgift med omvandling",
  "aritmetik",
  "minsta gemensamma nämnaren",
  "algebra",
  "geometri",
  "area",
  "potensräkning",
  "koordinatsystem",
  "rabattkuponger",
  "sannolikhetsräkning",
  "cirkelgeometri",
  "talteori",
  "vinklar",
  "funktioner",
];

export const printAll = () => {
  let list: string[] = [];
  list = list.concat(Object.keys(hpGuiden));
  list = list.concat(Object.values(hpGuiden).flat());
  list = list.concat(Object.keys(allaRätt));
  list = list.concat(Object.values(allaRätt).flat());
  list = list.concat(Array.from(new Set(egna)));
  list = list.concat(Array.from(new Set(aristofanes)));
  list = list.sort();
  return list;
  // console.log(Array.from(new Set(list)));
};

export const resultat = [
  "KVAstrategier",
  "NOGstrategier",
  "XYZstrategier",
  "addition",
  "addition",
  "addition av bråk",
  "additionsmetoden",
  "algebra",
  "algebra",
  "algebra",
  "algebra",
  "allmänna",
  "andragradsekvationer",
  "andragradsekvationer",
  "andragradsfunktioner",
  "använd svarsalternativen",
  "area",
  "aritmetik",
  "aritmetik",
  "aritmetik",
  "aritmetik",
  "att förenkla uttryck",
  "att jämföra bråk KVA",
  "avrundning",
  "bråk",
  "bråk",
  "bråk och decimaler",
  "cirkelgeometri",
  "cirkeln",
  "cirkelns ekvation",
  "cylinder",
  "decimaltal",
  "division",
  "division",
  "division",
  "division av bråk",
  "division av decimaler",
  "ekvationer",
  "ekvationer",
  "ekvationer",
  "ekvationer i bråkformat",
  "ekvationer med variabler",
  "ekvationssystem",
  "ekvationssystem",
  "ekvationssystemens lösbarhet",
  "enheter och prefix",
  "enhetsomvandling",
  "estimat av antal",
  "exponentialekvationer",
  "faktorisering",
  "funktioner",
  "funktioner",
  "funktioner",
  "funktioner",
  "funktionslära",
  "fyrfältare",
  "förenkla",
  "förhörningar",
  "genometriska formler",
  "geometri",
  "geometri",
  "geometri",
  "godtyckliga figurer",
  "grunder",
  "grundläggande geometriska begrepp",
  "grundläggande matematik",
  "grundpotensform",
  "hastighetsuppgift med omvandling",
  "illustrera problemet",
  "jämföra bråk",
  "jämn delbarhet",
  "jämna och ojämna tal",
  "konjugatregeln",
  "konstanter",
  "koordinatsystem",
  "koordinatsystem",
  "koordinatsystemOchRätaLinjer",
  "kvadrat",
  "kvadratens ekvation",
  "kvadrater",
  "kvadratrotsberäkning",
  "kvadreringsreglerna",
  "likformighet",
  "linjens ekvation",
  "lägesmått för talföljder med på varandra följande tal",
  "medelhastighet",
  "medelvärde",
  "medelvärde",
  "medelvärde, median och typvärde",
  "median",
  "median",
  "mgn",
  "minsta gemensamma nämnaren",
  "mittpunktsformeln",
  "multipler",
  "multiplikation",
  "multiplikation av bråk",
  "multiplikation av decimaler",
  "multiplikatoin",
  "mängder",
  "negativa tal",
  "negativa tal",
  "olika typer av trianglar",
  "olikheter",
  "olikheter",
  "olikheter",
  "omkrets",
  "omkrets, area och volym",
  "ordningarOchOrdningsföljder",
  "organisera informationen",
  "plankmålning",
  "polynommultiplikation",
  "potens & exponentialfunktioner",
  "potens av potens",
  "potens vid division",
  "potens vid multiplikation",
  "potenser",
  "potenslagar",
  "potenslagarna",
  "potensregler",
  "potensräkning",
  "primtal",
  "primtalsfaktorisering",
  "prioriteringsregler",
  "procent",
  "procent",
  "procent och procentenheter",
  "procent och promille",
  "procentuella förändringar",
  "pythagoras",
  "pythagoras sats",
  "pythagoras sats",
  "rabattkuponger",
  "radie",
  "rektangel",
  "rektanglar",
  "resonera istället för att beräkna",
  "rita figur",
  "roten ur",
  "räkna inte ut uppgiften",
  "räkna med kvadratrötter",
  "räkneordning",
  "räkneregler",
  "räta linjens ekvation",
  "räta linjens ekvation",
  "räta linjens ekvation",
  "rörelse",
  "rötter",
  "samband mellan potens och rötter",
  "sannolikhet",
  "sannolikhet",
  "sannolikhetsräkning",
  "sidlängd",
  "skriv om uppgiften till ekvationsform",
  "sortering",
  "stapla klossar",
  "statistik",
  "statistiskamått",
  "stryk autamtiskt D om",
  "substitutionsmetoden",
  "subtraktion",
  "subtraktion av bråk",
  "subtraktion av decimaler",
  "tabell",
  "tabellerförmängder",
  "talföljder",
  "talteori",
  "tre basproblem procent",
  "triangel",
  "triangelns ekvation",
  "trianglar",
  "trianglar",
  "trianglar",
  "tänk på decimaltal",
  "viktiga bråk, procent, decimaltal",
  "viktiga enhetsomvandlingar",
  "viktiga potenser",
  "viktiga rötter",
  "vinkelräta linjer",
  "vinklar",
  "vinklar",
  "vinklar",
  "vinklar och parallella linjer",
  "volym",
  "vst",
  "yttervinkelsatsen",
  "översätta text till ekvationer",
];

export const resultat2 = [
  "KVAstrategier",
  "NOGstrategier",
  "XYZstrategier",
  "addition",
  "addition av bråk",
  "additionsmetoden",
  "algebra",
  "allmänna",
  "andragradsekvationer",
  "andragradsfunktioner",
  "använd svarsalternativen",
  "area",
  "aritmetik",
  "att förenkla uttryck",
  "att jämföra bråk KVA",
  "avrundning",
  "bråk",
  "bråk och decimaler",
  "cirkelgeometri",
  "cirkeln",
  "cirkelns ekvation",
  "cylinder",
  "decimaltal",
  "division",
  "division av bråk",
  "division av decimaler",
  "ekvationer",
  "ekvationer i bråkformat",
  "ekvationer med variabler",
  "ekvationssystem",
  "ekvationssystemens lösbarhet",
  "enheter och prefix",
  "enhetsomvandling",
  "estimat av antal",
  "exponentialekvationer",
  "faktorisering",
  "funktioner",
  "funktionslära",
  "fyrfältare",
  "förenkla",
  "förhörningar",
  "genometriska formler",
  "geometri",
  "godtyckliga figurer",
  "grunder",
  "grundläggande geometriska begrepp",
  "grundläggande matematik",
  "grundpotensform",
  "hastighetsuppgift med omvandling",
  "illustrera problemet",
  "jämföra bråk",
  "jämn delbarhet",
  "jämna och ojämna tal",
  "konjugatregeln",
  "konstanter",
  "koordinatsystem",
  "koordinatsystemOchRätaLinjer",
  "kvadrat",
  "kvadratens ekvation",
  "kvadrater",
  "kvadratrotsberäkning",
  "kvadreringsreglerna",
  "likformighet",
  "linjens ekvation",
  "lägesmått för talföljder med på varandra följande tal",
  "medelhastighet",
  "medelvärde",
  "medelvärde, median och typvärde",
  "median",
  "mgn",
  "minsta gemensamma nämnaren",
  "mittpunktsformeln",
  "multipler",
  "multiplikation",
  "multiplikation av bråk",
  "multiplikation av decimaler",
  "multiplikatoin",
  "mängder",
  "negativa tal",
  "olika typer av trianglar",
  "olikheter",
  "omkrets",
  "omkrets, area och volym",
  "ordningarOchOrdningsföljder",
  "organisera informationen",
  "plankmålning",
  "polynommultiplikation",
  "potens & exponentialfunktioner",
  "potens av potens",
  "potens vid division",
  "potens vid multiplikation",
  "potenser",
  "potenslagar",
  "potenslagarna",
  "potensregler",
  "potensräkning",
  "primtal",
  "primtalsfaktorisering",
  "prioriteringsregler",
  "procent",
  "procent och procentenheter",
  "procent och promille",
  "procentuella förändringar",
  "pythagoras",
  "pythagoras sats",
  "rabattkuponger",
  "radie",
  "rektangel",
  "rektanglar",
  "resonera istället för att beräkna",
  "rita figur",
  "roten ur",
  "räkna inte ut uppgiften",
  "räkna med kvadratrötter",
  "räkneordning",
  "räkneregler",
  "räta linjens ekvation",
  "rörelse",
  "rötter",
  "samband mellan potens och rötter",
  "sannolikhet",
  "sannolikhetsräkning",
  "sidlängd",
  "skriv om uppgiften till ekvationsform",
  "sortering",
  "stapla klossar",
  "statistik",
  "statistiskamått",
  "stryk autamtiskt D om",
  "substitutionsmetoden",
  "subtraktion",
  "subtraktion av bråk",
  "subtraktion av decimaler",
  "tabell",
  "tabellerförmängder",
  "talföljder",
  "talteori",
  "tre basproblem procent",
  "triangel",
  "triangelns ekvation",
  "trianglar",
  "tänk på decimaltal",
  "viktiga bråk, procent, decimaltal",
  "viktiga enhetsomvandlingar",
  "viktiga potenser",
  "viktiga rötter",
  "vinkelräta linjer",
  "vinklar",
  "vinklar och parallella linjer",
  "volym",
  "vst",
  "yttervinkelsatsen",
  "översätta text till ekvationer",
];

export const resultat3 = {
  aritmetik: {
    områden: ["addition", "förenkla", "räkneordning", "avrundning"],
    subtraktion: {
      områden: ["negativa tal"],
    },
    multiplikation: {
      områden: ["multipler", "rötter"],
      polynommultiplikation: {
        områden: ["konjugatregeln", "kvadreringsreglerna"],
      },
      faktorisering: {
        områden: ["primtal"],
      },
      potenser: {
        områden: ["potenslagar", "rötter i potensform", "grundpotensform"],
      },
    },
    division: {
      områden: [
        "addition/subtraktion av bråk",
        "multiplikation av bråk",
        "division av bråk",
      ],
      delbarhet: {
        områden: [
          "minsta gemensamma nämnaren",
          "delbarhet",
          "jämna och ojämna tal",
        ],
      },
      decimaltal: {
        områden: ["division av decimaler", "multiplikation av decimaler"],
      },
    },
  },
  algebra: {
    områden: [
      "ekvationer",
      "ekvationer i bråkformat",
      "olikheter",
      "exponentialekvationer",
    ],
    ekvationssystem: {
      områden: [
        "additionsmetoden",
        "substitutionsmetoden",
        "ekvationssystemens lösbarhet",
      ],
    },
    andragradsekvationer: {
      områden: ["pq-formeln"],
    },
  },
  geometri: {
    omkretsOchArea: {
      områden: ["triangel", "cirkel"],
      fyrhörningar: {
        områden: ["sidlängd", "parallellogram", "rektangel", "kvadrat"],
      },
    },
    volym: {
      områden: ["rätblock", "cylinder"],
    },
    trianglar: {
      områden: ["likformighet", "pythagoras sats", "vinkelsumma"],
    },
    cirklar: {
      områden: ["pi", "radie", "diameter"],
    },
    vinklar: {
      områden: [
        "vinkelsumma",
        "cirkeln",
        "liksidig triangel",
        "vinkelräta linjer",
        "parallella linjer",
        "yttervinkelsatsen",
      ],
      rätvinkligTriangel: ["pythagoras sats"],
    },
  },
  funktioner: {
    områden: [
      "räta linjens ekvation",
      "andragradsfunktioner",
      "exponentialfunktioner",
      "koordinatsystem",
    ],
    rätaLinjensEkvation: {
      områden: ["mittpunktsformeln"],
    },
  },
  praktiskMatematik: {
    områden: ["enheter och prefix", "enhetsomvandling", "sannolikhet"],
    rörelse: {
      områden: ["sträcka tid hastighet", "medelhastighet"],
    },
    statistik: {
      områden: ["medelvärde", "median", "typvärde"],
    },
    procent: {
      områden: ["procentenheter", "promille", "procentuell förändring"],
    },
  },
  talteori: {
    områden: ["konstanter", "mängder", "talföljder"],
  },
};

export const övrigt = {
  uppgiftstyper: [
    "sortering",
    "plankmålning",
    "fyrfältare",
    "rabattkuponger",
    "godtyckliga figurer",
  ],
  tekniker: [
    "illustrera problemet",
    "rita figur",
    "tabell",
    "skriv om uppgiften till ekvationsform",
    "additionsmetoden",
    "substitutionsmetoden",
  ],
};
