import { Segment, Solution } from "hp-types";

type SegmentType = {
  solutionDomain: Solution[];
  secondsPerQuestion: number;
};

const segments: Record<Segment, SegmentType> = {
  XYZ: {
    solutionDomain: [Solution.A, Solution.B, Solution.C, Solution.D],
    secondsPerQuestion: 60,
  },
  KVA: {
    solutionDomain: [Solution.A, Solution.B, Solution.C, Solution.D],
    secondsPerQuestion: 60,
  },
  NOG: {
    solutionDomain: [
      Solution.A,
      Solution.B,
      Solution.C,
      Solution.D,
      Solution.E,
    ],
    secondsPerQuestion: 100,
  },
  DTK: {
    solutionDomain: [Solution.A, Solution.B, Solution.C, Solution.D],
    secondsPerQuestion: 115,
  },
};

export default segments;
