import { Section } from "../data/sections";
import addNewToSectionList from "./addNewToSectionList";
import getAllDependencies from "./getAllDependencies";

const getDependenciesFromList = (sections: Section[]): Section[] => {
  let dependencies: Section[] = [];
  sections.forEach((section) => {
    const ancestors = getAllDependencies(section);
    const newDependencies = addNewToSectionList(dependencies, ancestors);
    dependencies = newDependencies;
  });
  return dependencies;
};

export default getDependenciesFromList;
