import { Stack } from "@mui/material";
import { FC, useState } from "react";
import styled, { DefaultTheme, useTheme } from "styled-components";
import Modal from "../../../../../../../components/Modal";
import { CurrentQuestion } from "hp-types";
import shuffleArray from "../../../../../../../utils/shuffleArray";
import VideoFrame from "../../../../../../../components/VideoFrame";
import { Button, Heading, Text } from "hp-fabric";

const Placeholder = styled.div<{ width: number; height: number }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border: 1px solid ${(props) => props.theme.colors.borderGray};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spaces.s}px;
  @media (min-width: 400px) {
    gap: ${(props) => props.theme.spaces.m}px;
  }
`;

const ButtonsContainer = styled.div<{ size?: string; active?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.spaces.s}px;
  justify-content: ${(props) => (props.size === "s" ? "center" : "flex-start")};
  ${(props) =>
    !props.active && props.size !== "s"
      ? "opacity: 0; pointer-events: none;"
      : "opacity: 1; pointer-events: auto;"};
  transition: opacity 0.2s ease-in;
`;

const getVideoDimensions = (theme: DefaultTheme) => {
  const modalWidth = (theme.dimensions.width || 300) - 2 * theme.spaces.m;
  const maxWidth = 574;
  const width = modalWidth > maxWidth ? maxWidth : modalWidth;
  const height = width * (9 / 16);
  return { width, height };
};

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  question: CurrentQuestion;
};

const VideosModal: FC<Props> = ({ open, setOpen, question }) => {
  const theme = useTheme();
  const [resources] = useState(shuffleArray(question.resources));
  const [selectedResource, setSelectedResource] = useState<{
    name: string;
    url: string;
  } | null>(null);
  const { width, height } = getVideoDimensions(theme);
  return (
    <Modal open={open} setOpen={setOpen} padding="m">
      <Stack spacing={1}>
        <Heading level={2}>Resurser</Heading>
        <ButtonsContainer active={!!selectedResource}>
          {resources.map((resource) => (
            <Button
              key={resource.url}
              kind="option"
              size="s"
              selected={
                !!selectedResource && resource.name === selectedResource.name
              }
              color={theme.colors.gray6}
              onClick={() => setSelectedResource(resource)}
            >
              {resource.name}
            </Button>
          ))}
        </ButtonsContainer>
        {selectedResource ? (
          <VideoFrame
            width={width}
            height={height}
            resource={selectedResource}
          />
        ) : (
          <Placeholder width={width} height={height}>
            <Text align="center">Välj en kanal för att se lösningsexempel</Text>
            <ButtonsContainer size="s">
              {resources.map((resource) => (
                <Button
                  key={resource.url}
                  kind="option"
                  size="s"
                  color={theme.colors.gray6}
                  onClick={() => setSelectedResource(resource)}
                >
                  {resource.name}
                </Button>
              ))}
            </ButtonsContainer>
          </Placeholder>
        )}
      </Stack>
    </Modal>
  );
};

export default VideosModal;
