import { FC } from "react";
import styled from "styled-components";

const StyledDot = styled.div<{ active: boolean }>`
  height: ${(props) => (props.active ? 7 : 6)}px;
  width: ${(props) => (props.active ? 7 : 6)}px;
  border-radius: 50%;
  margin-left: 5px;
  background-color: ${(props) => {
    if (props.active) {
      return props.theme.isDark
        ? props.theme.colors.gray3
        : props.theme.colors.blue1;
    }
    return props.theme.isDark
      ? props.theme.colors.gray7
      : props.theme.colors.gray5;
  }};
`;

type Props = {
  active: boolean;
};

const NavDot: FC<Props> = ({ active }) => {
  return <StyledDot active={active} />;
};

export default NavDot;
