import {
  TrainingArgument,
  TrainingData,
  TrainingType,
} from "../providers/MathTrainingProvider";
import validateTraingDataAnswer from "./validateTrainingDataAnswer";

type Props = {
  trainingData: TrainingData;
  subject: TrainingType;
};

const getFinishedLevelFromTrainingData = ({ trainingData, subject }: Props) => {
  const argumentList: TrainingArgument[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  let finished = 0;
  argumentList.forEach((arg1) => {
    argumentList.forEach((arg2) => {
      const history = trainingData[arg1][arg2];
      const success = history.slice(-3).filter((answer) =>
        validateTraingDataAnswer({
          type: subject,
          arg1,
          arg2,
          answer: answer.answer,
        })
      ).length;
      finished += success;
    });
  });
  let total = 9 * 9 * 3;
  if (subject === "division") {
    argumentList.forEach((arg1) => {
      argumentList.forEach((arg2) => {
        if (String((arg1 / arg2) % 1).length > 4) {
          total -= 3;
        }
      });
    });
  }
  return (finished * 100) / total;
};

export default getFinishedLevelFromTrainingData;
