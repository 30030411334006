import {
  TrainingArgument,
  TrainingType,
} from "../providers/MathTrainingProvider";

type Props = {
  type: TrainingType;
  arg1: TrainingArgument;
  arg2: TrainingArgument;
};

const getSolutionForTrainingData = ({ type, arg1, arg2 }: Props) => {
  switch (type) {
    case "addition":
      return arg1 + arg2;
    case "subtraktion":
      return arg1 - arg2;
    case "multiplikation":
      return arg1 * arg2;
    case "division":
      return arg1 / arg2;
    default:
      throw new Error("Känner inte igen typen " + type);
  }
};

export default getSolutionForTrainingData;
