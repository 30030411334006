import { FC } from "react";
import {
  TrainingArgument,
  TrainingType,
} from "../../../../providers/MathTrainingProvider";
import styled, { css } from "styled-components";
import getSolutionForTrainingData from "../../../../utils/getSolutionForTrainingData";
import getSubjectSign from "../../../../utils/getSubjectSign";
import { Text } from "hp-fabric";

const StyledTextContainer = styled.div`
  display: flex;
  > p {
    margin: 0
      ${(props) =>
        (Math.min(600, props.theme.dimensions.width || 0) / 100) * 0.5}px;
  }
`;

type Props = {
  arg1: TrainingArgument;
  arg2: TrainingArgument;
  subject: TrainingType;
  fontSize: number;
};

const QuestionText: FC<Props> = ({ arg1, arg2, subject, fontSize }) => {
  return (
    <StyledTextContainer>
      <Text
        customCss={css`
          font-size: ${fontSize}px;
        `}
      >
        {arg1}
      </Text>
      <Text
        customCss={css`
          font-size: ${fontSize}px;
        `}
      >
        {getSubjectSign(subject)}
      </Text>
      <Text
        customCss={css`
          font-size: ${fontSize}px;
        `}
      >
        {arg2}
      </Text>
      <Text
        customCss={css`
          font-size: ${fontSize}px;
        `}
      >
        =
      </Text>
      <Text
        customCss={css`
          font-size: ${fontSize}px;
        `}
      >
        {getSolutionForTrainingData({
          type: subject,
          arg1: arg1,
          arg2: arg2,
        })}
      </Text>
    </StyledTextContainer>
  );
};

export default QuestionText;
