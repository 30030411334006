import { doc, setDoc } from "firebase/firestore";
import { fireStore } from "../firebase";
import { v4 as uuidv4 } from "uuid";
import { FEEDBACK } from "hp-types";

export type Variables = {
  feedbackType: FEEDBACK;
  message: string;
  questionId?: string;
  section?: string;
};

export const addFeedback = async ({
  feedbackType,
  message,
  questionId,
  section,
}: Variables) => {
  const uuid = uuidv4();

  const errorObject = {
    ...(feedbackType && { feedbackType }),
    ...(message && { message }),
    ...(questionId && { questionId }),
    ...(section && { section }),
  };

  const updateObject = {
    [uuid]: errorObject,
  };

  const documentReference = doc(
    fireStore,
    "feedback",
    feedbackType.toLowerCase()
  );
  await setDoc(documentReference, updateObject, { merge: true });
};
