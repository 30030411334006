import { FC, createContext, useContext, ReactNode } from "react";
import { useQuery } from "react-query";
import { DTKobject, CurrentDtkObject } from "hp-types";
import fetchDtkObject from "../../data/queries/fetchDtkObject";

export const convertDtkObjectToCurrentDtkObject = (
  dtkObject: DTKobject
): CurrentDtkObject => ({
  ...dtkObject,
  images: dtkObject.images.map((image) => ({ ...image, path: [] })),
});

export type DtkObjectContextType = {
  dtkObject: Record<string, DTKobject> | undefined;
  loadingDtkObject: boolean;
};

export const DtkObjectContext = createContext<DtkObjectContextType | null>(
  null
);

export const useDtkObject = (): DtkObjectContextType => {
  const context = useContext(DtkObjectContext);
  if (!context) {
    throw new Error("useDtkObject must be inside the DtkObjectProvider");
  }
  return context;
};

type Props = {
  children: ReactNode;
};

const DtkObjectProvider: FC<Props> = ({ children }) => {
  const { data: dtkObject, isLoading: loadingDtkObject } = useQuery(
    ["dtk-object"],
    () => fetchDtkObject()
  );

  return (
    <DtkObjectContext.Provider
      value={{
        dtkObject,
        loadingDtkObject,
      }}
    >
      {children}
    </DtkObjectContext.Provider>
  );
};

export default DtkObjectProvider;
