import { FC } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "./SlideButton.css";
import styled from "styled-components";
import pSBC from "../../../../../../utils/psBC";

const StyledSlideButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.borderGray};
  padding: 0;
  cursor: pointer;
  background-color: ${(props) => pSBC(0.02, props.theme.colors.surface)};
  color: ${(props) => props.theme.colors.textPrimary};

  ${(props) =>
    props.disabled
      ? `
    background-color: ${props.theme.colors.surface};
    color: ${pSBC(-0.5, props.theme.colors.textPrimary)};
    border: 1px solid ${pSBC(-0.1, props.theme.colors.borderGray)};
    cursor: not-allowed;
    `
      : `
    &:hover {
    background-color: ${pSBC(0.05, props.theme.colors.surface)};
    color: ${pSBC(0.05, props.theme.colors.textPrimary)};
    border: 1px solid ${pSBC(0.05, props.theme.colors.borderGray)};
    }
    `}
`;

type Props = {
  disabled: boolean;
  onClick: () => void;
};

const SlideButton: FC<Props> = ({ onClick, disabled }) => {
  return (
    <StyledSlideButton disabled={disabled}>
      <div
        style={{
          position: "absolute",
          left: 3,
          top: 13,
        }}
        onClick={!disabled ? onClick : () => {}}
      >
        <KeyboardArrowRightIcon />
      </div>
    </StyledSlideButton>
  );
};

export default SlideButton;
