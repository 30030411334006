import { Button, Card, Heading, Page, Text } from "hp-fabric";
import { FC, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { css, useTheme } from "styled-components";
import MenuIcon from "../../components/MenuIcon";
import {
  TrainingType,
  useMathTraining,
} from "../../providers/MathTrainingProvider";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import ResultRow from "./ResultRow";

type Props = {};

const SubjectResult: FC<Props> = () => {
  const { latestResult } = useMathTraining();

  const { subject } = useParams() as { subject: TrainingType };
  const navigate = useNavigate();
  const theme = useTheme();
  useEffect(() => {
    if (!latestResult.length) {
      navigate(`/math-training/${subject}`);
    }
  });
  return (
    <Page
      header={{
        leftComponent: <MenuIcon />,
        centerComponent: (
          <Heading level={1} size="s">
            Resultat
          </Heading>
        ),
      }}
    >
      <Card>
        <div>
          <table style={{ width: "100%" }}>
            <tr>
              <th />
              <th>
                <Text
                  align="center"
                  customCss={css`
                    font-size: 1.5rem;
                  `}
                >
                  facit
                </Text>
              </th>
              <th>
                <Text
                  align="right"
                  customCss={css`
                    font-size: 1.5rem;
                  `}
                >
                  din tid
                </Text>
              </th>
            </tr>
            {latestResult.map((result, index) => {
              return (
                <ResultRow result={result} index={index} subject={subject} />
              );
            })}
          </table>
          <div
            style={{
              margin: "0 auto",
              width: "fit-content",
              padding: theme.spaces.m,
            }}
          >
            <Button
              kind="secondary"
              link={{ to: `/math-training/${subject}`, component: Link }}
            >
              {`Återgå till ${capitalizeFirstLetter(subject)}`}
            </Button>
          </div>
        </div>
      </Card>
    </Page>
  );
};

export default SubjectResult;
