import { Button } from "hp-fabric";
import { ButtonSizes } from "hp-fabric/dist/components/Button/ButtonBase";
import { FC } from "react";

type Props = {
  size?: string;
  name: string;
  selected: boolean;
  color: string;
  onClick: (argument: boolean) => void;
};

const SelectorItem: FC<Props> = ({
  color,
  size = "m",
  selected,
  onClick,
  name,
}) => {
  return (
    <Button
      kind="option"
      size={size as ButtonSizes}
      selected={selected}
      color={color}
      onClick={() => onClick(!selected)}
    >
      {name}
    </Button>
  );
};

export default SelectorItem;
