import { useTheme } from "styled-components";
import MultiSelect from "../../../../../components/MultiSelect";
import { useFilter } from "../../../../../providers/FilterProvider";
import checkTestsFilter from "../../../../../providers/FilterProvider/checkTestsFilter";
import { useMetaData } from "../../../../../providers/MetaDataProvider/MetaDataProvider";

const getDisplayText = (selected: string[]) => {
  if (selected.length === 0) {
    return "Samtliga tidigare provpass";
  } else if (selected.length === 1) {
    return selected[0];
  } else {
    return `${selected.length} provpass valda`;
  }
};

const TestSelect = () => {
  const theme = useTheme();
  const { filter, setFilter } = useFilter();
  const { testSessions } = useMetaData();

  const options = testSessions.map((testSession) => {
    const date = testSession.slice(0, 10);
    const partNumber = Number(testSession.slice(11));
    const month = Number(date.slice(5, 7));
    const season = month < 8 ? "våren" : "hösten";

    const name = `${date} (${season}) Provpass ${partNumber}`;
    return {
      id: name,
      label: name,
      selected:
        !!filter.tests.length &&
        checkTestsFilter(filter.tests, { date, partNumber }),
    };
  });

  const selected = options
    .filter((option) => option.selected)
    .map((option) => option.id);
  const displayedText = getDisplayText(selected);
  const dropDownSize = {
    maxHeight: 0.5 * (theme.dimensions.height || 500),
    width: 800,
  };

  const onChange = (selected: string[]) => {
    const newTestFilter = selected.map((testString) => {
      const seasonPattern = / \(.*\) Provpass /;
      const [date, partNumber] = testString.split(seasonPattern);
      return {
        date,
        partNumber: Number(partNumber),
      };
    });

    setFilter({ ...filter, tests: newTestFilter });
  };

  const label = "Tidigare provpass";
  const allOption = "Samtliga tidigare provpass";

  return (
    <MultiSelect
      label={label}
      displayedText={displayedText}
      allOption={allOption}
      options={options.reverse()}
      dropDownSize={dropDownSize}
      onChange={onChange}
    />
  );
};

export default TestSelect;
