import { FC } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useCurrentQuestion } from "../../providers/CurrentQuestionProvider";
import { AnswerData, useAnswers } from "../../providers/AnswersProvider";
import { useSettings } from "../../providers/SettingsProvider";
import { useQuestions } from "../../providers/QuestionsProvider";
import { TestingPage } from "hp-fabric";
import { useDtkObject } from "../../providers/DtkObjectProvider/DtkObjectProvider";

export const OPACITY_SPEED = 0.5;
export const TRANSFORM_SPEED = 0.9;

const Testing: FC = () => {
  const navigate = useNavigate();
  const { addAnswers } = useAnswers();
  const { settings } = useSettings();

  const {
    currentQuestion,
    currentQuestions,
    currentQuestionIndex,
    registerAnswer,
    setQuestion,
  } = useCurrentQuestion();
  const { loadingQuestions } = useQuestions();

  const { dtkObject } = useDtkObject();

  if (!currentQuestion && !loadingQuestions) {
    return <Navigate to="/" replace={true} />;
  }

  const finishTest = () => {
    const timeStamp = new Date().getTime();
    const newAnswers = currentQuestions
      .filter(({ answer }) => answer)
      .map((question) => ({
        questionId: question.id,
        answer: question.answer,
        seconds: question.seconds,
        timeStamp,
      })) as AnswerData[];
    addAnswers(newAnswers);
    navigate("/result");
  };

  return (
    <TestingPage
      dtkObjects={dtkObject}
      currentQuestionIndex={currentQuestionIndex}
      currentQuestions={currentQuestions}
      finishTest={finishTest}
      registerAnswer={registerAnswer}
      setCurrentQuestionIndex={setQuestion}
      shouldHideTime={settings.hideTime}
      shouldShowPauseButton={settings.pauseButton}
    />
  );
};

export default Testing;
