import { LinkVertical } from "@visx/shape";
import { useState } from "react";
import { useTheme } from "styled-components";
import { getSectionByName, Section, SectionName } from "../../../data/sections";
import addNewToSectionList from "../../../utils/addNewToSectionList";
import getAllDependencies from "../../../utils/getAllDependencies";
import getDependenciesFromList from "../../../utils/getDependenciesFromList";
import Node from "./Node";

type Props = {
  sections: Section[];
};

const GraphChart = ({ sections }: Props) => {
  const theme = useTheme();
  const allSections = addNewToSectionList(
    sections,
    getDependenciesFromList(sections)
  );
  const hierarchies: Record<number, Section[]> = {
    0: allSections.filter((section) => section.dependencies.length === 0),
  };
  const defaultHighlighted = sections.map(({ name }) => name);
  const [selectedNode, setSelectedNode] = useState<SectionName | null>(null);
  const selectedHighlighted = selectedNode && [
    selectedNode,
    ...getAllDependencies(getSectionByName(selectedNode)).map(
      ({ name }) => name
    ),
  ];
  const highlighted =
    (selectedNode && selectedHighlighted) || defaultHighlighted;

  for (let i = 0; i < 10; i++) {
    const parentStrings = hierarchies[i]?.map((s) => s.name);
    const nextLevel = allSections.filter((s) =>
      s.dependencies.some((s) => parentStrings.includes(s.name))
    );
    hierarchies[i + 1] = nextLevel;
  }

  const screenWidth = (theme.dimensions.width || 0) - 2 * theme.spaces.m;
  const width = Math.min(screenWidth, 600);
  const height = 400;

  const nodes = [
    {
      section: getSectionByName("Addition"),
      xCenter: width / 2,
      yCenter: (15 / 400) * height,
    },
    {
      section: getSectionByName("Subtraktion"),
      xCenter: width / 4,
      yCenter: (65 / 400) * height,
    },
    {
      section: getSectionByName("Multiplikation"),
      xCenter: (3 * width) / 4,
      yCenter: (65 / 400) * height,
    },
    {
      section: getSectionByName("Division"),
      xCenter: width / 2,
      yCenter: (100 / 400) * height,
    },
    {
      section: getSectionByName("Negativa tal"),
      xCenter: width / 9,
      yCenter: (125 / 400) * height,
    },
    {
      section: getSectionByName("Potenser"),
      xCenter: (9 * width) / 10,
      yCenter: (115 / 400) * height,
    },
    {
      section: getSectionByName("Ekvationer"),
      xCenter: (5 * width) / 9,
      yCenter: (275 / 400) * height,
    },
    {
      section: getSectionByName("Ekvationssystem"),
      xCenter: (2 * width) / 10,
      yCenter: (355 / 400) * height,
    },
    {
      section: getSectionByName("Addition och subtraktion av bråk"),
      xCenter: (10 * width) / 14,
      yCenter: (335 / 400) * height,
    },
    {
      section: getSectionByName("Olikheter"),
      xCenter: (4 * width) / 10,
      yCenter: (225 / 400) * height,
    },

    {
      section: getSectionByName("Parenteser"),
      xCenter: (2 * width) / 8,
      yCenter: (195 / 400) * height,
    },
    {
      section: getSectionByName("Bråktal"),
      xCenter: (6 * width) / 8,
      yCenter: (205 / 400) * height,
    },
    {
      section: getSectionByName("Decimaler"),
      xCenter: (9 * width) / 10,
      yCenter: (165 / 400) * height,
    },
    {
      section: getSectionByName("Procent"),
      xCenter: (9 * width) / 10,
      yCenter: (235 / 400) * height,
    },
    {
      section: getSectionByName("Medelvärde"),
      xCenter: (12 * width) / 14,
      yCenter: (285 / 400) * height,
    },
    {
      section: getSectionByName("Räkneordning"),
      xCenter: (2 * width) / 15,
      yCenter: (245 / 400) * height,
    },
    {
      section: getSectionByName("Förenkla"),
      xCenter: width / 9,
      yCenter: (295 / 400) * height,
    },
  ];

  const links = nodes
    .map((node) => {
      return node.section.dependencies.map((section) => ({
        from: section.name,
        to: node.section.name,
      }));
    })
    .flat();

  return (
    <svg
      width={width}
      height={height}
      style={{ marginLeft: "auto", marginRight: "auto" }}
    >
      <rect
        width={width}
        height={height}
        onClick={() => setSelectedNode(null)}
        fill={theme.colors.surface}
      />
      {links.map((link) => {
        const fromNode = nodes.find((n) => n.section.name === link.from);
        const toNode = nodes.find((n) => n.section.name === link.to);
        const source = {
          x: fromNode?.xCenter,
          y: (fromNode?.yCenter || 0) + 5,
        };
        const target = {
          x: toNode?.xCenter,
          y: (toNode?.yCenter || 0) - 15,
        };

        return (
          <LinkVertical
            key={(fromNode?.section.name || "") + (toNode?.section.name || "")}
            data={{ source, target }}
            stroke={
              selectedNode &&
              highlighted.includes(fromNode?.section.name as SectionName) &&
              highlighted.includes(toNode?.section.name as SectionName)
                ? theme.colors.textPrimary
                : theme.colors.borderGray
            }
            strokeWidth="1"
            fill="none"
          />
        );
      })}
      {nodes.map((node) => {
        return (
          <Node
            setSelectedNode={setSelectedNode}
            key={node.section.name}
            section={node.section}
            xCenter={node.xCenter}
            yCenter={node.yCenter}
            highlighted={highlighted.includes(node.section.name)}
          />
        );
      })}
    </svg>
  );
};

export default GraphChart;
