import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCurrentQuestion } from "../../providers/CurrentQuestionProvider";
import { useFilter } from "../../providers/FilterProvider";
import { FilterPage } from "hp-fabric";
import { useAnswers } from "../../providers/AnswersProvider";
import { useQuestions } from "../../providers/QuestionsProvider";
import { useMetaData } from "../../providers/MetaDataProvider/MetaDataProvider";
import { InformationImages } from "hp-types";

const informationImages: InformationImages = {
  checked: {
    dark: require("./exclude_darkmode.png"),
    light: require("./exclude.png"),
  },
  unchecked: {
    dark: require("./include_darkmode.png"),
    light: require("./include.png"),
  },
};

const FilterContainer = () => {
  const navigate = useNavigate();
  const { answers } = useAnswers();
  const { loadingQuestions } = useQuestions();
  const { startTest } = useCurrentQuestion();
  const { sections } = useMetaData();
  const { filter, filtered, setFilter } = useFilter();
  const start = () => {
    startTest(filtered);
    navigate("/testing");
  };

  return (
    <FilterPage
      informationImages={informationImages}
      answers={answers}
      filter={filter}
      filtered={filtered}
      loading={loadingQuestions}
      setFilter={setFilter}
      startTest={start}
      linkComponent={Link}
      sections={sections}
    />
  );
};

export default FilterContainer;
