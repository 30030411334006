import { AxisLeft } from "@visx/axis";
import { useTheme } from "styled-components";
import { useBarChart } from "../BarChartProvider";

const LeftAxis = () => {
  const theme = useTheme();
  const { bars, marginLeft, marginTop, yScale } = useBarChart();
  const allInt = bars.every(({ value }) => value % 1 === 0);
  return (
    <AxisLeft
      stroke={theme.colors.textPrimary}
      tickStroke={theme.colors.textPrimary}
      tickLabelProps={() => ({
        fill: theme.colors.textPrimary,
        fontSize: 8,
        transform: `translate(-3, 0.5)`,
        textAnchor: "end",
      })}
      numTicks={allInt ? Math.max(...bars.map(({ value }) => value)) : 11}
      scale={yScale}
      left={marginLeft}
      top={marginTop}
    />
  );
};

export default LeftAxis;
