/*
 * -1: failed
 * 0: unanswered
 * 1: completed once in a row
 * 2: completed twice in a row
 * 3: completed three times in a row
 * 4: completed four times in a row
 */

import {
  TrainingAnswer,
  TrainingArgument,
  TrainingType,
} from "../providers/MathTrainingProvider";
import validateTraingDataAnswer from "./validateTrainingDataAnswer";

type Props = {
  arg1: TrainingArgument;
  arg2: TrainingArgument;
  subject: TrainingType;
  history: TrainingAnswer[];
};

const getTrainingCompletedLevel = ({ arg1, arg2, history, subject }: Props) => {
  if (!history.length) {
    return 0;
  }
  const failedLatest = !validateTraingDataAnswer({
    type: subject,
    arg1,
    arg2,
    answer: history[history.length - 1].answer,
  });
  if (failedLatest) {
    return -1;
  }

  if (history.length === 1) {
    return 1;
  }
  const failedSecondToLatest = !validateTraingDataAnswer({
    type: subject,
    arg1,
    arg2,
    answer: history[history.length - 2].answer,
  });
  if (failedSecondToLatest) {
    return 1;
  }

  if (history.length === 2) {
    return 2;
  }
  const failedThirdToLatest = !validateTraingDataAnswer({
    type: subject,
    arg1,
    arg2,
    answer: history[history.length - 3].answer,
  });
  if (failedThirdToLatest) {
    return 2;
  }

  if (history.length === 3) {
    return 3;
  }
  const failedFourthToLatest = !validateTraingDataAnswer({
    type: subject,
    arg1,
    arg2,
    answer: history[history.length - 4].answer,
  });
  if (failedFourthToLatest) {
    return 3;
  }

  if (history.length === 4) {
    return 4;
  }
  const failedFifthToLatest = !validateTraingDataAnswer({
    type: subject,
    arg1,
    arg2,
    answer: history[history.length - 5].answer,
  });
  if (failedFifthToLatest) {
    return 4;
  }
  return 5;
};

export default getTrainingCompletedLevel;
