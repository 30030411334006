import { FC, useState } from "react";
import styled from "styled-components";
import Filter from "./Filter";
import FooterTop from "./FooterTop";
import { Question } from "hp-types";

const StyledFooter = styled.footer<{ isOpen: boolean }>`
  box-sizing: border-box;
  transition: height 0.35s ease-in-out;
  background-color: ${(props) => props.theme.colors.surface};
  height: ${(props) => (props.isOpen ? "70vh" : props.theme.spaces.xxxl)}px;
  overflow: ${(props) => (props.isOpen ? "auto" : "hidden")};
  padding: ${(props) => props.theme.spaces.s}px;
  min-width: 100vw;
  position: fixed;
  bottom: 0;
  box-shadow: 0 1px 10px rgb(0 0 0 / 10%);
  border-top: 1px solid ${(props) => props.theme.colors.borderGray};
`;

const FooterContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

type Props = {
  filtered: Question[];
};

const FilterFooter: FC<Props> = ({ filtered }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledFooter isOpen={isOpen}>
      <FooterContainer>
        <FooterTop
          filtered={filtered}
          open={() => setIsOpen(true)}
          close={() => setIsOpen(false)}
          isOpen={isOpen}
        />
        <Filter isOpen={isOpen} />
      </FooterContainer>
    </StyledFooter>
  );
};

export default FilterFooter;
