import { doc, getDoc } from "firebase/firestore";
import { fireStore } from "../firebase";
import { MetaData } from "hp-types";

export const fetchMetaData = async (): Promise<MetaData> => {
  const reference = doc(fireStore, "questions", "meta");
  return getDoc(reference).then((doc) => doc.data()) as Promise<MetaData>;
};

export default fetchMetaData;
