type SectionResource = {
  creator:
    | "Matteboken"
    | "Eddler"
    | "Wikipedia"
    | "HPGuiden"
    | "Nationellt Centrum för Matematikutbildning"
    | "Mathleaks"
    | "Vidma"
    | "Formelsamlingen"
    | "Fysikguiden";
  url: string;
  label?: string;
};

export type SectionName =
  | "Addition"
  | "Subtraktion"
  | "Negativa tal"
  | "Multiplikation"
  | "Multipler"
  | "Potenser"
  | "Potenslagar"
  | "Polynommultiplikation"
  | "Konjugatregeln"
  | "Kvadreringsreglerna"
  | "Rötter"
  | "Division"
  | "Faktorisering"
  | "Primtal"
  | "Decimaler"
  | "Bråktal"
  | "Addition och subtraktion av bråk"
  | "Multiplikation och division av bråk"
  | "Avrundning"
  | "Delbarhet"
  | "Minsta gemensamma nämnare"
  | "Naturliga tal"
  | "Jämna och ojämna tal"
  | "Grundpotensform"
  | "Parenteser"
  | "Räkneordning"
  | "Förenkla"
  | "Ekvationer"
  | "Olikheter"
  | "Linjära olikheter"
  | "Ekvationssystem"
  | "Andragradsekvationer"
  | "Exponentialekvationer"
  | "PQ-formeln"
  | "Vinklar"
  | "Vinkelsumma"
  | "Vinkelräta linjer"
  | "Parallella linjer"
  | "Trianglar"
  | "Triangelns omkrets"
  | "Triangelns Area"
  | "Likformighet"
  | "Rätvinklig triangel"
  | "Liksidig triangel"
  | "Likbent triangel"
  | "Pythagoras sats"
  | "Yttervinkelsatsen"
  | "Cirklar"
  | "π (pi)"
  | "Radie"
  | "Diameter"
  | "Cirkelns area"
  | "Cirkelbåge"
  | "Cirkelsektor"
  | "Cirkelns omkrets"
  | "Fyrhörningar"
  | "Parallellogram"
  | "Area av parallellogram"
  | "Area av rektangel"
  | "Omkrets av rektangel"
  | "Kvadrat"
  | "Rätblock"
  | "Volymen av ett rätblock"
  | "Cylinder"
  | "Volymen av en cylinder"
  | "Funktioner"
  | "Koordinatsystem"
  | "Avståndsformeln"
  | "Räta linjens ekvation"
  | "Andragradsfunktioner"
  | "Exponentialfunktioner"
  | "Mittpunktsformeln"
  | "Enheter och prefix"
  | "Enhetsomvandling"
  | "Hastighet, sträcka och tid"
  | "Medelhastighet"
  | "Medelvärde"
  | "Median"
  | "Typvärde"
  | "Procent"
  | "Sannolikhet"
  | "Procentenheter"
  | "Promille"
  | "Procentuell forändring"
  | "Talföljder";

export type Section = {
  name: SectionName;
  dependencies: Section[];
  area: string;
  resources: SectionResource[];
};

const addition: Section = {
  name: "Addition",
  dependencies: [],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-5/de-fyra-raknesatten/addition",
    },
  ],
  area: "Aritmetik",
};

const subtraktion: Section = {
  name: "Subtraktion",
  dependencies: [addition],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-5/de-fyra-raknesatten/subtraktion",
    },
  ],
  area: "Aritmetik",
};

const negativaTal: Section = {
  name: "Negativa tal",
  dependencies: [subtraktion],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/aritmetik/negativa-tal",
    },
  ],
  area: "Aritmetik",
};

const multiplikation: Section = {
  name: "Multiplikation",
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-5/de-fyra-raknesatten/multiplikation",
    },
    {
      creator: "Matteboken",
      label: "Multiplikation med 10-potenser",
      url: "https://www.matteboken.se/lektioner/skolar-7/tal-och-de-fyra-raknesatten/multiplikation-med-10-100-och-1000",
    },
  ],
  dependencies: [addition],
  area: "Aritmetik",
};

const multipler: Section = {
  name: "Multipler",
  dependencies: [multiplikation],
  resources: [
    {
      creator: "HPGuiden",
      url: "https://hpguiden.se/forumet/topic/multipel-7505",
    },
  ],
  area: "Aritmetik",
};

const potenser: Section = {
  name: "Potenser",
  dependencies: [multiplikation],
  resources: [
    {
      creator: "Matteboken",
      label: "Potenser i årskurs 8",
      url: "https://www.matteboken.se/lektioner/skolar-8/tal-och-rakning/potenser",
    },
    {
      creator: "Matteboken",
      label: "Potenser i Matte 1",
      url: "https://www.matteboken.se/lektioner/matte-1/aritmetik/potenser",
    },
  ],
  area: "Aritmetik",
};

const potenslagar: Section = {
  name: "Potenslagar",
  dependencies: [potenser],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/aritmetik/potenser#!/:~:text=.-,Potenslagar",
    },
  ],
  area: "Aritmetik",
};

const polynommultiplikation: Section = {
  name: "Polynommultiplikation",
  dependencies: [multiplikation, potenser],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-3/polynom-och-ekvationer/multiplikation-av-polynom",
    },
  ],
  area: "Aritmetik",
};

const konjugatregeln: Section = {
  name: "Konjugatregeln",
  dependencies: [polynommultiplikation],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-2/algebra/konjugatregeln",
    },
  ],
  area: "Aritmetik",
};

const kvadreringsreglerna: Section = {
  name: "Kvadreringsreglerna",
  dependencies: [polynommultiplikation],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-2/algebra/kvadreringsreglerna",
    },
  ],
  area: "Aritmetik",
};

const rotter: Section = {
  name: "Rötter",
  dependencies: [multiplikation],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/aritmetik/kvadratrotter-och-andra-rotter",
    },
  ],
  area: "Aritmetik",
};

const division: Section = {
  name: "Division",
  dependencies: [multiplikation],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-5/de-fyra-raknesatten/division",
    },
    {
      creator: "Matteboken",
      label: "Division med 10-potenser",
      url: "https://www.matteboken.se/lektioner/skolar-7/tal-och-de-fyra-raknesatten/division-10-100-och-1000",
    },
  ],
  area: "Aritmetik",
};

const faktorisering: Section = {
  name: "Faktorisering",
  dependencies: [division],
  resources: [
    {
      creator: "Wikipedia",
      url: "https://sv.wikipedia.org/wiki/Faktorisering",
    },
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/algebra/faktorisering-och-parenteser",
    },
  ],
  area: "Aritmetik",
};

const primtal: Section = {
  name: "Primtal",
  dependencies: [faktorisering],
  resources: [
    {
      creator: "Eddler",
      url: "https://eddler.se/lektioner/primtal/",
    },
  ],
  area: "Aritmetik",
};

const decimaler: Section = {
  name: "Decimaler",
  dependencies: [division],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-7/tal-och-de-fyra-raknesatten/naturliga-tal-och-decimaltal#:~:text=Om%20vi%20markerar%20ett%20tal%20som%20ligger%20n%C3%A5gonstans%20mellan%20tv%C3%A5%20naturliga%20tal%20p%C3%A5%20tallinjen%2C%20s%C3%A5%20kan%20det%20talet%20inte%20vara%20ett%20heltal.%20D%C3%A4remot%20kan%20det%20vara%20ett%20decimaltal.",
    },
  ],
  area: "Aritmetik",
};

const braktal: Section = {
  name: "Bråktal",
  dependencies: [division],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-8/brak/braktal",
    },
  ],
  area: "Aritmetik",
};

const additionOchSubtrationAvBrak: Section = {
  name: "Addition och subtraktion av bråk",
  dependencies: [braktal, subtraktion],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-8/brak/addition-och-subtraktion-av-brak",
    },
  ],
  area: "Aritmetik",
};

const multiplikationOchDivisionAvBrak: Section = {
  name: "Multiplikation och division av bråk",
  dependencies: [braktal],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-8/brak/multiplikation-och-division-av-brak",
    },
  ],
  area: "Aritmetik",
};

const avrundning: Section = {
  name: "Avrundning",
  dependencies: [decimaler],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-7/tal-och-de-fyra-raknesatten/avrundning-och-overslagsrakning",
    },
  ],
  area: "Aritmetik",
};

const delbarhet: Section = {
  name: "Delbarhet",
  dependencies: [division],
  resources: [
    {
      creator: "Matteboken",
      url: "https://sv.wikipedia.org/wiki/Delbarhet",
    },
  ],
  area: "Aritmetik",
};

const MGN: Section = {
  name: "Minsta gemensamma nämnare",
  dependencies: [division],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/aritmetik/minsta-gemensamma-namnare#!/",
    },
  ],
  area: "Aritmetik",
};

const naturligaTal: Section = {
  name: "Naturliga tal",
  dependencies: [addition],
  resources: [
    {
      creator: "Matteboken",
      label: "Matte 1 - Talmängder",
      url: "https://www.matteboken.se/lektioner/matte-1/aritmetik/talmangder#!/",
    },
    {
      creator: "Matteboken",
      label: "Årskurs 6",
      url: "https://www.matteboken.se/lektioner/skolar-6/tal/naturliga-tal",
    },
  ],
  area: "Aritmetik",
};

const JamnaOchOjamnaTal: Section = {
  name: "Jämna och ojämna tal",
  dependencies: [naturligaTal],
  resources: [
    {
      creator: "Nationellt Centrum för Matematikutbildning",
      url: "http://ncm.gu.se/media/stravorna/2/a/2AB_jamna_och_udda_tal.pdf",
    },
  ],
  area: "Aritmetik",
};

const grundpotensform: Section = {
  name: "Grundpotensform",
  dependencies: [potenser, decimaler],
  resources: [
    {
      creator: "Matteboken",
      label: "Grundpotensform",
      url: "https://www.matteboken.se/lektioner/skolar-8/tal-och-rakning/grundpotensform",
    },
  ],
  area: "Aritmetik",
};

const parenteser: Section = {
  name: "Parenteser",
  dependencies: [subtraktion, division],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-8/variabler-och-uttryck/uttryck-med-parenteser",
    },
    {
      creator: "Matteboken",
      label: "Multiplikation av parenteser",
      url: "https://www.matteboken.se/lektioner/skolar-8/variabler-och-uttryck/multiplikation-av-parenteser",
    },
  ],
  area: "Aritmetik",
};

const rakneordning: Section = {
  name: "Räkneordning",
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/aritmetik/rakneordning",
    },
  ],
  dependencies: [parenteser],
  area: "Aritmetik",
};

const forenkla: Section = {
  name: "Förenkla",
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/algebra/forenkla-uttryck",
    },
  ],
  dependencies: [rakneordning],
  area: "Aritmetik",
};

const ekvationer: Section = {
  name: "Ekvationer",
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/algebra/formler-och-ekvationer",
    },
  ],
  dependencies: [subtraktion, division],
  area: "Algebra",
};

const olikheter: Section = {
  name: "Olikheter",
  dependencies: [subtraktion, division],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/funktioner/olikheter-och-linjara-olikheter",
    },
  ],
  area: "Algebra",
};

const linjäraOlikheter: Section = {
  name: "Linjära olikheter",
  dependencies: [olikheter, ekvationer],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/funktioner/olikheter-och-linjara-olikheter#!/",
    },
  ],
  area: "Algebra",
};

const ekvationssystem: Section = {
  name: "Ekvationssystem",
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-2/ovningsexempel/ekvationssystem",
    },
    {
      creator: "Matteboken",
      label: "Med tre obekanta",
      url: "https://eddler.se/lektioner/linjara-ekvationssystem-med-tre-okanda/",
    },
  ],
  dependencies: [ekvationer],
  area: "Algebra",
};

const andragradsekvationer: Section = {
  name: "Andragradsekvationer",
  dependencies: [ekvationer],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-2/andragradsekvationer/andragradsekvationer",
    },
  ],
  area: "Algebra",
};

const exponentialEkvationer: Section = {
  name: "Exponentialekvationer",
  dependencies: [ekvationer, potenser],
  resources: [
    {
      creator: "Eddler",
      url: "https://eddler.se/lektioner/exponentialekvationer",
    },
  ],
  area: "Algebra",
};

const pqFormeln: Section = {
  name: "PQ-formeln",
  dependencies: [andragradsekvationer],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-2/andragradsekvationer/pq-formeln",
    },
  ],
  area: "Algebra",
};

const vinklar: Section = {
  name: "Vinklar",
  dependencies: [],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-7/geometri-och-enheter/vinklar",
    },
  ],
  area: "Geometri",
};

const vinkelsumma: Section = {
  name: "Vinkelsumma",
  dependencies: [vinklar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-8/geometri-och-enheter/vinklar",
    },
    {
      creator: "Wikipedia",
      url: "https://sv.wikipedia.org/wiki/Vinkelsumma",
    },
  ],
  area: "Geometri",
};

const vinkelrataLinjer: Section = {
  name: "Vinkelräta linjer",
  dependencies: [vinklar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/funktioner/parallella-och-vinkelrata-linjer#!/",
    },
  ],
  area: "Geometri",
};

const parallellaLinjer: Section = {
  name: "Parallella linjer",
  dependencies: [vinkelrataLinjer],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/funktioner/parallella-och-vinkelrata-linjer#!/",
    },
  ],
  area: "Geometri",
};

const trianglar: Section = {
  name: "Trianglar",
  dependencies: [vinklar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-8/geometri-och-enheter/trianglar",
    },
  ],
  area: "Geometri",
};

const triangelnsOmkrets: Section = {
  name: "Triangelns omkrets",
  dependencies: [trianglar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/geometri/omkrets-och-area#!/:~:text=Trianglar%20har%20alltid%20tre%20sidor.%20Omkretsen%20av%20en%20triangel%20%C3%A4r%20summan%20av%20dess%20tre%20sidor.",
    },
  ],
  area: "Geometri",
};

const triangelnsArea: Section = {
  name: "Triangelns Area",
  dependencies: [trianglar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/geometri/omkrets-och-area#!/:~:text=Arean%20f%C3%B6r%20en%20triangel%20f%C3%A5r%20man%20enligt%20f%C3%B6ljande%20formel",
    },
  ],
  area: "Geometri",
};

const likformighet: Section = {
  name: "Likformighet",
  dependencies: [trianglar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-2/geometri/likformighet-och-kongruens",
    },
  ],
  area: "Geometri",
};

const rätvinkligTriangel: Section = {
  name: "Rätvinklig triangel",
  dependencies: [trianglar, vinkelrataLinjer],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-8/geometri-och-enheter/trianglar#:~:text=R%C3%A4tvinkliga%20trianglar,triangel%20alltid%20%C3%A4r%20180%C2%B0.",
    },
  ],
  area: "Geometri",
};

const liksidigTriangel: Section = {
  name: "Liksidig triangel",
  dependencies: [trianglar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-7/geometri-och-enheter/trianglar#:~:text=En%20liksidig%20triangel%20%C3%A4r%20en%20triangel%20d%C3%A4r%20alla%20tre%20sidorna%20%C3%A4r%20lika%20l%C3%A5nga.",
    },
  ],
  area: "Geometri",
};

const likbentTriangel: Section = {
  name: "Likbent triangel",
  dependencies: [trianglar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-7/geometri-och-enheter/trianglar#:~:text=En%20likbent%20triangel%20%C3%A4r%20en%20triangel%20d%C3%A4r%20tv%C3%A5%20av%20sidorna%20%C3%A4r%20lika%20l%C3%A5nga.",
    },
  ],
  area: "Geometri",
};

const pythagorasSats: Section = {
  name: "Pythagoras sats",
  dependencies: [rätvinkligTriangel, rotter, potenser],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-9/geometri/pythagoras-sats",
    },
  ],
  area: "Geometri",
};

const yttervinkelsatsen: Section = {
  name: "Yttervinkelsatsen",
  dependencies: [trianglar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-2/ovningsexempel/geometri",
    },
    {
      creator: "Wikipedia",
      url: "https://sv.wikipedia.org/wiki/Yttervinkelsatsen",
    },
  ],
  area: "Geometri",
};

const cirklar: Section = {
  name: "Cirklar",
  dependencies: [vinklar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-8/geometri-och-enheter/cirklar",
    },
  ],
  area: "Geometri",
};

const pi: Section = {
  name: "π (pi)",
  dependencies: [cirklar],
  resources: [
    {
      creator: "Wikipedia",
      url: "https://sv.wikipedia.org/wiki/Pi",
    },
    {
      creator: "Eddler",
      url: "https://eddler.se/lektioner/talet-pi-%CF%80/",
    },
  ],
  area: "Geometri",
};

const radie: Section = {
  name: "Radie",
  dependencies: [cirklar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-8/geometri-och-enheter/cirklar",
    },
  ],
  area: "Geometri",
};

const diameter: Section = {
  name: "Diameter",
  dependencies: [cirklar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-8/geometri-och-enheter/cirklar",
    },
  ],
  area: "Geometri",
};

const cirkelnsArea: Section = {
  name: "Cirkelns area",
  dependencies: [pi, radie, potenser],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/geometri/omkrets-och-area#!/:~:text=Arean%20av%20en%20cirkel%20f%C3%A5r%20man%20genom%20f%C3%B6ljande%20formel%3A",
    },
  ],
  area: "Geometri",
};

const cirkelBage: Section = {
  name: "Cirkelbåge",
  dependencies: [diameter, radie, pi],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-8/geometri-och-enheter/cirklar#:~:text=H%C3%A4r%20g%C3%A5r%20vi%20igenom%20cirkelb%C3%A5ge%20och%20cirkelsektor.",
    },
  ],
  area: "Geometri",
};

const cirkelSektor: Section = {
  name: "Cirkelsektor",
  dependencies: [radie, diameter, pi],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-8/geometri-och-enheter/cirklar#:~:text=H%C3%A4r%20g%C3%A5r%20vi%20igenom%20cirkelb%C3%A5ge%20och%20cirkelsektor.",
    },
  ],
  area: "Geometri",
};

const cirkelnsOmkrets: Section = {
  name: "Cirkelns omkrets",
  dependencies: [cirklar, pi, radie, multiplikation],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/geometri/omkrets-och-area#!/:~:text=Omkretsen%20blir%20d%C3%A5,%2C%20allts%C3%A5%3A",
    },
  ],
  area: "Geometri",
};

const fyrhorningar: Section = {
  name: "Fyrhörningar",
  dependencies: [vinklar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-7/geometri-och-enheter/fyrhorningar",
    },
  ],
  area: "Geometri",
};

const parallellogram: Section = {
  name: "Parallellogram",
  dependencies: [fyrhorningar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-7/geometri-och-enheter/fyrhorningar#:~:text=En%20parallellogram%20%C3%A4r%20en%20fyrh%C3%B6rning%20d%C3%A4r%20motst%C3%A5ende%20sidor%20%C3%A4r%20lika%20l%C3%A5nga.",
    },
  ],
  area: "Geometri",
};

const areaAvParallellogram: Section = {
  name: "Area av parallellogram",
  dependencies: [parallellogram, multiplikation],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-7/geometri-och-enheter/fyrhorningar#:~:text=Vi%20ber%C3%A4knar%20d%C3%A4rf%C3%B6r%20parallellogrammens%20area%20s%C3%A5%20h%C3%A4r%3A",
    },
  ],
  area: "Geometri",
};

const areaAvRektangel: Section = {
  name: "Area av rektangel",
  dependencies: [fyrhorningar, addition],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-7/geometri-och-enheter/fyrhorningar#:~:text=N%C3%A4r%20vi%20ska%20ber%C3%A4kna%20en%20rektangels%20area%20anv%C3%A4nder%20vi%20oss%20ocks%C3%A5%20av%20basen%20och%20h%C3%B6jden.",
    },
  ],
  area: "Geometri",
};

const omkretsAvRektangel: Section = {
  name: "Omkrets av rektangel",
  dependencies: [fyrhorningar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-7/geometri-och-enheter/fyrhorningar#:~:text=En%20rektangels%20omkrets%20%C3%A4r%20lika%20med%20summan%20av%20dess%20sidors%20l%C3%A4ngder.%20D%C3%A4rf%C3%B6r%20kan%20vi%20ber%C3%A4kna%20rektangelns%20omkrets%20s%C3%A5%20h%C3%A4r%3A",
    },
  ],
  area: "Geometri",
};

const kvadrat: Section = {
  name: "Kvadrat",
  dependencies: [fyrhorningar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-7/geometri-och-enheter/fyrhorningar#:~:text=En%20kvadrat%20%C3%A4r%20en%20rektangel%20d%C3%A4r%20alla%20sidorna%20har%20samma%20l%C3%A4ngd.",
    },
  ],
  area: "Geometri",
};

const rätblock: Section = {
  name: "Rätblock",
  dependencies: [fyrhorningar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-9/geometri/ratblock-och-kuber",
    },
  ],
  area: "Geometri",
};

const volymAvRätblock: Section = {
  name: "Volymen av ett rätblock",
  dependencies: [rätblock, multiplikation],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-9/geometri/ratblock-och-kuber#:~:text=F%C3%B6r%20att%20f%C3%A5%20volymen%20multiplicerar%20vi%20basarean%20med%20h%C3%B6jden%3A",
    },
  ],
  area: "Geometri",
};

const cylinder: Section = {
  name: "Cylinder",
  dependencies: [cirklar],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-9/geometri/cylindrar",
    },
  ],
  area: "Geometri",
};

const volymAvCylinder: Section = {
  name: "Volymen av en cylinder",
  dependencies: [cirkelnsArea, cylinder, multiplikation],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-9/geometri/cylindrar#:~:text=Volymen%20av%20en%20rak%2C%20cirkul%C3%A4r%20cylinder%20kan%20vi%20ber%C3%A4kna%20s%C3%A5%20h%C3%A4r%3A",
    },
  ],
  area: "Geometri",
};

const funktioner: Section = {
  name: "Funktioner",
  dependencies: [ekvationer],
  resources: [
    {
      creator: "Eddler",
      url: "https://eddler.se/lektioner/vad-ar-funktioner/",
    },
    {
      creator: "Mathleaks",
      url: "https://mathleaks.se/utbildning/funktioner_och_grafer",
    },
    {
      creator: "Vidma",
      url: "https://vidma.se/funktioner/",
    },
  ],
  area: "Funktioner",
};

const koordinatsystem: Section = {
  name: "Koordinatsystem",
  dependencies: [],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/funktioner/koordinatsystem",
    },
  ],
  area: "Funktioner",
};

const avstandsFormeln: Section = {
  name: "Avståndsformeln",
  dependencies: [koordinatsystem],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-2/geometri/avstandsformeln#:~:text=Avst%C3%A5ndsformeln%20kan%20anv%C3%A4ndas%20f%C3%B6r%20att,p%C3%A5%20en%20r%C3%A4tvinklig%20triangels%20sidor.",
    },
  ],
  area: "Funktioner",
};

const rataLinjensEkvation: Section = {
  name: "Räta linjens ekvation",
  dependencies: [funktioner],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/funktioner/linjara-funktioner#!/",
    },
  ],
  area: "Funktioner",
};

const andragradsfunktioner: Section = {
  name: "Andragradsfunktioner",
  dependencies: [funktioner, andragradsekvationer],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-2/ovningsexempel/andragradsfunktioner",
    },
  ],
  area: "Funktioner",
};

const exponentialfunktioner: Section = {
  name: "Exponentialfunktioner",
  dependencies: [funktioner, exponentialEkvationer],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-2/logaritmer/exponentialfunktioner",
    },
  ],
  area: "Funktioner",
};

const mittpunktsformeln: Section = {
  name: "Mittpunktsformeln",
  dependencies: [rataLinjensEkvation],
  resources: [
    {
      creator: "Eddler",
      url: "https://eddler.se/lektioner/mittpunktsformeln/",
    },
  ],
  area: "Funktioner",
};

const enheterOchPrefix: Section = {
  name: "Enheter och prefix",
  dependencies: [],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-7/geometri-och-enheter/enheter-och-prefix",
    },
  ],
  area: "Praktisk matematik",
};

const enhetsomvandling: Section = {
  name: "Enhetsomvandling",
  dependencies: [enheterOchPrefix, division],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-7/geometri-och-enheter/enheter-och-prefix#:~:text=I%20tabellen%20h%C3%A4r%20nedanf%C3%B6r%20kan%20du%20se%20hur%20vi%20kan%20omvandla%20mellan%20olika%20vanliga%20viktenheter.%20Till%20exempel%20kan%20vi%20l%C3%A4sa%20i%20tabellen%20att%201%20ton%20%C3%A4r%20lika%20mycket%20som%201%C2%A0000%20kilogram%20(kg)%20och%20att%201%20kg%20%C3%A4r%20lika%20mycket%20som%201%C2%A0000%20gram%20(g).",
    },
  ],
  area: "Praktisk matematik",
};

const strackaTidHastighet: Section = {
  name: "Hastighet, sträcka och tid",
  dependencies: [decimaler],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-7/uttryck-och-ekvationer/hastighet-stracka-och-tid",
    },
  ],
  area: "Praktisk matematik",
};

const medelhastighet: Section = {
  name: "Medelhastighet",
  dependencies: [strackaTidHastighet],
  resources: [
    {
      creator: "Fysikguiden",
      url: "https://fysikguiden.se/hastighet/medelhastighet",
    },
    {
      creator: "Eddler",
      url: "https://eddler.se/lektioner/medelhastighet/",
    },
    {
      creator: "Formelsamlingen",
      url: "https://www.formelsamlingen.se/alla-amnen/fysik/rorelse/medelhastighet",
    },
  ],
  area: "Praktisk matematik",
};

const medelvarde: Section = {
  name: "Medelvärde",
  dependencies: [braktal],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/statistik-och-sannolikhet/medelvarde-median-och-typvarde",
    },
  ],
  area: "Statistik",
};

const median: Section = {
  name: "Median",
  dependencies: [],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/statistik-och-sannolikhet/medelvarde-median-och-typvarde",
    },
  ],
  area: "Statistik",
};

const typvarde: Section = {
  name: "Typvärde",
  dependencies: [],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/statistik-och-sannolikhet/medelvarde-median-och-typvarde",
    },
  ],
  area: "Statistik",
};

const procent: Section = {
  name: "Procent",
  dependencies: [decimaler],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-8/procent/procent-hundradel",
    },
  ],
  area: "Statistik",
};

const sannolikhet: Section = {
  name: "Sannolikhet",
  dependencies: [procent, olikheter],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/statistik-och-sannolikhet/sannolikhet-for-en-handelse#!/",
    },
  ],
  area: "Statistik",
};

const procentenheter: Section = {
  name: "Procentenheter",
  dependencies: [procent],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/skolar-8/procent/procentenheter",
    },
  ],
  area: "Statistik",
};

const promille: Section = {
  name: "Promille",
  dependencies: [procent],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/algebra/forandringar-i-procent#!/:~:text=f%C3%B6r%C3%A4ndringar%20i%20procent.-,Promille,-F%C3%B6r%20att%20visa",
    },
  ],
  area: "Statistik",
};

const procentuellForandring: Section = {
  name: "Procentuell forändring",
  dependencies: [procentenheter],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-1/algebra/forandringar-i-procent",
    },
  ],
  area: "Statistik",
};

const talfoljder: Section = {
  name: "Talföljder",
  dependencies: [],
  resources: [
    {
      creator: "Matteboken",
      url: "https://www.matteboken.se/lektioner/matte-5/talfoljder-och-induktionsbevis/talfoljder",
    },
  ],
  area: "Talteori",
};

export const sections: Section[] = [
  likbentTriangel,
  addition,
  subtraktion,
  naturligaTal,
  negativaTal,
  multiplikation,
  multipler,
  potenser,
  potenslagar,
  polynommultiplikation,
  konjugatregeln,
  kvadreringsreglerna,
  rotter,
  division,
  faktorisering,
  primtal,
  decimaler,
  avrundning,
  delbarhet,
  MGN,
  JamnaOchOjamnaTal,
  parenteser,
  rakneordning,
  forenkla,
  ekvationer,
  olikheter,
  ekvationssystem,
  andragradsekvationer,
  pqFormeln,
  vinklar,
  vinkelsumma,
  vinkelrataLinjer,
  parallellaLinjer,
  trianglar,
  triangelnsOmkrets,
  triangelnsArea,
  likformighet,
  liksidigTriangel,
  rätvinkligTriangel,
  pythagorasSats,
  yttervinkelsatsen,
  braktal,
  cirklar,
  pi,
  radie,
  diameter,
  cirkelnsArea,
  cirkelnsOmkrets,
  fyrhorningar,
  additionOchSubtrationAvBrak,
  multiplikationOchDivisionAvBrak,
  parallellogram,
  areaAvParallellogram,
  areaAvRektangel,
  omkretsAvRektangel,
  kvadrat,
  rätblock,
  grundpotensform,
  volymAvRätblock,
  cylinder,
  linjäraOlikheter,
  volymAvCylinder,
  funktioner,
  koordinatsystem,
  avstandsFormeln,
  rataLinjensEkvation,
  andragradsfunktioner,
  exponentialfunktioner,
  mittpunktsformeln,
  enheterOchPrefix,
  enhetsomvandling,
  sannolikhet,
  cirkelBage,
  cirkelSektor,
  exponentialEkvationer,
  strackaTidHastighet,
  medelhastighet,
  medelvarde,
  median,
  typvarde,
  procent,
  procentenheter,
  promille,
  procentuellForandring,
  talfoljder,
];
export const makeDataForTree = () => {
  const nodes = sections.map(({ name }) => ({
    id: name,
    text: name,
    href: "hej",
  }));
  const edges = sections

    .map((subject) => {
      const { dependencies } = subject;
      return dependencies.map((dependency) => ({
        id: `${dependency.name}-${subject.name}`,
        from: dependency.name,
        to: subject.name,
      }));
    })
    .flat();
  return {
    nodes,
    edges,
  };
};

export const getSectionByName = (name: SectionName) => {
  const section = sections.find((s) => s.name === name);
  if (!section) {
    throw new Error("No section by that name" + name);
  }
  return section;
};
