import { FC } from "react";
import styled, { useTheme } from "styled-components";
import SelectorGroup from "../../../../components/SelectorGroup";
import SelectorItem from "../../../../components/SelectorItem";
import { useFilter } from "../../../../providers/FilterProvider";
import TestSelect from "./TestSelect";

const FilterContainer = styled.div<{ isOpen: boolean }>`
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spaces.m}px;
`;

type Props = {
  isOpen: boolean;
};

const Filter: FC<Props> = ({ isOpen }) => {
  const { filter, setFilter } = useFilter();
  const { unanswered, incorrect, tooSlow, correct, XYZ, KVA, NOG, DTK } =
    filter;
  const theme = useTheme();
  return (
    <FilterContainer isOpen={isOpen}>
      <SelectorGroup title="Delar" size="s">
        <SelectorItem
          size="s"
          color={theme.colors.xyz}
          name={"XYZ"}
          onClick={(XYZ) => setFilter({ ...filter, XYZ })}
          selected={XYZ}
        />
        <SelectorItem
          size="s"
          color={theme.colors.kva}
          name={"KVA"}
          onClick={(KVA) => setFilter({ ...filter, KVA })}
          selected={KVA}
        />
        <SelectorItem
          size="s"
          color={theme.colors.nog}
          name={"NOG"}
          onClick={(NOG) => setFilter({ ...filter, NOG })}
          selected={NOG}
        />
        <SelectorItem
          size="s"
          color={theme.colors.dtk}
          name={"DTK"}
          onClick={(DTK) => setFilter({ ...filter, DTK })}
          selected={DTK}
        />
      </SelectorGroup>
      <SelectorGroup title="Historik" size="s">
        <SelectorItem
          size="s"
          color={theme.colors.unanswered}
          name={"Obesvarade"}
          onClick={(unanswered) => setFilter({ ...filter, unanswered })}
          selected={unanswered}
        />
        <SelectorItem
          size="s"
          color={theme.colors.incorrect}
          name={"Felsvarade"}
          onClick={(incorrect) => setFilter({ ...filter, incorrect })}
          selected={incorrect}
        />
        <SelectorItem
          size="s"
          color={theme.colors.tooSlow}
          name={"För långsamma"}
          onClick={(tooSlow) => setFilter({ ...filter, tooSlow })}
          selected={tooSlow}
        />
        <SelectorItem
          size="s"
          color={theme.colors.correct}
          name={"Rättsvarade"}
          onClick={(correct) => setFilter({ ...filter, correct })}
          selected={correct}
        />
      </SelectorGroup>
      <div style={{ marginTop: 14 }}>
        <TestSelect />
      </div>
    </FilterContainer>
  );
};

export default Filter;
