enum CreatorID {
  ARISTOFANES = "Aristofanes",
  VIDMA = "Vidma",
  FREDRIK_LINDMARK = "Fredrik Lindmark",
  HP_GUIDEN = "HPGuiden",
}

export type Creator = {
  id: CreatorID;
  displayName: string;
};

export const resourceCreators: Creator[] = [
  {
    id: CreatorID.ARISTOFANES,
    displayName: "JON",
  },
  {
    id: CreatorID.VIDMA,
    displayName: "Vidma",
  },
  {
    id: CreatorID.FREDRIK_LINDMARK,
    displayName: "Fredrik Lindmark",
  },
  {
    id: CreatorID.HP_GUIDEN,
    displayName: "HP Guiden",
  },
];
