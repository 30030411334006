import { FC, ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

const StyledCard = styled.div`
  display: block;
  text-decoration: none;
  background-color: ${(props) => props.theme.colors.surface};
  margin: 0 -${(props) => props.theme.spaces.m}px;
  overflow: hidden;
  width: 100%;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: ${(props) => props.theme.spaces.s}px;
  padding: ${(props) => props.theme.spaces.m}px;
  transition: height 0.5s;
`;

type Props = {
  to?: string;
  children: ReactNode;
};

const Card: FC<Props> = ({ children, to }) => {
  return (
    <StyledCard as={to ? RouterLink : "div"} to={to}>
      {children}
    </StyledCard>
  );
};

export default Card;
