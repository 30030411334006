import { FC } from "react";
import { useQuestions } from "../../providers/QuestionsProvider";
import { SectionPage } from "hp-fabric";
import { useMetaData } from "../../providers/MetaDataProvider/MetaDataProvider";
import { Link, useParams } from "react-router-dom";
import MenuIcon from "../../components/MenuIcon";
import { useAnswers } from "../../providers/AnswersProvider";
import { useFilter } from "../../providers/FilterProvider";
import { useMutation } from "react-query";
import { addFeedback } from "../../data/queries/addFeedback";
import { FEEDBACK } from "hp-types";

export const OPACITY_SPEED = 0.5;
export const TRANSFORM_SPEED = 0.9;

const Section: FC = () => {
  const { loadingQuestions, questions } = useQuestions();
  const { answers } = useAnswers();
  const { sectionId } = useParams();

  const { filter, setFilter } = useFilter();

  const { sections, resources, optimalPath, loadingMetaData } = useMetaData();

  const loading = loadingQuestions || loadingMetaData;

  const { mutate } = useMutation(addFeedback);

  const sendQuestionResourceError = (questionId: string, errorText: string) => {
    mutate({
      questionId,
      message: errorText,
      feedbackType: FEEDBACK.RESOURCE,
    });
  };

  const sendSectionError = (errorText: string) => {
    mutate({
      section: sectionId,
      message: errorText,
      feedbackType: FEEDBACK.SECTION,
    });
  };

  return (
    <SectionPage
      linkComponent={Link}
      menuIcon={<MenuIcon />}
      optimalPath={optimalPath || []}
      questions={questions}
      sections={sections}
      answers={answers}
      resources={resources}
      section={sections[sectionId as string]}
      loading={loading}
      filter={filter}
      setFilter={setFilter}
      sendQuestionResourceError={sendQuestionResourceError}
      sendSectionError={sendSectionError}
    />
  );
};

export default Section;
