import { FC } from "react";
import { useSpring, animated, easings } from "@react-spring/web";
import {
  ResultObject,
  TrainingType,
} from "../../../providers/MathTrainingProvider";
import { css, useTheme } from "styled-components";
import getSubjectSign from "../../../utils/getSubjectSign";
import stringifyTime from "../../../utils/stringifyTime";
import getSolutionForTrainingData from "../../../utils/getSolutionForTrainingData";
import { Text } from "hp-fabric";

type Props = {
  result: ResultObject;
  index: number;
  subject: TrainingType;
};

const ResultRow: FC<Props> = ({ result, index, subject }) => {
  const { arg1, arg2, answer, seconds } = result;
  const solution = getSolutionForTrainingData({
    type: subject,
    arg1,
    arg2,
  });
  const theme = useTheme();
  const { opacity } = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 150 * Math.min(12, index),
    config: {
      duration: 1000,
      easing: easings.easeOutQuad,
    },
  });
  return (
    <animated.tr
      style={{
        opacity,
      }}
    >
      <td>
        <Text
          customCss={css`
            font-size: 1.5rem;
          `}
        >
          {arg1} {getSubjectSign(subject)} {arg2}
        </Text>
      </td>
      <td>
        <Text
          align="center"
          customCss={css`
            font-size: 1.5rem;
          `}
        >
          <span style={{ minWidth: 50, display: "inline-block" }}>
            {answer}
          </span>
          <span
            style={{
              color:
                solution === answer
                  ? theme.colors.positive0
                  : theme.colors.pink1,
              margin: `0 ${theme.spaces.m}px`,
            }}
          >
            {solution === answer ? "=" : "≠"}
          </span>
          <span style={{ minWidth: 50, display: "inline-block" }}>
            {solution}
          </span>
        </Text>
      </td>
      <td>
        <Text
          align="right"
          customCss={css`
            font-size: 1.5rem;
          `}
        >
          {stringifyTime(seconds)}
        </Text>
      </td>
    </animated.tr>
  );
};

export default ResultRow;
