import { AboutPage } from "hp-fabric";
import { FC } from "react";
import MenuIcon from "../../components/MenuIcon";
import { useMutation } from "react-query";
import { addFeedback } from "../../data/queries/addFeedback";
import { FEEDBACK } from "hp-types";

const About: FC = () => {
  const { mutate } = useMutation(addFeedback);

  const sendFeedback = (message: string) => {
    mutate({
      message,
      feedbackType: FEEDBACK.GENERAL,
    });
  };

  return (
    <AboutPage
      menuIcon={<MenuIcon />}
      carpenterImage={require("./carpenter.webp")}
      stressedImage={require("./stressed3.webp")}
      swishImage={require("./swish.webp")}
      sendFeedback={sendFeedback}
    />
  );
};

export default About;
