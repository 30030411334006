import { FC } from "react";
import DashboardCard from "../../components/DashboardCard";
import DashboardContainer from "../../components/DashboardContainer";
import getSectionStatistics, { getPointsForSectionList } from "./utils";
import BarChart from "./BarChart";
import { Stack } from "@mui/material";
import GraphChart from "./GraphChart";
import { sections } from "../../data/sections";
import addNewToSectionList from "../../utils/addNewToSectionList";
import getDependenciesFromList from "../../utils/getDependenciesFromList";
import { css } from "styled-components";
import { allTestSessions } from "../../data/tests";
import { useQuestions } from "../../providers/QuestionsProvider";
import { Heading, Text } from "hp-fabric";

type Props = {};

const NUMBER_OF_BARS = 50;

const Mathematics: FC<Props> = () => {
  const { questions, loadingQuestions } = useQuestions();

  const sectionsWithCount = getSectionStatistics(questions)
    .sort((a, b) => {
      const maxSort = b.max - a.max;
      const minSort = b.min - a.min;
      const averageSort = b.average - a.average;
      return maxSort || minSort || averageSort;
    })
    .slice(0, NUMBER_OF_BARS);

  const minBarData = sectionsWithCount
    .filter(({ min }) => min)
    .sort((a, b) => b.min - a.min)
    .map(({ name, min }) => ({
      label: name,
      value: min,
    }));

  const averageBarDataWithoutMin = sectionsWithCount
    .filter(({ name, average }) => {
      const isInMin = minBarData.map(({ label }) => label).includes(name);
      return !isInMin && average > 1;
    })
    .sort((a, b) => b.average - a.average)
    .map(({ name, average }) => ({
      label: name,
      value: average,
    }));

  const averageStatistics = getPointsForSectionList(questions, [
    ...minBarData.map(({ label }) => label),
    ...averageBarDataWithoutMin.map(({ label }) => label),
  ]);

  const minGraphData = sections.filter((section) =>
    minBarData.map((d) => d.label).includes(section.name)
  );

  const minSectionsWithAncestors = addNewToSectionList(
    minGraphData,
    getDependenciesFromList(minGraphData)
  );

  const minStatistics = getPointsForSectionList(
    questions,
    minSectionsWithAncestors.map(({ name }) => name)
  );

  return (
    <DashboardContainer header={{}}>
      <DashboardCard>
        <div style={{ width: "fit-content", marginLeft: "auto" }}>
          <Text
            customCss={css`
              font-size: 11px;
            `}
          >
            * Statistiken i nedanstående artikel är baserad på en analys av
            delarna KVA, XYZ och NOG från de senaste {allTestSessions.length}{" "}
            kvantitativa provpassen.
          </Text>
        </div>
        <Stack
          spacing={2}
          style={{ marginLeft: "auto", marginRight: "auto", maxWidth: 700 }}
        >
          <Heading level={2}>Högskoleprovets matematik</Heading>
          <Heading size="s" level={3}>
            Var ska jag börja?
          </Heading>
          <Text>
            Det första du vill avgöra när du börjar plugga till högskoleprovet
            är: Vad är absolut viktigast?
          </Text>
          <Text>
            Nedanstående graf visar de matematiska områden som dyker upp{" "}
            <span style={{ fontWeight: 800 }}>minst en gång</span> per provpass,
            samt minsta antal gånger de dykt upp.
          </Text>
          <BarChart bars={minBarData} />
          <Text>
            {" "}
            Dock bör man ha i åtanke att matematik bygger på matematik och det
            finns en del att lära innan man kan bemästra ovanstående områden.
            Här nedan är en graf över vad du behöver lära dig, från Addition
            hela vägen ner till Ekvationsystem.
          </Text>
          {!loadingQuestions && <GraphChart sections={minGraphData} />}
          <Text>
            Faktum är att om du bemästrar dessa{" "}
            {minSectionsWithAncestors.length} områden, säkrar du mellan
            {" " + minStatistics.min} till {minStatistics.max} poäng på varje
            högskoleprov.
          </Text>
          <Text>
            Efter att du bemästrat dessa kan vi gå vidare och se vilka
            återstående områden som{" "}
            <span style={{ fontWeight: 800 }}>i genomsnitt</span> dyker upp mer
            än en gånger per provpass.
          </Text>
          <BarChart bars={averageBarDataWithoutMin} />
          <Text>
            Om du bemästrar även dessa {averageBarDataWithoutMin.length} områden
            kommer du att nu ha säkrat upp mellan {averageStatistics.min} till{" "}
            {averageStatistics.max} poäng.
          </Text>
        </Stack>
      </DashboardCard>
    </DashboardContainer>
  );
};

export default Mathematics;
